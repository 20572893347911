var render = function() {
  var _this = this

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "login-component-layout",
    {
      attrs: {
        component: "SignUpForm"
      }
    },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function submit($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function callback($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-n4"
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6",
                    md: "4"
                  }
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.isCompany,
                        callback: function callback($$v) {
                          _vm.isCompany = $$v
                        },
                        expression: "isCompany"
                      }
                    },
                    [
                      _c("v-radio", {
                        staticClass: "ml-3",
                        attrs: {
                          label: _vm.i18n["private"],
                          value: false
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "6",
                    md: "8"
                  }
                },
                [
                  _c(
                    "v-radio-group",
                    {
                      attrs: {
                        row: ""
                      },
                      model: {
                        value: _vm.isCompany,
                        callback: function callback($$v) {
                          _vm.isCompany = $$v
                        },
                        expression: "isCompany"
                      }
                    },
                    [
                      _c("v-radio", {
                        attrs: {
                          label: _vm.i18n["companyUser"],
                          value: true,
                          "data-test-form-iscompany": ""
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm.createUserDto.isCompany
            ? _c("v-text-field", {
                attrs: {
                  label: _vm.i18n["company"],
                  rules: _vm.requiredRule,
                  id: "company",
                  outlined: ""
                },
                model: {
                  value: _vm.createUserDto.company,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createUserDto, "company", $$v)
                  },
                  expression: "createUserDto.company"
                }
              })
            : _vm._e(),
          _c("v-text-field", {
            attrs: {
              label: _vm.i18n["firstNameLabel"],
              rules: _vm.requiredRule,
              id: "firstName",
              outlined: ""
            },
            model: {
              value: _vm.createUserDto.firstName,
              callback: function callback($$v) {
                _vm.$set(_vm.createUserDto, "firstName", $$v)
              },
              expression: "createUserDto.firstName"
            }
          }),
          _c("v-text-field", {
            attrs: {
              label: _vm.i18n["lastNameLabel"],
              rules: _vm.requiredRule,
              id: "lastName",
              outlined: ""
            },
            model: {
              value: _vm.createUserDto.lastName,
              callback: function callback($$v) {
                _vm.$set(_vm.createUserDto, "lastName", $$v)
              },
              expression: "createUserDto.lastName"
            }
          }),
          _c("login-component-input-mail"),
          _c("login-component-input-password", {
            attrs: {
              suffix: "New",
              rule: _vm.passwordNewRule,
              customLabel: _vm.$t(
                "components.login.LoginComponentInputPassword.password"
              )
            }
          }),
          _c("login-component-input-password", {
            attrs: {
              suffix: "Repeat",
              rule: _vm.passwordRepeatRule
            }
          }),
          _c("login-component-password-requirement-list", {
            attrs: {
              password: _vm.passwordNew,
              setPass: _vm.setPass
            }
          }),
          _c("v-checkbox", {
            ref: "privacy",
            staticClass: "padded",
            attrs: {
              rules: [
                function(v) {
                  return !!v || _this.$t("report.thgClosing.readPrivacy")
                }
              ],
              label: _vm.$t("report.thgClosing.confirmationPrivacy"),
              "data-test-privacy-checkbox": ""
            },
            model: {
              value: _vm.isTermsAccepted,
              callback: function callback($$v) {
                _vm.isTermsAccepted = $$v
              },
              expression: "isTermsAccepted"
            }
          }),
          _c("v-checkbox", {
            staticClass: "padded",
            attrs: {
              label: _vm.$t("report.thgClosing.marketing"),
              "data-test-marketing-checkbox": ""
            },
            model: {
              value: _vm.isMarketingContactAllowed,
              callback: function callback($$v) {
                _vm.isMarketingContactAllowed = $$v
              },
              expression: "isMarketingContactAllowed"
            }
          }),
          _c("login-component-message", {
            attrs: {
              failText: _vm.failText,
              successText: _vm.successText
            }
          }),
          _c("login-component-submit-button", {
            attrs: {
              isFormValid: !_vm.isButtonDisabled,
              isLoading: _vm.isLoading,
              component: "SignUpForm"
            }
          }),
          _c("login-component-navigation-button", {
            attrs: {
              to: "/login"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }