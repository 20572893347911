var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      staticClass: "pa-2"
    },
    [
      _vm._l(_vm.pages, function(page) {
        return _c(
          "v-col",
          {
            key: page.subtitle,
            attrs: {
              cols: "12",
              sm: "6"
            }
          },
          [
            _c(
              "v-item",
              {
                attrs: {
                  value: page.nextPage
                }
              },
              [
                _c(
                  "v-sheet",
                  {
                    staticClass: "pointer",
                    attrs: {
                      outlined: "",
                      rounded: "",
                      color: "info"
                    },
                    on: {
                      click: function click() {
                        return (_vm.currentStep = page.nextPage)
                      }
                    }
                  },
                  [
                    _c(
                      "v-card",
                      {
                        attrs: {
                          outlined: ""
                        }
                      },
                      [
                        _c(
                          "v-card-actions",
                          {
                            staticClass: "pt-8 pb-8"
                          },
                          [
                            _c("v-spacer"),
                            _c(
                              "v-icon",
                              {
                                staticStyle: {
                                  transform: "scale(2)"
                                },
                                attrs: {
                                  "x-large": "",
                                  color: "info"
                                }
                              },
                              [_vm._v(" " + _vm._s(page.icon) + " ")]
                            ),
                            _c("v-spacer")
                          ],
                          1
                        ),
                        _c(
                          "h3",
                          {
                            staticClass: "text-center mb-n2"
                          },
                          [_vm._v(" " + _vm._s(page.title) + " ")]
                        ),
                        _c(
                          "v-card-subtitle",
                          {
                            staticClass: "text-center"
                          },
                          [_vm._v(" " + _vm._s(page.subtitle) + " ")]
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ],
          1
        )
      }),
      _c("debug", {
        attrs: {
          debug: _vm.report
        }
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }