var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-card-title", [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.login.LoginCardAuthorizationOverview.permissions"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              [
                _c("v-simple-table", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function fn() {
                        return [
                          _c("thead", [
                            _c("tr", [
                              _c(
                                "th",
                                {
                                  staticClass: "text-left"
                                },
                                [_vm._v(" Permission ")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-left"
                                },
                                [_vm._v(" Action ")]
                              ),
                              _c(
                                "th",
                                {
                                  staticClass: "text-left"
                                },
                                [_vm._v(" Subject ")]
                              )
                            ])
                          ]),
                          _c(
                            "tbody",
                            _vm._l(_vm.permissions, function(permission, i) {
                              return _c(
                                "tr",
                                {
                                  key: i
                                },
                                [
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "BackendResourceEnum.".concat(
                                            permission.type
                                          )
                                        )
                                      ) +
                                        " (" +
                                        _vm._s(permission.type) +
                                        ")"
                                    )
                                  ]),
                                  _c(
                                    "td",
                                    _vm._l(permission.action, function(action) {
                                      return _c(
                                        "v-chip",
                                        {
                                          key: action
                                        },
                                        [_vm._v(" " + _vm._s(action) + " ")]
                                      )
                                    }),
                                    1
                                  ),
                                  _c("td", [
                                    _vm._v(
                                      _vm._s(_vm.getResourceName(permission))
                                    )
                                  ])
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            ],
            2
          )
        ],
        1
      ),
      _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("v-card-title", [
            _c("h3", [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t(
                      "components.login.LoginCardAuthorizationOverview.roles"
                    )
                  ) +
                  " "
              )
            ])
          ]),
          _c(
            "v-card-text",
            [
              [
                _c("v-simple-table", {
                  scopedSlots: _vm._u([
                    {
                      key: "default",
                      fn: function fn() {
                        return [
                          _c(
                            "tbody",
                            _vm._l(_vm.user.roles, function(role) {
                              return _c(
                                "tr",
                                {
                                  key: role
                                },
                                [
                                  _c(
                                    "td",
                                    {
                                      staticStyle: {
                                        border: "none"
                                      }
                                    },
                                    [_vm._v(_vm._s(role))]
                                  )
                                ]
                              )
                            }),
                            0
                          )
                        ]
                      },
                      proxy: true
                    }
                  ])
                })
              ]
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }