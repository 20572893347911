var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-text-field", {
    attrs: {
      label: _vm.label,
      type: _vm.isPasswordVisible ? "text" : "password",
      rules: _vm.rules,
      "prepend-inner-icon": "mdi-lock",
      "append-icon": _vm.isPasswordVisible ? "mdi-eye" : "mdi-eye-off",
      id: "password".concat(_vm.suffix),
      outlined: ""
    },
    on: {
      "click:append": function clickAppend($event) {
        _vm.isPasswordVisible = !_vm.isPasswordVisible
      }
    },
    model: {
      value: _vm.password,
      callback: function callback($$v) {
        _vm.password = $$v
      },
      expression: "password"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }