var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.isQuicklinks
    ? _c(
        "v-menu",
        {
          attrs: {
            "offset-y": "",
            left: "",
            "nudge-bottom": "14",
            "max-width": "450",
            "close-on-content-click": false,
            "content-class": "user-profile-menu-content"
          },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function fn(_ref) {
                  var on = _ref.on,
                    attrs = _ref.attrs
                  return [
                    _c(
                      "v-btn",
                      _vm._g(
                        _vm._b(
                          {
                            attrs: {
                              icon: ""
                            }
                          },
                          "v-btn",
                          attrs,
                          false
                        ),
                        on
                      ),
                      [_c("v-icon", [_vm._v(" mdi-magnify ")])],
                      1
                    )
                  ]
                }
              }
            ],
            null,
            false,
            1213369270
          )
        },
        [
          _c("quick-link-list", {
            attrs: {
              limitDisplay: true
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }