var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c(
        "v-container",
        {
          attrs: {
            fluid: ""
          }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-expansion-panels",
                {
                  attrs: {
                    multiple: true,
                    flat: false,
                    hover: true
                  }
                },
                _vm._l(_vm.length, function(item, i) {
                  return _c(
                    "v-expansion-panel",
                    {
                      key: i
                    },
                    [
                      _c("v-expansion-panel-header", [_vm._v(_vm._s(item))]),
                      _c("v-expansion-panel-content", [
                        _vm._v(
                          " Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. "
                        )
                      ])
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }