import { render, staticRenderFns } from "./UserProfile.vue?vue&type=template&id=e928b7d8&"
import script from "./UserProfile.vue?vue&type=script&lang=ts&"
export * from "./UserProfile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
installComponents(component, {VCard,VCardSubtitle,VCardTitle,VCol,VContainer,VRow,VSkeletonLoader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('e928b7d8')) {
      api.createRecord('e928b7d8', component.options)
    } else {
      api.reload('e928b7d8', component.options)
    }
    module.hot.accept("./UserProfile.vue?vue&type=template&id=e928b7d8&", function () {
      api.rerender('e928b7d8', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/UserProfile.vue"
export default component.exports