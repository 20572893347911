import { render, staticRenderFns } from "./DocumentTemplateDetailTokenListOptions.vue?vue&type=template&id=95566e16&scoped=true&"
import script from "./DocumentTemplateDetailTokenListOptions.vue?vue&type=script&lang=ts&"
export * from "./DocumentTemplateDetailTokenListOptions.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95566e16",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCardActions,VCol,VIcon,VRow,VSheet,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('95566e16')) {
      api.createRecord('95566e16', component.options)
    } else {
      api.reload('95566e16', component.options)
    }
    module.hot.accept("./DocumentTemplateDetailTokenListOptions.vue?vue&type=template&id=95566e16&scoped=true&", function () {
      api.rerender('95566e16', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sign/DocumentTemplateDetailTokenListOptions.vue"
export default component.exports