import { render, staticRenderFns } from "./DocumentTemplateDetailInformation.vue?vue&type=template&id=273ec68e&scoped=true&"
import script from "./DocumentTemplateDetailInformation.vue?vue&type=script&lang=ts&"
export * from "./DocumentTemplateDetailInformation.vue?vue&type=script&lang=ts&"
import style0 from "./DocumentTemplateDetailInformation.vue?vue&type=style&index=0&id=273ec68e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "273ec68e",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCardText,VCheckbox,VChip,VCombobox,VForm,VRow,VSimpleTable,VSpacer,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('273ec68e')) {
      api.createRecord('273ec68e', component.options)
    } else {
      api.reload('273ec68e', component.options)
    }
    module.hot.accept("./DocumentTemplateDetailInformation.vue?vue&type=template&id=273ec68e&scoped=true&", function () {
      api.rerender('273ec68e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sign/DocumentTemplateDetailInformation.vue"
export default component.exports