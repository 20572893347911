import { render, staticRenderFns } from "./FilterSelection.vue?vue&type=template&id=16162066&scoped=true&"
import script from "./FilterSelection.vue?vue&type=script&lang=ts&"
export * from "./FilterSelection.vue?vue&type=script&lang=ts&"
import style0 from "./FilterSelection.vue?vue&type=style&index=0&id=16162066&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16162066",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VChip,VCombobox,VContainer,VForm,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('16162066')) {
      api.createRecord('16162066', component.options)
    } else {
      api.reload('16162066', component.options)
    }
    module.hot.accept("./FilterSelection.vue?vue&type=template&id=16162066&scoped=true&", function () {
      api.rerender('16162066', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/filter/FilterSelection.vue"
export default component.exports