var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        title: _vm.$t(
          "components.fleet.FleetVehicleDetailDocumentCard.addDocumentDialogTitle"
        ),
        isDialogActive: _vm.dialogActive,
        leftDisabled: _vm.isLoading,
        rightDisabled: _vm.isRightDisabled,
        rightLoading: _vm.isLoading,
        hideLeft: _vm.step === _vm.STEPS.CHOOSE_NEXT_STEP,
        hideRight: _vm.step === _vm.STEPS.CHOOSE_NEXT_STEP,
        leftText: _vm.$t("back"),
        width: "800px"
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogActive = $event
        },
        leftClick: _vm.previous,
        rightClick: _vm.next
      }
    },
    [
      _c(
        "v-stepper",
        {
          staticClass: "pa-0",
          attrs: {
            elevation: 0
          },
          model: {
            value: _vm.step,
            callback: function callback($$v) {
              _vm.step = $$v
            },
            expression: "step"
          }
        },
        [
          _c(
            "v-stepper-items",
            {
              staticClass: "pa-0"
            },
            [
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: _vm.STEPS.CHOOSE_NEXT_STEP
                  }
                },
                [
                  _c(
                    "v-row",
                    _vm._l(_vm.choices, function(choice) {
                      return _c(
                        "v-col",
                        {
                          key: choice.subtitle,
                          attrs: {
                            cols: "12",
                            sm: "6"
                          }
                        },
                        [
                          _c(
                            "v-item",
                            [
                              _c(
                                "v-sheet",
                                {
                                  staticClass: "pointer",
                                  attrs: {
                                    outlined: "",
                                    rounded: "",
                                    color: "info"
                                  },
                                  on: {
                                    click: choice.onClick
                                  }
                                },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      attrs: {
                                        outlined: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-card-actions",
                                        {
                                          staticClass: "pt-8 pb-8"
                                        },
                                        [
                                          _c("v-spacer"),
                                          _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                transform: "scale(2)"
                                              },
                                              attrs: {
                                                "x-large": "",
                                                color: "info"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " + _vm._s(choice.icon) + " "
                                              )
                                            ]
                                          ),
                                          _c("v-spacer")
                                        ],
                                        1
                                      ),
                                      _c(
                                        "h3",
                                        {
                                          staticClass: "text-center mb-n2"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(choice.title) + " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "v-card-subtitle",
                                        {
                                          staticClass: "text-center"
                                        },
                                        [
                                          _vm._v(
                                            " " + _vm._s(choice.subtitle) + " "
                                          )
                                        ]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  staticClass: "pa-0",
                  attrs: {
                    step: _vm.STEPS.SELECT_DOCUMENT
                  }
                },
                [
                  _c("paginated-table", {
                    attrs: {
                      store: _vm.store,
                      predefinedFilter: _vm.store.commonPredefinedFilter,
                      selectedItems: _vm.selectedItems,
                      baseQuery: {
                        partnerId: _vm.partnerId
                      },
                      singleSelect: true,
                      showSelect: true,
                      headers: _vm.headers,
                      allItems: _vm.documentSuggestions
                    },
                    on: {
                      "update:selectedItems": function updateSelectedItems(
                        $event
                      ) {
                        _vm.selectedItems = $event
                      },
                      "update:selected-items": function updateSelectedItems(
                        $event
                      ) {
                        _vm.selectedItems = $event
                      },
                      "click:row": function clickRow(d) {
                        return _vm.selectedItems.splice(
                          0,
                          _vm.selectedItems.length,
                          d
                        )
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "v-stepper-content",
                {
                  attrs: {
                    step: _vm.STEPS.UPLOAD
                  }
                },
                [
                  _c("document-form", {
                    ref: "form",
                    staticClass: "ml-4 mr-4 mb-2",
                    attrs: {
                      hideButton: true,
                      isLoading: _vm.isLoading,
                      hideTemplateOption: true,
                      folder: _vm.folder
                    },
                    on: {
                      updateValid: _vm.updateValid
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }