import { render, staticRenderFns } from "./CustomFieldSingleSelect.vue?vue&type=template&id=1fd01722&"
import script from "./CustomFieldSingleSelect.vue?vue&type=script&lang=ts&"
export * from "./CustomFieldSingleSelect.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VListItemContent,VListItemSubtitle,VListItemTitle,VSelect,VSheet})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fd01722')) {
      api.createRecord('1fd01722', component.options)
    } else {
      api.reload('1fd01722', component.options)
    }
    module.hot.accept("./CustomFieldSingleSelect.vue?vue&type=template&id=1fd01722&", function () {
      api.rerender('1fd01722', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/report/CustomFieldSingleSelect.vue"
export default component.exports