var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-portal",
    [
      _c(
        "v-container",
        {
          attrs: {
            fluid: ""
          }
        },
        [
          _c("ProfileCard", {
            attrs: {
              user: _vm.User
            }
          }),
          _c("Debug", {
            attrs: {
              debug: _vm.User
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }