var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: "",
        id: "filterCardFilter"
      }
    },
    [
      !_vm.hideDivider ? _c("v-divider") : _vm._e(),
      _c(
        "v-card-title",
        {
          class: _vm.padding ? "" : "pa-0"
        },
        [
          _vm.isSerachActivated
            ? _c(
                "v-col",
                {
                  staticClass: "pa-0",
                  attrs: {
                    cols: 12,
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-form",
                    {
                      on: {
                        submit: function submit($event) {
                          $event.preventDefault()
                          return _vm.confirmSearch.apply(null, arguments)
                        }
                      }
                    },
                    [
                      _c("v-text-field", {
                        attrs: {
                          loading: _vm.isSearchLoading,
                          placeholder: _vm.$t("components.filter.label"),
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          clearable: "",
                          "data-test-search-field": ""
                        },
                        on: {
                          click: function click($event) {
                            return _vm.$emit("clickSearch")
                          },
                          "click:clear": function clickClear($event) {
                            _vm._search = ""
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend-inner",
                              fn: function fn() {
                                return [
                                  _c("filter-card-pagination-menu", {
                                    attrs: {
                                      filterList: _vm.filterList,
                                      large: _vm.large,
                                      filter: _vm._filter,
                                      predefinedFilter: _vm.predefinedFilter,
                                      search: _vm.search
                                    },
                                    on: {
                                      "update:filter": function updateFilter(
                                        $event
                                      ) {
                                        _vm._filter = $event
                                      },
                                      confirmFilter: function confirmFilter(
                                        $event
                                      ) {
                                        return _vm.$emit("confirmFilter")
                                      }
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "append",
                              fn: function fn() {
                                return [
                                  _c(
                                    "tooltip",
                                    {
                                      attrs: {
                                        text: ""
                                          .concat(
                                            _vm.$t("components.filter.label"),
                                            ": "
                                          )
                                          .concat(_vm.filterLabel)
                                      }
                                    },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            "data-test-search-button": ""
                                          },
                                          on: {
                                            click: _vm.confirmSearch
                                          }
                                        },
                                        [_vm._v(" mdi-magnify ")]
                                      )
                                    ],
                                    1
                                  )
                                ]
                              },
                              proxy: true
                            }
                          ],
                          null,
                          false,
                          1235240172
                        ),
                        model: {
                          value: _vm._search,
                          callback: function callback($$v) {
                            _vm._search = $$v
                          },
                          expression: "_search"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          !_vm.isSerachActivated
            ? _c(
                "v-col",
                {
                  attrs: {
                    cols: "12",
                    align: "center"
                  }
                },
                [
                  _c("filter-card-pagination-menu", {
                    attrs: {
                      filterList: _vm.filterList,
                      large: _vm.large,
                      filter: _vm._filter,
                      predefinedFilter: _vm.predefinedFilter,
                      search: _vm.search
                    },
                    on: {
                      "update:filter": function updateFilter($event) {
                        _vm._filter = $event
                      },
                      confirmFilter: function confirmFilter($event) {
                        return _vm.$emit("confirmFilter")
                      }
                    }
                  })
                ],
                1
              )
            : _vm._e()
        ],
        1
      ),
      !_vm.hideDivider ? _c("v-divider") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }