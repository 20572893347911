var render = function() {
  var _vm$booking, _vm$booking2, _vm$service

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        width: "600px",
        absolute: true,
        title: _vm.$t("views.booking.BookingTable.title"),
        subtitle: _vm.$t("views.booking.BookingTable.subtitle", {
          date: _vm.creationDate
        }),
        loading: _vm.loading
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _vm.booking
        ? _c("v-simple-table", [
            _c(
              "tbody",
              _vm._l(_vm.items, function(item, idx) {
                return _c(
                  "tr",
                  {
                    key: idx
                  },
                  [
                    _c("td", [_vm._v(_vm._s(_vm.$t(item.text)))]),
                    _c("td", [_vm._v(_vm._s(item.value))])
                  ]
                )
              }),
              0
            )
          ])
        : _vm._e(),
      _c(
        "v-card",
        {
          staticClass: "mx-5 overflow-y-auto",
          staticStyle: {
            "max-height": "35vh"
          },
          attrs: {
            flat: ""
          }
        },
        [
          (_vm$booking = _vm.booking) !== null &&
          _vm$booking !== void 0 &&
          _vm$booking.attachments &&
          (_vm$booking2 = _vm.booking) !== null &&
          _vm$booking2 !== void 0 &&
          _vm$booking2.attachments.length
            ? _c("booking-attachment-list", {
                staticStyle: {
                  "max-width": "600px"
                },
                attrs: {
                  attachments: _vm.booking.attachments
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "pl-4 pr-4 pt-4"
        },
        [
          _c(
            "v-btn",
            {
              attrs: {
                small: "",
                color: "error",
                outlined: "",
                elevation: "0"
              },
              on: {
                click: function click($event) {
                  _vm.isCancelDialogActive = true
                }
              }
            },
            [
              _vm._v(
                " " + _vm._s(_vm.$t("bookingForm.BookingDetail.cancel")) + " "
              )
            ]
          ),
          (_vm$service = _vm.service) !== null &&
          _vm$service !== void 0 &&
          _vm$service.isActive
            ? _c(
                "v-btn",
                {
                  attrs: {
                    small: "",
                    color: "primary",
                    elevation: "0"
                  },
                  on: {
                    click: _vm.update
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("bookingForm.BookingDetail.update")) +
                      " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      ),
      _c("confirm-action-dialog", {
        attrs: {
          isDialogActive: _vm.isCancelDialogActive,
          title: _vm.$t("views.booking.BookingTable.confirmDeleteTitle"),
          subtitle: _vm.$t("views.booking.BookingTable.confirmDeleteSubtitle"),
          leftLoading: _vm.isCancelDialogLoading,
          rightLoading: _vm.isCancelDialogLoading
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isCancelDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isCancelDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isCancelDialogActive = false
          },
          rightClick: _vm.cancel
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }