import { render, staticRenderFns } from "./BookingIFrame.vue?vue&type=template&id=5fd6ebbd&"
import script from "./BookingIFrame.vue?vue&type=script&lang=ts&"
export * from "./BookingIFrame.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VCard } from 'vuetify/lib/components/VCard';
import { VMain } from 'vuetify/lib/components/VMain';
installComponents(component, {VApp,VCard,VMain})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5fd6ebbd')) {
      api.createRecord('5fd6ebbd', component.options)
    } else {
      api.reload('5fd6ebbd', component.options)
    }
    module.hot.accept("./BookingIFrame.vue?vue&type=template&id=5fd6ebbd&", function () {
      api.rerender('5fd6ebbd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/booking/BookingIFrame.vue"
export default component.exports