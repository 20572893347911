var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.isLoadingServices
        ? _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "ml-2 mr-4",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-skeleton-loader", {
                    staticClass: "mt-4",
                    attrs: {
                      type: _vm.loadingAnimation
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm.bookableServices.length
        ? _c(
            "div",
            [
              _c(
                "v-card-text",
                {
                  staticClass: "title font-weight-bold"
                },
                [_vm._v(_vm._s(_vm.$t("bookingForm.BookingServices.title")))]
              ),
              _vm.showListView
                ? _c(
                    "v-row",
                    {
                      attrs: {
                        "no-gutters": ""
                      }
                    },
                    _vm._l(_vm.bookableServices, function(item) {
                      return _c(
                        "v-col",
                        {
                          key: item.serviceId,
                          attrs: {
                            cols: "4"
                          }
                        },
                        [
                          _c("booking-services-item", {
                            key: item.serviceId,
                            attrs: {
                              item: item
                            },
                            on: {
                              click: function click($event) {
                                return _vm.selectService(item)
                              }
                            }
                          })
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
              !_vm.showListView
                ? _c("v-virtual-scroll", {
                    staticClass: "overflow-y-auto mb-4",
                    attrs: {
                      height: _vm.height,
                      "item-height": "75",
                      items: _vm.bookableServices
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function fn(_ref) {
                            var item = _ref.item
                            return [
                              _c("booking-services-item", {
                                attrs: {
                                  item: item
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.selectService(item)
                                  }
                                }
                              })
                            ]
                          }
                        }
                      ],
                      null,
                      false,
                      3736992545
                    )
                  })
                : _vm._e()
            ],
            1
          )
        : _c(
            "v-row",
            {
              staticClass: "fill-height mt-10 mb-12",
              style: "height: ".concat(_vm.height),
              attrs: {
                align: "stretch"
              }
            },
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-img", {
                    staticClass: "mt-10",
                    attrs: {
                      contain: "",
                      src: _vm.image,
                      height: 150,
                      justify: "center"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-col",
                {
                  staticClass: "justify-center align-center text-center",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("bookingForm.BookingServices.noServices", {
                            partnerMail: _vm.partnerMail
                          })
                        ) +
                        " "
                    )
                  ])
                ],
                1
              )
            ],
            1
          ),
      _c("booking-navigation", {
        attrs: {
          isMarginTop: true,
          isMobile: _vm.isMobile
        },
        on: {
          back: _vm.back,
          forward: _vm.forward
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }