var render = function() {
  var _vm$partner$address,
    _vm$partner$address2,
    _vm$partner$address3,
    _vm$partner$contact,
    _vm$partner$address4,
    _vm$partner$address5,
    _vm$partner$address6,
    _vm$partner$contact2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [_vm._v(" Widerrufsrecht des Verbrauchers ")]),
              _c("v-card-subtitle", [_vm._v(" Stand: 01.03.2024 ")]),
              _c("v-card-text", [
                _vm._v(
                  " Diese Vereinbarung räumt dem Verbraucher ein Widerrufsrecht nach Maßgabe der nachfolgenden Widerrufsbelehrung ein. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann (§ 13 BGB). "
                )
              ]),
              _c("v-card-text", [_vm._v("Widerrufsbelehrung")]),
              _c("v-card-text", [
                _vm._v(
                  " Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (" +
                    _vm._s(
                      ""
                        .concat(_vm.partner.companyName, ", ")
                        .concat(
                          (_vm$partner$address = _vm.partner.address) ===
                            null || _vm$partner$address === void 0
                            ? void 0
                            : _vm$partner$address.street,
                          ", "
                        )
                        .concat(
                          (_vm$partner$address2 = _vm.partner.address) ===
                            null || _vm$partner$address2 === void 0
                            ? void 0
                            : _vm$partner$address2.zip,
                          " "
                        )
                        .concat(
                          (_vm$partner$address3 = _vm.partner.address) ===
                            null || _vm$partner$address3 === void 0
                            ? void 0
                            : _vm$partner$address3.city
                        )
                    ) +
                    ", E-Mail: " +
                    _vm._s(
                      (_vm$partner$contact = _vm.partner.contact) === null ||
                        _vm$partner$contact === void 0
                        ? void 0
                        : _vm$partner$contact.email
                    ) +
                    " mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief￼ oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht. "
                )
              ]),
              _c("v-card-text", [_vm._v("Muster-Widerrufsformular")]),
              _c("v-card-text", [
                _vm._v(
                  " Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück. "
                )
              ]),
              _c(
                "v-sheet",
                {
                  staticClass: "ma-4",
                  attrs: {
                    elevation: 5,
                    rounded: ""
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.partner.companyName)),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm$partner$address4 = _vm.partner.address) ===
                            null || _vm$partner$address4 === void 0
                            ? void 0
                            : _vm$partner$address4.street
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm$partner$address5 = _vm.partner.address) ===
                            null || _vm$partner$address5 === void 0
                            ? void 0
                            : _vm$partner$address5.zip
                        ) +
                        " " +
                        _vm._s(
                          (_vm$partner$address6 = _vm.partner.address) ===
                            null || _vm$partner$address6 === void 0
                            ? void 0
                            : _vm$partner$address6.city
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      " E-Mail: " +
                        _vm._s(
                          (_vm$partner$contact2 = _vm.partner.contact) ===
                            null || _vm$partner$contact2 === void 0
                            ? void 0
                            : _vm$partner$contact2.email
                        )
                    ),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: ____________________________________"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" – Bestellt am: ______________"),
                    _c("br"),
                    _vm._v(" – Name des/der Verbraucher(s): ______________"),
                    _c("br"),
                    _vm._v(
                      " – Anschrift des/der Verbraucher(s): ______________"
                    ),
                    _c("br"),
                    _vm._v(
                      " – Unterschrift des/der Verbraucher(s): ______________"
                    ),
                    _c("br"),
                    _vm._v(" – Datum: ______________ ")
                  ])
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " Wir sind verpflichtet, dich als Verbraucher auf die Plattform zur Online-Streitbeilegung (OS-Plattform) der Europäischen Kommission hinzuweisen. Diese OS-Plattform ist über folgenden Link erreichbar: https://webgate.ec.europa.eu/odr. Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle allerdings nicht teil. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }