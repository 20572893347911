var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "mt-15 pt-10"
    },
    [
      _c(
        "login-component-layout",
        {
          attrs: {
            component: _vm.reason
          }
        },
        [
          _c(
            "v-form",
            {
              on: {
                submit: function submit($event) {
                  $event.preventDefault()
                  return _vm.backToLogin.apply(null, arguments)
                }
              }
            },
            [
              _c("login-component-submit-button", {
                attrs: {
                  isFormValid: true,
                  component: _vm.reason
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }