var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c("sign-up-card", {
        attrs: {
          logo: _vm.logo,
          title: _vm.title,
          text: _vm.text,
          isDescriptionCardActive: true
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }