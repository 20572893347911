var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-app",
    {
      staticClass: "loginOuterOuterContainer",
      attrs: {
        id: "loginContainer"
      }
    },
    [
      _c(
        "v-card",
        {
          directives: [
            {
              name: "resize",
              rawName: "v-resize",
              value: _vm.onResize,
              expression: "onResize"
            }
          ],
          staticClass: "mt-3 ml-md-auto mr-md-auto",
          attrs: {
            flat: "",
            outlined: _vm.isBreakpointReached
          }
        },
        [
          _vm.showLogoOnMobile
            ? _c(
                "div",
                {
                  attrs: {
                    id: "topLogoCard"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "top mb-n8"
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.logo,
                          id: "topImage"
                        }
                      })
                    ]
                  )
                ]
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticClass: "loginOuterContainer"
            },
            [
              _c(
                "div",
                {
                  staticClass: "loginInnerContainer"
                },
                [
                  _c(
                    "div",
                    {
                      attrs: {
                        id: "loginInner"
                      }
                    },
                    [_c("router-view")],
                    1
                  )
                ]
              ),
              _vm.isDescriptionCardActive
                ? _c("v-divider", {
                    staticClass: "d-none d-md-block",
                    staticStyle: {
                      "z-index": "1"
                    },
                    attrs: {
                      vertical: ""
                    }
                  })
                : _vm._e(),
              _vm.isDescriptionCardActive
                ? _c(
                    "div",
                    {
                      staticClass: "loginInnerContainer",
                      attrs: {
                        id: "right"
                      }
                    },
                    [
                      _c("login-card-description", {
                        attrs: {
                          logo: _vm.logo,
                          title: _vm.title,
                          text: _vm.text
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }