var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("booking-success", {
        attrs: {
          heading: "bookingForm.BookingDetail.yourCreatedBooking",
          text: "bookingForm.BookingDetail.yourCreatedBookingText",
          showDownloadBtn: false
        }
      }),
      _c(
        "v-row",
        {
          staticClass: "justify-center"
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.isMobile ? "3" : "auto"
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "error",
                    outlined: "",
                    elevation: "0",
                    block: _vm.isMobile
                  },
                  on: {
                    click: _vm.goToCancel
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("bookingForm.BookingDetail.cancel")) +
                      " "
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "v-col",
            {
              attrs: {
                cols: _vm.isMobile ? "3" : "auto"
              }
            },
            [
              _c(
                "v-btn",
                {
                  attrs: {
                    color: "primary",
                    elevation: "0",
                    block: _vm.isMobile
                  },
                  on: {
                    click: _vm.goToUpdate
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("bookingForm.BookingDetail.update")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }