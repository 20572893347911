var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-alert",
        {
          attrs: {
            prominent: "",
            dismissible: "",
            type: "error",
            "close-icon": "mdi-delete"
          },
          model: {
            value: _vm.alert,
            callback: function callback($$v) {
              _vm.alert = $$v
            },
            expression: "alert"
          }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "grow"
                },
                [
                  _vm._v("There are "),
                  _c("strong", [_vm._v(" " + _vm._s(_vm.errors.length))]),
                  _vm._v(" new errors!.")
                ]
              ),
              _c(
                "v-col",
                {
                  staticClass: "shrink"
                },
                [
                  _c(
                    "v-btn",
                    {
                      on: {
                        click: _vm.openDetails
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-format-list-bulleted-square")])],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            inset: "",
            scrollable: ""
          },
          model: {
            value: _vm.sheet,
            callback: function callback($$v) {
              _vm.sheet = $$v
            },
            expression: "sheet"
          }
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v("Error Log")]),
              _c("v-divider"),
              _c(
                "v-card-text",
                {
                  staticStyle: {
                    height: "200px"
                  }
                },
                [
                  _vm._l(_vm.errors, function(error, i) {
                    return _c(
                      "v-list-item",
                      {
                        key: i
                      },
                      [
                        _c("v-list-item-title", [_vm._v(_vm._s(error.name))]),
                        _c("v-list-item-subtitle", [
                          _vm._v(_vm._s(error.message))
                        ])
                      ],
                      1
                    )
                  }),
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-6",
                          attrs: {
                            text: "",
                            color: "error"
                          },
                          on: {
                            click: function click($event) {
                              _vm.sheet = !_vm.sheet
                            }
                          }
                        },
                        [_vm._v("close")]
                      ),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          staticClass: "mt-6",
                          attrs: {
                            text: "",
                            color: "error"
                          },
                          on: {
                            click: _vm.clear
                          }
                        },
                        [_vm._v("clear")]
                      )
                    ],
                    1
                  )
                ],
                2
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }