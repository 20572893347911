var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "center mr-7 ml-7",
          attrs: {
            flat: ""
          }
        },
        [
          _c("img", {
            attrs: {
              src: _vm.logo,
              id: "image"
            }
          }),
          _c(
            "h2",
            {
              staticClass: "pb-3",
              attrs: {
                id: "title"
              }
            },
            [_vm._v(_vm._s(_vm.displayTitle))]
          ),
          _c("v-divider"),
          _c("p", {
            staticClass: "pt-3",
            attrs: {
              id: "text"
            },
            domProps: {
              innerHTML: _vm._s(_vm.text)
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }