var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _c(
        "v-row",
        [
          _c("v-text-field", {
            staticClass: "mx-8 mt-8",
            attrs: {
              disabled: _vm.disabled,
              outlined: "",
              label: "IBAN *",
              rules: _vm.ibanRules
            },
            model: {
              value: _vm.banking.iban,
              callback: function callback($$v) {
                _vm.$set(_vm.banking, "iban", $$v)
              },
              expression: "banking.iban"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-text-field", {
            staticClass: "mx-8",
            attrs: {
              disabled: _vm.disabled,
              outlined: "",
              label: "Bank *",
              rules: _vm.requiredRules
            },
            model: {
              value: _vm.banking.bank,
              callback: function callback($$v) {
                _vm.$set(_vm.banking, "bank", $$v)
              },
              expression: "banking.bank"
            }
          })
        ],
        1
      ),
      _c(
        "v-row",
        [
          _c("v-text-field", {
            staticClass: "mx-8",
            attrs: {
              disabled: _vm.disabled,
              outlined: "",
              label: "Kontoinhaber *",
              rules: _vm.requiredRules
            },
            model: {
              value: _vm.banking.name,
              callback: function callback($$v) {
                _vm.$set(_vm.banking, "name", $$v)
              },
              expression: "banking.name"
            }
          })
        ],
        1
      ),
      !_vm.isDisabled
        ? _c("profile-confirmation-row", {
            attrs: {
              isLoading: _vm.isLoading,
              valid: _vm.valid
            },
            on: {
              save: _vm.save,
              abort: _vm.abort
            }
          })
        : _vm._e(),
      _vm.isDisabled
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    color: "primary",
                    "data-test-profile-edit": ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.isDisabled = !_vm.isDisabled
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.EditProfileDialog.edit")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }