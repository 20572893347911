var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-col",
        {
          attrs: {
            cols: "12"
          }
        },
        [
          _c(
            "v-card-title",
            {
              staticClass: "mb-n6"
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.$t("components.profile.EditProfileDialog.general")
                  ) +
                  " "
              )
            ]
          )
        ],
        1
      ),
      _vm.displayOptions.isGeneralEnabled
        ? [
            _c(
              "v-card-text",
              [
                _vm.showUserNameField
                  ? _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-n6",
                          attrs: {
                            outlined: "",
                            label: _vm.$t(
                              "components.profile.ProfileCard.userName"
                            ),
                            disabled: "",
                            "data-test-profile-userName": ""
                          },
                          model: {
                            value: _vm.user.userName,
                            callback: function callback($$v) {
                              _vm.$set(_vm.user, "userName", $$v)
                            },
                            expression: "user.userName"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c(
                  "v-row",
                  {
                    staticClass: "mt-n4"
                  },
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "6",
                          md: "4"
                        }
                      },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: {
                              row: "",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.user.isCompany,
                              callback: function callback($$v) {
                                _vm.$set(_vm.user, "isCompany", $$v)
                              },
                              expression: "user.isCompany"
                            }
                          },
                          [
                            _c("v-radio", {
                              staticClass: "ml-3",
                              attrs: {
                                label: _vm.$t(
                                  "components.profile.EditProfileDialog.private"
                                ),
                                value: false
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "6",
                          md: "8"
                        }
                      },
                      [
                        _c(
                          "v-radio-group",
                          {
                            attrs: {
                              row: "",
                              disabled: _vm.disabled
                            },
                            model: {
                              value: _vm.user.isCompany,
                              callback: function callback($$v) {
                                _vm.$set(_vm.user, "isCompany", $$v)
                              },
                              expression: "user.isCompany"
                            }
                          },
                          [
                            _c("v-radio", {
                              attrs: {
                                label: _vm.$t(
                                  "components.profile.EditProfileDialog.company"
                                ),
                                value: true,
                                "data-test-form-iscompany": ""
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-n6",
                      attrs: {
                        outlined: "",
                        label: _vm.$t(
                          "components.profile.EditProfileDialog.name"
                        ),
                        rules: [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t(
                                "components.profile.EditProfileDialog.mandatoryInput"
                              )
                            )
                          }
                        ],
                        "data-test-profile-firstName": "",
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.user.firstName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.user, "firstName", $$v)
                        },
                        expression: "user.firstName"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-n6",
                      attrs: {
                        outlined: "",
                        label: _vm.$t(
                          "components.profile.EditProfileDialog.lastname"
                        ),
                        rules: [
                          function(v) {
                            return (
                              !!v ||
                              _vm.$t(
                                "components.profile.EditProfileDialog.mandatoryInput"
                              )
                            )
                          }
                        ],
                        "data-test-profile-lastName": "",
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.user.lastName,
                        callback: function callback($$v) {
                          _vm.$set(_vm.user, "lastName", $$v)
                        },
                        expression: "user.lastName"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.displayOptions.isCompanyEnabled
        ? [
            _vm.user.isCompany
              ? _c(
                  "div",
                  [
                    _c(
                      "v-card-text",
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n6 mt-n7",
                              attrs: {
                                rules: [
                                  function(v) {
                                    return (
                                      !!v ||
                                      _vm.$t(
                                        "components.profile.EditProfileDialog.mandatoryInput"
                                      )
                                    )
                                  }
                                ],
                                outlined: "",
                                label:
                                  _vm.$t(
                                    "components.profile.EditProfileDialog.companyName"
                                  ) + " *",
                                "data-test-profile-company": "",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.user.company,
                                callback: function callback($$v) {
                                  _vm.$set(_vm.user, "company", $$v)
                                },
                                expression: "user.company"
                              }
                            })
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c("v-card-title", [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.profile.EditProfileDialog.taxInformation"
                                )
                              ) +
                              " "
                          )
                        ]),
                        _vm.isTaxDeductibleEnabled
                          ? _c("v-card-subtitle", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.taxInformationQuestion"
                                    )
                                  ) +
                                  " "
                              )
                            ])
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.isTaxDeductibleEnabled
                      ? _c(
                          "v-card-text",
                          [
                            _c(
                              "v-row",
                              {
                                staticClass: "mt-n13 mb-n7"
                              },
                              [
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "6",
                                      md: "4"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          row: "",
                                          disabled: _vm.disabled
                                        },
                                        model: {
                                          value: _vm.user.isTaxDeductible,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "isTaxDeductible",
                                              $$v
                                            )
                                          },
                                          expression: "user.isTaxDeductible"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          staticClass: "ml-3",
                                          attrs: {
                                            label: _vm.$t(
                                              "components.profile.EditProfileDialog.yes"
                                            ),
                                            value: true
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                ),
                                _c(
                                  "v-col",
                                  {
                                    attrs: {
                                      cols: "6",
                                      md: "8"
                                    }
                                  },
                                  [
                                    _c(
                                      "v-radio-group",
                                      {
                                        attrs: {
                                          row: "",
                                          disabled: _vm.disabled
                                        },
                                        model: {
                                          value: _vm.user.isTaxDeductible,
                                          callback: function callback($$v) {
                                            _vm.$set(
                                              _vm.user,
                                              "isTaxDeductible",
                                              $$v
                                            )
                                          },
                                          expression: "user.isTaxDeductible"
                                        }
                                      },
                                      [
                                        _c("v-radio", {
                                          attrs: {
                                            label: _vm.$t(
                                              "components.profile.EditProfileDialog.no"
                                            ),
                                            value: false
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isTHGContext
                      ? _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12"
                            }
                          },
                          [
                            _vm.user.isTaxDeductible
                              ? _c(
                                  "v-card-subtitle",
                                  {
                                    staticClass: "mt-n6 mb-n3"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.profile.EditProfileDialog.taxNumberRequiredText"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              : _c(
                                  "v-card-subtitle",
                                  {
                                    staticClass: "mt-n6 mb-n3"
                                  },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.profile.EditProfileDialog.taxNumberNotRequiredText"
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.user.isTaxDeductible
                      ? _c(
                          "div",
                          [
                            _vm.isResubmitTaxnumberEnabled
                              ? _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-row",
                                      {
                                        staticClass: "mt-n12 mb-n8"
                                      },
                                      [
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "6",
                                              md: "4"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  row: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.localIsResubmitTaxnumber,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.localIsResubmitTaxnumber = $$v
                                                  },
                                                  expression:
                                                    "localIsResubmitTaxnumber"
                                                }
                                              },
                                              [
                                                _c("v-radio", {
                                                  staticClass: "ml-3",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "report.thgContactData.register"
                                                    ),
                                                    value: false,
                                                    "data-test-form-isNotResubmittingTaxnumber":
                                                      ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        ),
                                        _c(
                                          "v-col",
                                          {
                                            attrs: {
                                              cols: "6",
                                              md: "8"
                                            }
                                          },
                                          [
                                            _c(
                                              "v-radio-group",
                                              {
                                                attrs: {
                                                  row: ""
                                                },
                                                model: {
                                                  value:
                                                    _vm.localIsResubmitTaxnumber,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.localIsResubmitTaxnumber = $$v
                                                  },
                                                  expression:
                                                    "localIsResubmitTaxnumber"
                                                }
                                              },
                                              [
                                                _c("v-radio", {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "report.thgContactData.resubmit"
                                                    ),
                                                    value: true,
                                                    "data-test-form-isResubmittingTaxnumber":
                                                      ""
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            !_vm.localIsResubmitTaxnumber
                              ? _c(
                                  "v-card-text",
                                  [
                                    _c(
                                      "v-col",
                                      {
                                        attrs: {
                                          cols: "12"
                                        }
                                      },
                                      [
                                        _c("v-text-field", {
                                          staticClass: "mt-n4 mb-n4",
                                          attrs: {
                                            outlined: "",
                                            rules: _vm.taxNumberRules,
                                            label: ""
                                              .concat(
                                                _vm.$t(
                                                  "components.profile.EditProfileDialog.taxnumber"
                                                ),
                                                " "
                                              )
                                              .concat(
                                                _vm.isTaxnumberRequired
                                                  ? "*"
                                                  : ""
                                              ),
                                            "data-test-profile-taxnumber": "",
                                            disabled: _vm.disabled
                                          },
                                          model: {
                                            value: _vm.user.taxnumber,
                                            callback: function callback($$v) {
                                              _vm.$set(
                                                _vm.user,
                                                "taxnumber",
                                                $$v
                                              )
                                            },
                                            expression: "user.taxnumber"
                                          }
                                        })
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e(),
      _vm.displayOptions.isContactEnabled
        ? [
            _c(
              "v-col",
              {
                attrs: {
                  cols: "12"
                }
              },
              [
                _c("v-card-title", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "components.profile.EditProfileDialog.contactInformation"
                        )
                      ) +
                      " "
                  )
                ]),
                _vm.isTHGContext
                  ? _c(
                      "v-card-subtitle",
                      {
                        staticClass: "mb-n6"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.profile.EditProfileDialog.contactInformationText"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-card-text",
              [
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-n6",
                      attrs: {
                        outlined: "",
                        label: _vm.$t(
                          "components.profile.EditProfileDialog.email"
                        ),
                        rules: _vm.emailRules,
                        type: "email",
                        "data-test-profile-email": "",
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.user.contact.email,
                        callback: function callback($$v) {
                          _vm.$set(_vm.user.contact, "email", $$v)
                        },
                        expression: "user.contact.email"
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-col",
                  {
                    attrs: {
                      cols: "12"
                    }
                  },
                  [
                    _c("v-text-field", {
                      staticClass: "mb-n6",
                      attrs: {
                        outlined: "",
                        label: _vm.$t(
                          "components.profile.EditProfileDialog.phone"
                        ),
                        rules: _vm.phoneNumberRules,
                        "data-test-profile-phone": "",
                        disabled: _vm.disabled
                      },
                      model: {
                        value: _vm.user.contact.phone,
                        callback: function callback($$v) {
                          _vm.$set(_vm.user.contact, "phone", $$v)
                        },
                        expression: "user.contact.phone"
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm.displayOptions.isAddressEnabled
        ? [
            _c(
              "v-col",
              {
                attrs: {
                  cols: "12"
                }
              },
              [
                _c("v-card-title", [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.EditProfileDialog.adress")
                      ) +
                      " "
                  )
                ]),
                _vm.user.isCompany && _vm.isTHGContext
                  ? _c(
                      "v-card-subtitle",
                      {
                        staticClass: "mb-n6"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.profile.EditProfileDialog.adressTextCompany"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm.isTHGContext
                  ? _c(
                      "v-card-subtitle",
                      {
                        staticClass: "mb-n6"
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.profile.EditProfileDialog.adressText"
                              )
                            ) +
                            " "
                        )
                      ]
                    )
                  : _vm._e()
              ],
              1
            ),
            _c(
              "v-card-text",
              [
                _vm.isResubmitAddressEnabled
                  ? _c(
                      "v-row",
                      {
                        staticClass: "mt-n4 mb-n11"
                      },
                      [
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "6",
                              md: "4"
                            }
                          },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: {
                                  row: ""
                                },
                                model: {
                                  value: _vm.localIsResubmitAddress,
                                  callback: function callback($$v) {
                                    _vm.localIsResubmitAddress = $$v
                                  },
                                  expression: "localIsResubmitAddress"
                                }
                              },
                              [
                                _c("v-radio", {
                                  staticClass: "ml-3",
                                  attrs: {
                                    label: _vm.$t(
                                      "report.thgContactData.register"
                                    ),
                                    value: false,
                                    "data-test-form-isNotResubmittingAddressDetails":
                                      ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        ),
                        _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "6",
                              md: "8"
                            }
                          },
                          [
                            _c(
                              "v-radio-group",
                              {
                                attrs: {
                                  row: ""
                                },
                                model: {
                                  value: _vm.localIsResubmitAddress,
                                  callback: function callback($$v) {
                                    _vm.localIsResubmitAddress = $$v
                                  },
                                  expression: "localIsResubmitAddress"
                                }
                              },
                              [
                                _c("v-radio", {
                                  attrs: {
                                    label: _vm.$t(
                                      "report.thgContactData.resubmit"
                                    ),
                                    value: true,
                                    "data-test-form-isResubmittingAddressDetails":
                                      ""
                                  }
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            ),
            !_vm.localIsResubmitAddress
              ? _c(
                  "v-card-text",
                  [
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-n6",
                          attrs: {
                            outlined: "",
                            rules: _vm.addressRules,
                            label: ""
                              .concat(
                                _vm.$t(
                                  "components.profile.EditProfileDialog.street"
                                ),
                                " "
                              )
                              .concat(_vm.isAddressRequired ? "*" : ""),
                            "data-test-profile-street": "",
                            disabled: _vm.disabled
                          },
                          model: {
                            value: _vm.user.address.street,
                            callback: function callback($$v) {
                              _vm.$set(_vm.user.address, "street", $$v)
                            },
                            expression: "user.address.street"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-n6",
                          attrs: {
                            outlined: "",
                            rules: _vm.addressRules,
                            label: ""
                              .concat(
                                _vm.$t(
                                  "components.profile.EditProfileDialog.zip"
                                ),
                                " "
                              )
                              .concat(_vm.isAddressRequired ? "*" : ""),
                            "data-test-profile-zip": "",
                            disabled: _vm.disabled
                          },
                          model: {
                            value: _vm.user.address.zip,
                            callback: function callback($$v) {
                              _vm.$set(_vm.user.address, "zip", $$v)
                            },
                            expression: "user.address.zip"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "v-col",
                      {
                        attrs: {
                          cols: "12"
                        }
                      },
                      [
                        _c("v-text-field", {
                          staticClass: "mb-n6",
                          attrs: {
                            outlined: "",
                            rules: _vm.addressRules,
                            label: ""
                              .concat(
                                _vm.$t(
                                  "components.profile.EditProfileDialog.city"
                                ),
                                " "
                              )
                              .concat(_vm.isAddressRequired ? "*" : ""),
                            "data-test-profile-city": "",
                            disabled: _vm.disabled
                          },
                          model: {
                            value: _vm.user.address.city,
                            callback: function callback($$v) {
                              _vm.$set(_vm.user.address, "city", $$v)
                            },
                            expression: "user.address.city"
                          }
                        })
                      ],
                      1
                    ),
                    !_vm.hideState
                      ? _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12"
                            }
                          },
                          [
                            _c("v-text-field", {
                              staticClass: "mb-n6",
                              attrs: {
                                outlined: "",
                                rules: _vm.stateRules,
                                label: ""
                                  .concat(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.state"
                                    ),
                                    " "
                                  )
                                  .concat(_vm.isAddressRequired ? "*" : ""),
                                "data-test-profile-state": "",
                                disabled: _vm.disabled
                              },
                              model: {
                                value: _vm.user.address.state,
                                callback: function callback($$v) {
                                  _vm.$set(_vm.user.address, "state", $$v)
                                },
                                expression: "user.address.state"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.hideCountry
                      ? _c(
                          "v-col",
                          {
                            attrs: {
                              cols: "12"
                            }
                          },
                          [
                            _c("v-autocomplete", {
                              staticClass: "mb-n6",
                              attrs: {
                                outlined: "",
                                rules: _vm.addressRules,
                                label: ""
                                  .concat(
                                    _vm.$t(
                                      "components.profile.EditProfileDialog.country"
                                    ),
                                    " "
                                  )
                                  .concat(_vm.isAddressRequired ? "*" : ""),
                                "data-test-profile-countryCode": "",
                                disabled: _vm.disabled,
                                items: _vm.countryCodes
                              },
                              model: {
                                value: _vm.user.address.countryCode,
                                callback: function callback($$v) {
                                  _vm.$set(_vm.user.address, "countryCode", $$v)
                                },
                                expression: "user.address.countryCode"
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }