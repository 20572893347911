var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        overflow: "hidden"
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            dense: "",
            outlined: ""
          }
        },
        [
          _vm._t("default"),
          _vm._l(_vm.editConfig, function(edit, index) {
            return _c(
              "span",
              {
                key: JSON.stringify(edit) + index
              },
              [
                edit.item === _vm.TemplateActionSpecialItemType.LINE
                  ? _c("template-editor-action-divider")
                  : edit.item ===
                    _vm.TemplateActionSpecialItemType.ADD_PLACEHOLDER
                  ? _c("template-editor-add-placeholder", {
                      attrs: {
                        partnerId: _vm.partnerId
                      },
                      on: {
                        addPlaceholder: _vm.addPlaceholder
                      }
                    })
                  : edit.content && edit.display.icon
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          icon: "",
                          disabled: !_vm.isActionButtonActive(edit.content)
                        },
                        on: {
                          click: function click($event) {
                            return _vm.click(index, edit)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: _vm.menuStyleButtonColor(edit)
                            }
                          },
                          [_vm._v(_vm._s(edit.display.icon))]
                        )
                      ],
                      1
                    )
                  : edit.content && edit.display.text
                  ? _c(
                      "v-btn",
                      {
                        staticClass: "ml-n3 mr-n3",
                        attrs: {
                          text: "",
                          disabled: !_vm.isActionButtonActive(edit.content),
                          color: _vm.menuStyleButtonColor(edit)
                        },
                        on: {
                          click: function click($event) {
                            return _vm.click(index, edit)
                          }
                        }
                      },
                      [_c("small", [_vm._v(_vm._s(edit.display.text))])]
                    )
                  : edit.content && edit.display.color
                  ? _c(
                      "v-btn",
                      {
                        staticStyle: {
                          "margin-left": "-6px",
                          "margin-right": "-6px"
                        },
                        attrs: {
                          icon: "",
                          disabled: !_vm.isActionButtonActive(edit.content)
                        },
                        on: {
                          click: function click($event) {
                            return _vm.click(index, edit)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            attrs: {
                              color: edit.display.color
                            }
                          },
                          [_vm._v("mdi-square-rounded")]
                        )
                      ],
                      1
                    )
                  : _vm._e(),
                edit.sublist
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          value: _vm.clickedIndex === index,
                          "offset-y": "",
                          "close-on-click": false,
                          "close-on-content-click": false
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function fn(_ref) {
                                var on = _ref.on,
                                  attrs = _ref.attrs
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticStyle: {
                                            overflow: "none"
                                          },
                                          attrs: {
                                            icon: "",
                                            color: _vm.menuSublistButtonColor(
                                              edit
                                            )
                                          },
                                          on: {
                                            click: function click($event) {
                                              return _vm.click(index, edit)
                                            }
                                          }
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticStyle: {
                                            position: "relative",
                                            left: "12px",
                                            bottom: "2px"
                                          },
                                          attrs: {
                                            small: ""
                                          }
                                        },
                                        [_vm._v(_vm._s(edit.display.icon))]
                                      ),
                                      _vm.isActiveSublist(edit.sublist)
                                        ? _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                                right: "12px",
                                                top: "9px"
                                              },
                                              attrs: {
                                                "x-small": ""
                                              }
                                            },
                                            [_vm._v(" mdi-chevron-up ")]
                                          )
                                        : _c(
                                            "v-icon",
                                            {
                                              staticStyle: {
                                                position: "relative",
                                                right: "12px",
                                                top: "10px"
                                              },
                                              attrs: {
                                                "x-small": ""
                                              }
                                            },
                                            [_vm._v(" mdi-chevron-down ")]
                                          )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _vm.clickedIndex === index
                          ? _c("template-editor-actions", {
                              ref: "sublist",
                              refInFor: true,
                              attrs: {
                                editConfig: _vm.sublist,
                                root: false,
                                editor: _vm.editor,
                                partnerId: _vm.partnerId
                              },
                              on: {
                                click: _vm.bubbleClick,
                                addPlaceholder: _vm.addPlaceholder
                              }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          })
        ],
        2
      ),
      _vm.root
        ? _c(
            "v-row",
            [
              _c("public-image-upload-dialog", {
                ref: "publicImageUploadDialog",
                attrs: {
                  folder: _vm.folderName,
                  hideButton: true
                },
                on: {
                  onUpload: _vm.upload
                }
              }),
              _c("template-dialog-paste-url", {
                ref: "pasteImageDialog",
                on: {
                  onInput: _vm.paste
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }