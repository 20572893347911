var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-dialog",
    {
      staticClass: "pdfViewContainer",
      attrs: {
        fullscreen: "",
        transition: "dialog-bottom-transition"
      },
      on: {
        keydown: function keydown($event) {
          if (
            !$event.type.indexOf("key") &&
            _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])
          ) {
            return null
          }
          return _vm.closePdfDialog.apply(null, arguments)
        }
      },
      model: {
        value: _vm.isDialog,
        callback: function callback($$v) {
          _vm.isDialog = $$v
        },
        expression: "isDialog"
      }
    },
    [
      _vm.isDialog && _vm.pdf
        ? _c(
            "v-card",
            [
              _c(
                "div",
                {
                  staticStyle: {
                    "z-index": "1",
                    position: "absolute",
                    top: "0",
                    right: "0"
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "ma-2",
                      attrs: {
                        icon: ""
                      },
                      on: {
                        click: _vm.closePdfDialog
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-close")])],
                    1
                  )
                ],
                1
              ),
              _c("pdf-viewer", {
                attrs: {
                  pdf: _vm.pdf
                },
                on: {
                  onImageExport: _vm.onImageExport
                }
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }