var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-report",
    {
      attrs: {
        renderBackAndForthFooter: false,
        isProgressDone: true,
        showFooter: false
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-card-title",
                        {
                          staticClass: "text-h4 px-0"
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("report.profile.title")) + " "
                          )
                        ]
                      ),
                      _c("v-card-subtitle", {
                        staticClass: "pl-0",
                        domProps: {
                          innerHTML: _vm._s(_vm.$t("report.profile.subtitle"))
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c("notifications"),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                {
                  staticClass: "px-1",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _vm.isLoading
                    ? _c("v-skeleton-loader", {
                        attrs: {
                          type: "list-item-three-line"
                        }
                      })
                    : _c("profile-card", {
                        attrs: {
                          user: _vm.User
                        }
                      }),
                  _c("Debug", {
                    attrs: {
                      debug: _vm.User
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }