import { render, staticRenderFns } from "./CameraApp.vue?vue&type=template&id=f0a74d7c&scoped=true&"
import script from "./CameraApp.vue?vue&type=script&lang=ts&"
export * from "./CameraApp.vue?vue&type=script&lang=ts&"
import style0 from "./CameraApp.vue?vue&type=style&index=0&id=f0a74d7c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f0a74d7c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBottomSheet } from 'vuetify/lib/components/VBottomSheet';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
installComponents(component, {VAlert,VBottomSheet,VBtn,VContainer,VIcon,VImg,VSnackbar})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('f0a74d7c')) {
      api.createRecord('f0a74d7c', component.options)
    } else {
      api.reload('f0a74d7c', component.options)
    }
    module.hot.accept("./CameraApp.vue?vue&type=template&id=f0a74d7c&scoped=true&", function () {
      api.rerender('f0a74d7c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/camera/CameraApp.vue"
export default component.exports