var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-btn",
    {
      attrs: {
        text: "",
        "x-small": "",
        color: "info"
      },
      on: {
        click: _vm.onClick
      }
    },
    [_vm._v(_vm._s(_vm.$t("bookingForm.BookingBackButton.editButton")))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }