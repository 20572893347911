import { render, staticRenderFns } from "./ReportDocumentSignRequestStepperChoice.vue?vue&type=template&id=6c79f477&scoped=true&"
import script from "./ReportDocumentSignRequestStepperChoice.vue?vue&type=script&lang=ts&"
export * from "./ReportDocumentSignRequestStepperChoice.vue?vue&type=script&lang=ts&"
import style0 from "./ReportDocumentSignRequestStepperChoice.vue?vue&type=style&index=0&id=6c79f477&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6c79f477",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardSubtitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItem } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSheet } from 'vuetify/lib/components/VSheet';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardSubtitle,VCol,VIcon,VItem,VRow,VSheet,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('6c79f477')) {
      api.createRecord('6c79f477', component.options)
    } else {
      api.reload('6c79f477', component.options)
    }
    module.hot.accept("./ReportDocumentSignRequestStepperChoice.vue?vue&type=template&id=6c79f477&scoped=true&", function () {
      api.rerender('6c79f477', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sign/ReportDocumentSignRequestStepperChoice.vue"
export default component.exports