var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticStyle: {
        width: "100%",
        height: "100px",
        "overflow-x": "auto"
      },
      attrs: {
        elevation: 0
      }
    },
    [
      _c("v-divider"),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row"
          }
        },
        _vm._l(_vm.pages, function(page, index) {
          return _c(
            "v-sheet",
            {
              key: "pageNumber" + index,
              staticClass: "imageContainer ml-1 mr-1",
              attrs: {
                outlined: "",
                rounded: "",
                color: index === _vm.currentPageNumber ? "info" : ""
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "imageContainer",
                  attrs: {
                    flat: "",
                    outlined: index === _vm.currentPageNumber
                  },
                  on: {
                    click: function click($event) {
                      return _vm.setCurrentPage(index)
                    }
                  }
                },
                [
                  _c("v-img", {
                    staticClass: "ma-auto imageContainer",
                    attrs: {
                      contain: "",
                      src: page.src
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }