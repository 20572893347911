import { render, staticRenderFns } from "./TheNavigationBase.vue?vue&type=template&id=191b4a27&"
import script from "./TheNavigationBase.vue?vue&type=script&lang=ts&"
export * from "./TheNavigationBase.vue?vue&type=script&lang=ts&"
import style0 from "./TheNavigationBase.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VList } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VList,VListItemGroup,VNavigationDrawer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('191b4a27')) {
      api.createRecord('191b4a27', component.options)
    } else {
      api.reload('191b4a27', component.options)
    }
    module.hot.accept("./TheNavigationBase.vue?vue&type=template&id=191b4a27&", function () {
      api.rerender('191b4a27', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/TheNavigationBase.vue"
export default component.exports