var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.filterSelectionConfiguration &&
    (!_vm.filterSelectionConfiguration.items ||
      !_vm.filterSelectionConfiguration.items.length)
    ? _c("v-text-field", {
        attrs: {
          type: _vm.filterSelectionConfiguration.type,
          rules: _vm.filterSelectionConfiguration.rules,
          placeholder: _vm.filterSelectionConfiguration.placeholder,
          "hide-details": "",
          outlined: "",
          dense: "",
          "data-test-filter-value": ""
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.filterSelectionConfiguration &&
      _vm.filterSelectionConfiguration.items &&
      !_vm.filterSelectionConfiguration.component
    ? _c("v-select", {
        attrs: {
          rules: _vm.filterSelectionConfiguration.rules,
          placeholder: _vm.filterSelectionConfiguration.placeholder,
          items: _vm.filterSelectionConfiguration.items,
          "item-value": _vm.filterSelectionConfiguration.itemValue,
          "hide-details": "",
          outlined: "",
          dense: ""
        },
        scopedSlots: _vm._u([
          {
            key: "append",
            fn: function fn() {
              return [
                _c("div", {
                  staticStyle: {
                    display: "none"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "item",
            fn: function fn(_ref) {
              var item = _ref.item
              return [
                _vm._v(" " + _vm._s(item.text ? _vm.$t(item.text) : item) + " ")
              ]
            }
          },
          {
            key: "selection",
            fn: function fn(_ref2) {
              var item = _ref2.item
              return [
                _vm._v(" " + _vm._s(item.text ? _vm.$t(item.text) : item) + " ")
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.filterSelectionConfiguration &&
      _vm.filterSelectionConfiguration.component
    ? _c("v-select", {
        attrs: {
          rules: _vm.filterSelectionConfiguration.rules,
          placeholder: _vm.filterSelectionConfiguration.placeholder,
          items: _vm.filterSelectionConfiguration.items,
          "item-value": _vm.filterSelectionConfiguration.itemValue,
          "hide-details": "",
          outlined: "",
          dense: ""
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function fn(_ref3) {
              var item = _ref3.item
              return [
                _c(
                  _vm.filterSelectionConfiguration.component,
                  _vm._b(
                    {
                      tag: "component",
                      attrs: {
                        small: true
                      }
                    },
                    "component",
                    _vm.filterSelectionConfiguration.mapItemToComponent
                      ? _vm.filterSelectionConfiguration.mapItemToComponent(
                          item
                        )
                      : item,
                    false
                  )
                )
              ]
            }
          },
          {
            key: "selection",
            fn: function fn(_ref4) {
              var item = _ref4.item
              return [
                _c(
                  _vm.filterSelectionConfiguration.component,
                  _vm._b(
                    {
                      tag: "component",
                      attrs: {
                        small: true
                      }
                    },
                    "component",
                    _vm.filterSelectionConfiguration.mapItemToComponent
                      ? _vm.filterSelectionConfiguration.mapItemToComponent(
                          item
                        )
                      : item,
                    false
                  )
                )
              ]
            }
          }
        ]),
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }