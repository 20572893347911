import { BookingAvailabilityGen } from "@/services/booking/v1/data-contracts";
import { DaysOfTheWeekHelper, DaysOfTheWeekOrder } from "@/lib/utility/daysOfTheWeekHelper";
/**
 * Function to map availability days to week days strings for objects that are available(isAvailable = true)
 * So, if isAvailable is true for Monday and Sunday this function will return
 * ["monday", "sunday"]
 */
export function getAvailableDaysAsWeekDayString(availability: BookingAvailabilityGen[]): string[] {
  const weekDaysValuesSunSat = DaysOfTheWeekHelper.getDaysOfTheWeek(DaysOfTheWeekOrder.Javascript);
  const weekDaysValuesMonSun = DaysOfTheWeekHelper.getDaysOfTheWeek(DaysOfTheWeekOrder.Normal);

  const availableForWeekDays: string[] = availability
    // weekDaysValuesSunSat[index] will give us the day(monday, friday); substring will reduce it mon, fri
    .map((availability, index) => (availability.isAvailable ? weekDaysValuesSunSat[index] : ""))
    //filter out empty strings
    .filter(Boolean);

  // Return the array of days sorted as Mon-Sun
  return availableForWeekDays.sort((day1, day2) => {
    return weekDaysValuesMonSun.indexOf(day1 as any) > weekDaysValuesMonSun.indexOf(day2 as any) ? 1 : -1;
  });
}

/**
 * Function to transform array of week day strings to a string separated by a character. Default separator is " | ".
 * Example:
 * weekDays: ["monday", "sunday"]
 * output: "monday | sunday"
 */
export function stringifyWeekDays(weekDays: string[], separator = " | "): string {
  return weekDays.join(separator);
}
