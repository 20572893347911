var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return !_vm.isMobile
    ? _c(
        "v-card",
        {
          staticClass: "mx-auto",
          attrs: {
            flat: _vm.flat
          }
        },
        [
          _c(
            "v-window",
            {
              model: {
                value: _vm.secondWindow,
                callback: function callback($$v) {
                  _vm.secondWindow = $$v
                },
                expression: "secondWindow"
              }
            },
            [
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.bookingScreenEnum.START
                  }
                },
                [
                  _c(
                    "v-row",
                    {
                      staticClass: "mx-0",
                      staticStyle: {
                        overflow: "hidden"
                      }
                    },
                    [
                      _c(
                        "v-col",
                        {
                          style: "background-color: ".concat(_vm.bgColor, " "),
                          attrs: {
                            cols: "6"
                          }
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex fill-height justify-center align-center"
                            },
                            [
                              _vm.step == _vm.bookingStepEnum.START &&
                              _vm.bookingIframe
                                ? _c("booking-start", {
                                    attrs: {
                                      bookingIframe: _vm.bookingIframe,
                                      dark: _vm.dark,
                                      bgColor: _vm.bgColor
                                    }
                                  })
                                : _vm._e(),
                              _vm.step == _vm.bookingStepEnum.CALENDER
                                ? _c("booking-calendar", {
                                    attrs: {
                                      dark: _vm.dark
                                    },
                                    on: {
                                      "date-change": _vm.onDateChange
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        ]
                      ),
                      _c(
                        "v-col",
                        {
                          attrs: {
                            cols:
                              _vm.step == _vm.bookingStepEnum.START &&
                              !_vm.bookingIframe
                                ? 12
                                : 6
                          }
                        },
                        [
                          _c(
                            "v-window",
                            {
                              model: {
                                value: _vm.step,
                                callback: function callback($$v) {
                                  _vm.step = $$v
                                },
                                expression: "step"
                              }
                            },
                            [
                              _c(
                                "v-window-item",
                                {
                                  attrs: {
                                    value: _vm.bookingStepEnum.START
                                  }
                                },
                                [
                                  _c("booking-services", {
                                    attrs: {
                                      bookingIframe: _vm.bookingIframe
                                    }
                                  })
                                ],
                                1
                              ),
                              _c(
                                "v-window-item",
                                {
                                  attrs: {
                                    value: _vm.bookingStepEnum.CALENDER
                                  }
                                },
                                [_c("booking-slots")],
                                1
                              ),
                              _c("v-window-item", {
                                attrs: {
                                  value: _vm.bookingStepEnum.FORM
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.bookingScreenEnum.FORM
                  }
                },
                [
                  _c("booking-form", {
                    on: {
                      submit: _vm.onBookSlot
                    }
                  })
                ],
                1
              ),
              _c(
                "v-window-item",
                {
                  attrs: {
                    value: _vm.bookingScreenEnum.SUCCESS
                  }
                },
                [_c("booking-success")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _c(
        "v-container",
        {
          staticClass: "px-1",
          attrs: {
            fluid: ""
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto",
              attrs: {
                "max-width": "400",
                "min-height": "600",
                flat: _vm.flat
              }
            },
            [
              _c(
                "v-window",
                {
                  model: {
                    value: _vm.step,
                    callback: function callback($$v) {
                      _vm.step = $$v
                    },
                    expression: "step"
                  }
                },
                [
                  _c(
                    "v-window-item",
                    {
                      style: "background-color: ".concat(_vm.bgColor, " "),
                      attrs: {
                        value: _vm.bookingStepEnum.START
                      }
                    },
                    [
                      _c("booking-start", {
                        attrs: {
                          isMobile: true,
                          dark: _vm.dark,
                          bgColor: _vm.bgColor
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.bookingStepEnum.SERVICES
                      }
                    },
                    [
                      _c("booking-services", {
                        attrs: {
                          isMobile: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.bookingStepEnum.CALENDER
                      }
                    },
                    [
                      _c("booking-calendar", {
                        attrs: {
                          isMobile: true
                        },
                        on: {
                          "date-change": _vm.onDateChange
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.bookingStepEnum.SLOTS
                      }
                    },
                    [
                      _c("booking-slots", {
                        attrs: {
                          isMobile: true
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.bookingStepEnum.FORM
                      }
                    },
                    [
                      _c("booking-form", {
                        attrs: {
                          isMobile: true
                        },
                        on: {
                          submit: _vm.onBookSlot
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "v-window-item",
                    {
                      attrs: {
                        value: _vm.bookingStepEnum.SUCCESS
                      }
                    },
                    [_c("booking-success")],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }