import { render, staticRenderFns } from "./DocumentTemplateDetailTokenListOptionsMobile.vue?vue&type=template&id=47cf1af7&scoped=true&"
import script from "./DocumentTemplateDetailTokenListOptionsMobile.vue?vue&type=script&lang=ts&"
export * from "./DocumentTemplateDetailTokenListOptionsMobile.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47cf1af7",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSlideXReverseTransition } from 'vuetify/lib/components/transitions';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
installComponents(component, {VBtn,VChip,VIcon,VSlideXReverseTransition,VSpeedDial})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('47cf1af7')) {
      api.createRecord('47cf1af7', component.options)
    } else {
      api.reload('47cf1af7', component.options)
    }
    module.hot.accept("./DocumentTemplateDetailTokenListOptionsMobile.vue?vue&type=template&id=47cf1af7&scoped=true&", function () {
      api.rerender('47cf1af7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/sign/DocumentTemplateDetailTokenListOptionsMobile.vue"
export default component.exports