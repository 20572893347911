var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "checkbox-container mb-8"
    },
    [
      _c("fieldset", {
        staticClass: "rounded"
      }),
      _c("v-checkbox", {
        staticClass: "pa-4 mt-0",
        attrs: {
          "hide-details": "auto",
          rules: _vm.requiredRule,
          disabled: _vm.disabled,
          loading: _vm.loading,
          hint: _vm.customField.hint,
          label: _vm.customField.label,
          "persistent-hint": ""
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      }),
      _vm.loading
        ? _c("v-progress-linear", {
            attrs: {
              indeterminate: "",
              height: 1
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }