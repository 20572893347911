var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      staticClass: "mb-2"
    },
    [
      _c(
        "v-container",
        {
          attrs: {
            flat: ""
          }
        },
        _vm._l(_vm.filterList, function(filter, index) {
          return _c(
            "div",
            {
              key: index
            },
            [
              !filter.multiple
                ? _c("v-text-field", {
                    ref: "singleInput",
                    refInFor: true,
                    staticClass: "lessCompact",
                    attrs: {
                      label: _vm.$t(filter.label || "components.filter.label"),
                      type: filter.type,
                      dense: "",
                      outlined: "",
                      rounded: ""
                    },
                    model: {
                      value: filter.value[0],
                      callback: function callback($$v) {
                        _vm.$set(filter.value, 0, $$v)
                      },
                      expression: "filter.value[0]"
                    }
                  })
                : filter.items
                ? _c("v-combobox", {
                    ref: "multiInputWithSelection",
                    refInFor: true,
                    staticClass: "compact",
                    attrs: {
                      label: _vm.$t(filter.label || "components.filter.label"),
                      items: filter.items,
                      chips: "",
                      clearable: "",
                      multiple: "",
                      outlined: "",
                      rounded: "",
                      dense: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function fn(_ref) {
                            var attrs = _ref.attrs,
                              item = _ref.item,
                              select = _ref.select,
                              selected = _ref.selected
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    style: _vm.chipStyle,
                                    attrs: {
                                      color: "primary",
                                      "input-value": selected,
                                      close: "",
                                      outlined: "",
                                      rounded: ""
                                    },
                                    on: {
                                      click: select,
                                      "click:close": function clickClose(
                                        $event
                                      ) {
                                        return _vm.remove(item, index)
                                      }
                                    }
                                  },
                                  "v-chip",
                                  attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: filter.value,
                      callback: function callback($$v) {
                        _vm.$set(filter, "value", $$v)
                      },
                      expression: "filter.value"
                    }
                  })
                : _c("v-combobox", {
                    ref: "multiInput",
                    refInFor: true,
                    staticClass: "compact",
                    attrs: {
                      label: filter.label,
                      "append-icon": "",
                      chips: "",
                      clearable: "",
                      multiple: "",
                      dense: "",
                      outlined: "",
                      rounded: ""
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "selection",
                          fn: function fn(_ref2) {
                            var attrs = _ref2.attrs,
                              item = _ref2.item,
                              select = _ref2.select,
                              selected = _ref2.selected
                            return [
                              _c(
                                "v-chip",
                                _vm._b(
                                  {
                                    style: _vm.closeChipStyle,
                                    attrs: {
                                      color: "closeChipColor",
                                      "input-value": selected,
                                      close: ""
                                    },
                                    on: {
                                      click: select,
                                      "click:close": function clickClose(
                                        $event
                                      ) {
                                        return _vm.remove(item, index)
                                      }
                                    }
                                  },
                                  "v-chip",
                                  attrs,
                                  false
                                ),
                                [_vm._v(" " + _vm._s(item) + " ")]
                              )
                            ]
                          }
                        }
                      ],
                      null,
                      true
                    ),
                    model: {
                      value: filter.value,
                      callback: function callback($$v) {
                        _vm.$set(filter, "value", $$v)
                      },
                      expression: "filter.value"
                    }
                  }),
              _c("Debug", {
                attrs: {
                  debug: filter.value
                }
              })
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }