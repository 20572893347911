var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-menu",
    {
      attrs: {
        elevation: 0,
        "offset-y": "",
        "offset-x": "",
        "nudge-left": 40,
        "nudge-bottom": 12,
        "close-on-content-click": false,
        rounded: "lg"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "div",
                {
                  staticClass: "ml-n2 mr-2 mt-n4"
                },
                [
                  !_vm.large
                    ? _c(
                        "v-btn",
                        _vm._g(
                          _vm._b(
                            {
                              attrs: {
                                icon: "",
                                "data-test-filter": ""
                              },
                              on: {
                                click: _vm.initTempFilter
                              }
                            },
                            "v-btn",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm.filter.length
                            ? _c(
                                "v-icon",
                                {
                                  ref: "toggleFilter",
                                  attrs: {
                                    color: _vm.filter.length ? "info" : ""
                                  }
                                },
                                [_vm._v(" mdi-filter ")]
                              )
                            : _c(
                                "v-icon",
                                {
                                  ref: "toggleFilter"
                                },
                                [_vm._v(" mdi-filter-outline ")]
                              )
                        ],
                        1
                      )
                    : _c(
                        "span",
                        {
                          staticClass: "ml-n7"
                        },
                        [
                          _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    elevation: "0",
                                    color: "info",
                                    small: ""
                                  },
                                  on: {
                                    click: _vm.initTempFilter
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _c(
                                "v-badge",
                                {
                                  attrs: {
                                    content: _vm.filter.length || "0",
                                    color: "warning"
                                  }
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "components.FilterCardPagination.editFilter"
                                          )
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                  _c("v-divider", {
                    staticClass: "mt-3 mb-n3",
                    staticStyle: {
                      position: "relative",
                      top: "-2px"
                    },
                    attrs: {
                      vertical: ""
                    }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.menu,
        callback: function callback($$v) {
          _vm.menu = $$v
        },
        expression: "menu"
      }
    },
    [
      _vm.menu
        ? _c(
            "v-card",
            {
              staticClass: "pa-1",
              style: "width: ".concat(
                _vm.$vuetify.breakpoint.smAndDown
                  ? "100vw"
                  : _vm.predefinedFilter && _vm.predefinedFilter.length
                  ? "650px"
                  : "450px",
                ";\n      word-break: break-word;\n      "
              ),
              attrs: {
                elevation: 0,
                rounded: "lg"
              }
            },
            [
              _c(
                "v-row",
                [
                  _vm.predefinedFilter
                    ? _c(
                        "v-col",
                        {
                          attrs: {
                            md: "4",
                            cols: "12"
                          }
                        },
                        [
                          _c("v-subheader", [
                            _vm._v(
                              _vm._s(
                                _vm.$t(
                                  "components.FilterCardPagination.suggestions"
                                )
                              )
                            )
                          ]),
                          _vm._l(_vm.predefinedFilter, function(
                            preFilter,
                            index
                          ) {
                            return _c(
                              "v-chip",
                              {
                                key: index,
                                staticClass: "ma-1",
                                attrs: {
                                  small: ""
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.setPredefinedFilter(
                                      preFilter.filter
                                    )
                                  }
                                }
                              },
                              [
                                _vm._v(
                                  " " + _vm._s(_vm.$t(preFilter.name)) + " "
                                )
                              ]
                            )
                          })
                        ],
                        2
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      attrs: {
                        md: _vm.mdSizeFilter,
                        cols: "12"
                      }
                    },
                    [
                      _c(
                        "v-form",
                        {
                          staticClass: "mt-6",
                          model: {
                            value: _vm.isValid,
                            callback: function callback($$v) {
                              _vm.isValid = $$v
                            },
                            expression: "isValid"
                          }
                        },
                        [
                          !_vm.tempFilter.length
                            ? _c("v-subheader", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "components.FilterCardPagination.noFilter"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm._l(_vm.tempFilter, function(filter, index) {
                            return _c(
                              "div",
                              {
                                key: "temp-filter" + index + filter.key,
                                staticClass: "ma-1 my-n4",
                                staticStyle: {
                                  display: "flex",
                                  "flex-direction": "row"
                                }
                              },
                              [
                                _c(
                                  "filter-card-pagination-menu-filter-element",
                                  {
                                    attrs: {
                                      value: filter,
                                      filterList: _vm.filterList
                                    },
                                    on: {
                                      remove: function remove($event) {
                                        return _vm.removeItem(index)
                                      }
                                    }
                                  }
                                )
                              ],
                              1
                            )
                          }),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-col",
                                {
                                  attrs: {
                                    cols: "12"
                                  }
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "info--text ml-1",
                                      attrs: {
                                        text: "",
                                        small: "",
                                        "data-test-filter-add": ""
                                      },
                                      on: {
                                        click: _vm.addFilter
                                      }
                                    },
                                    [
                                      _c("small", [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "components.FilterCardPagination.add"
                                              )
                                            ) +
                                            " "
                                        )
                                      ])
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        2
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        outlined: "",
                        color: "info",
                        disabled: !_vm.tempFilter.length
                      },
                      on: {
                        click: _vm.removeAll
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.FilterCardPagination.removeAll")
                          ) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        elevation: "0",
                        color: "info",
                        disabled: !_vm.isValid,
                        "data-test-filter-apply": ""
                      },
                      on: {
                        click: _vm.confirmFilter
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("confirm")) + " ")]
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }