var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c("debug", [
        _vm._v(" value " + _vm._s(_vm.value)),
        _c("br"),
        _vm._v(" selectedFilterElement " + _vm._s(_vm.selectedFilterElement)),
        _c("br"),
        _vm._v(" value " + _vm._s(_vm.value.id)),
        _c("br"),
        _vm._v(
          " filterList " +
            _vm._s(
              _vm.filterList.map(function(f) {
                return f.id
              })
            ) +
            " "
        )
      ]),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "flex-direction": "row"
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                "margin-right": "2px",
                flex: "1"
              },
              style: _vm.typeInputWidthStyle
            },
            [
              _c("v-combobox", {
                attrs: {
                  items: _vm.filterList,
                  filter: _vm.keyFilter,
                  "item-value": "id",
                  outlined: "",
                  dense: "",
                  "data-test-filter-key": ""
                },
                scopedSlots: _vm._u([
                  {
                    key: "append",
                    fn: function fn() {
                      return [
                        _c("div", {
                          staticStyle: {
                            display: "none"
                          }
                        })
                      ]
                    },
                    proxy: true
                  },
                  {
                    key: "item",
                    fn: function fn(_ref) {
                      var item = _ref.item
                      return [
                        _c("debug", [_vm._v(" " + _vm._s(item))]),
                        _vm._v(
                          " " +
                            _vm._s(
                              item.displayName
                                ? _vm.$t(item.displayName)
                                : item.key
                            ) +
                            " "
                        )
                      ]
                    }
                  },
                  {
                    key: "selection",
                    fn: function fn(_ref2) {
                      var item = _ref2.item
                      return [
                        _c("debug", [_vm._v(" " + _vm._s(item))]),
                        _vm._v(
                          " " +
                            _vm._s(
                              item.displayName
                                ? _vm.$t(item.displayName)
                                : item.key
                            ) +
                            " "
                        )
                      ]
                    }
                  }
                ]),
                model: {
                  value: _vm.selectedFilterElement,
                  callback: function callback($$v) {
                    _vm.selectedFilterElement = $$v
                  },
                  expression: "selectedFilterElement"
                }
              })
            ],
            1
          ),
          _vm.selectedFilterElement && _vm.isComparatorSelection
            ? _c(
                "div",
                {
                  staticStyle: {
                    width: "45px",
                    flex: "0",
                    "margin-right": "2px",
                    "margin-left": "2px"
                  }
                },
                [
                  _vm.isComparatorSelection
                    ? _c("v-select", {
                        attrs: {
                          items: _vm.availableOperations,
                          "hide-details": "",
                          outlined: "",
                          dense: "",
                          "data-test-filter-operation": ""
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "append",
                              fn: function fn() {
                                return [
                                  _c("div", {
                                    staticStyle: {
                                      display: "none"
                                    }
                                  })
                                ]
                              },
                              proxy: true
                            },
                            {
                              key: "item",
                              fn: function fn(_ref3) {
                                var item = _ref3.item
                                return [
                                  _c("v-spacer"),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm.$t(
                                          "components.filter.".concat(item)
                                        )
                                      ) +
                                      " "
                                  ),
                                  _c("v-spacer")
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function fn(_ref4) {
                                var item = _ref4.item
                                return [
                                  _c("v-spacer"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        position: "relative",
                                        left: "2px"
                                      }
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.$t(
                                              "components.filter.".concat(item)
                                            )
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _c("v-spacer")
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          16633753
                        ),
                        model: {
                          value: _vm.value.operation,
                          callback: function callback($$v) {
                            _vm.$set(_vm.value, "operation", $$v)
                          },
                          expression: "value.operation"
                        }
                      })
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _vm.selectedFilterElement
            ? _c(
                "div",
                {
                  staticStyle: {
                    "margin-left": "2px",
                    flex: "1"
                  },
                  style: _vm.valueInputWidthStyle
                },
                [
                  _c("filter-card-pagination-value-field", {
                    attrs: {
                      filterConfiguration: _vm.selectedFilterElement
                    },
                    model: {
                      value: _vm.value.value,
                      callback: function callback($$v) {
                        _vm.$set(_vm.value, "value", $$v)
                      },
                      expression: "value.value"
                    }
                  })
                ],
                1
              )
            : _vm._e(),
          _c(
            "div",
            {
              staticStyle: {
                width: "24px",
                flex: "0",
                position: "relative",
                top: "7px"
              }
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "ma-auto",
                  attrs: {
                    icon: "",
                    small: ""
                  },
                  on: {
                    click: function click($event) {
                      return _vm.$emit("remove")
                    }
                  }
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        small: ""
                      }
                    },
                    [_vm._v(" mdi-close ")]
                  )
                ],
                1
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }