var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.hasPrev || _vm.forceNextBtn
    ? _c(
        "div",
        [
          _c(
            "v-footer",
            {
              staticClass: "hidden-sm-and-up zindex",
              attrs: {
                fixed: "",
                elevation: "7",
                padless: ""
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "text-center pb-4",
                  attrs: {
                    flat: "",
                    tile: "",
                    width: "100%",
                    color: _vm.$vuetify.theme.dark
                      ? "grey darken-3"
                      : "white lighten-1 "
                  }
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        {
                          attrs: {
                            align: "center",
                            justify: "space-around"
                          }
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    align: "center",
                                    justify: "space-around"
                                  }
                                },
                                [
                                  _vm.hasPrev
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            rounded: "",
                                            outlined: "",
                                            fab: "",
                                            small: ""
                                          },
                                          on: {
                                            click: _vm.emitPrev
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-left")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.hasPrev
                                ? _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-around"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("back")) + " ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          ),
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-row",
                                {
                                  attrs: {
                                    align: "center",
                                    justify: "space-around"
                                  }
                                },
                                [
                                  _vm.hasNext
                                    ? _c(
                                        "v-btn",
                                        {
                                          staticClass: "ma-2",
                                          attrs: {
                                            rounded: "",
                                            outlined: "",
                                            fab: "",
                                            small: "",
                                            color: "success",
                                            disabled: _vm.disableNext
                                          },
                                          on: {
                                            click: _vm.emitNext
                                          }
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v("mdi-arrow-right")
                                          ])
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              ),
                              _vm.hasNext
                                ? _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        align: "center",
                                        justify: "space-around"
                                      }
                                    },
                                    [_vm._v(" " + _vm._s(_vm.$t("next")) + " ")]
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-container",
            [
              _c(
                "v-card-actions",
                {
                  staticClass:
                    "progressContainer card-actions hidden-sm-and-down d-sm-inline"
                },
                [
                  _c(
                    "v-row",
                    [
                      _vm.hasPrev
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-4",
                              attrs: {
                                id: "prev",
                                "x-large": "",
                                elevation: "0"
                              },
                              on: {
                                click: _vm.emitPrev
                              }
                            },
                            [
                              _c("v-icon", [_vm._v("mdi-arrow-left")]),
                              _vm._v(" " + _vm._s(_vm.$t("back")) + " ")
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("v-spacer"),
                      _vm.hasNext
                        ? _c(
                            "v-btn",
                            {
                              staticClass: "ma-4",
                              attrs: {
                                id: "next",
                                disabled: _vm.disableNext,
                                "x-large": "",
                                elevation: "0",
                                color: "green"
                              },
                              on: {
                                click: _vm.emitNext
                              }
                            },
                            [
                              _vm._v(" " + _vm._s(_vm.$t("next")) + " "),
                              _c("v-icon", [_vm._v("mdi-arrow-right")])
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }