var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-toolbar",
        {
          staticStyle: {
            "background-color": "rgb(250, 250, 250)"
          },
          style: _vm.$vuetify.breakpoint.smAndDown ? "" : _vm.border,
          attrs: {
            id: "selectorMenuToolbar",
            dense: "",
            flat: "",
            height: "37"
          }
        },
        [
          _c(
            "v-row",
            [
              _vm._l(_vm.positionEditOptionsMobile(_vm.token), function(
                _ref,
                index
              ) {
                var collapsedIcon = _ref.collapsedIcon,
                  content = _ref.content,
                  isDisabled = _ref.isDisabled
                return _c(
                  "v-card",
                  {
                    key: "options" + index,
                    staticClass: "mr-1",
                    attrs: {
                      outlined: "",
                      flat: ""
                    }
                  },
                  [
                    collapsedIcon
                      ? _c(
                          "v-btn",
                          {
                            key: collapsedIcon,
                            attrs: {
                              icon: "",
                              small: "",
                              disabled:
                                !_vm.editValues || !_vm.token
                                  ? true
                                  : isDisabled
                                  ? isDisabled(_vm.token.token)
                                  : false
                            },
                            on: {
                              click: function click($event) {
                                return _vm.toggleSelected(collapsedIcon)
                              }
                            }
                          },
                          [
                            _c("v-icon", [
                              _vm._v(" " + _vm._s(collapsedIcon) + " ")
                            ])
                          ],
                          1
                        )
                      : _vm._e(),
                    !collapsedIcon || _vm.selected === collapsedIcon
                      ? _c(
                          "span",
                          [
                            _c("v-slide-x-transition", [
                              _c(
                                "span",
                                {
                                  class: collapsedIcon ? "ml-1" : ""
                                },
                                _vm._l(content, function(option) {
                                  return _c(
                                    "v-btn",
                                    {
                                      key: option.icon,
                                      attrs: {
                                        icon: "",
                                        small: "",
                                        disabled:
                                          !_vm.editValues || !_vm.token
                                            ? true
                                            : _vm.isButtonDisabled(
                                                option.icon,
                                                _vm.token.token
                                              )
                                      },
                                      on: {
                                        click: option.click
                                      }
                                    },
                                    [
                                      _c("v-icon", [
                                        _vm._v(" " + _vm._s(option.icon) + " ")
                                      ])
                                    ],
                                    1
                                  )
                                }),
                                1
                              )
                            ])
                          ],
                          1
                        )
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c(
                "span",
                [
                  _vm.token &&
                  !_vm.editValues &&
                  !_vm.isTextField(_vm.token.token) &&
                  !_vm.isSignature(_vm.token.token)
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            top: "",
                            disabled: !_vm.editValues,
                            left: "",
                            "close-on-content-click": false,
                            transition: "slide-y-reverse-transition",
                            elevation: "0"
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function fn(activator) {
                                  return [
                                    _c(
                                      "v-card",
                                      {
                                        staticClass: "ml-1 mr-1",
                                        attrs: {
                                          outlined: "",
                                          flat: ""
                                        }
                                      },
                                      [
                                        _c(
                                          "v-btn",
                                          {
                                            attrs: {
                                              icon: "",
                                              small: "",
                                              disabled: !_vm.editValues
                                            }
                                          },
                                          [
                                            _c(
                                              "v-icon",
                                              _vm._g(
                                                _vm._b(
                                                  {},
                                                  "v-icon",
                                                  activator.attrs,
                                                  false
                                                ),
                                                activator.on
                                              ),
                                              [_vm._v(" mdi-keyboard-space ")]
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                }
                              }
                            ],
                            null,
                            false,
                            2530953026
                          )
                        },
                        [
                          _c(
                            "div",
                            {
                              style:
                                "\n                background-color:white;\n                width: 100%;\n                overflow:hidden;\n                zoom: 75%;\n                padding: 5px;\n                border-width: 2px;\n              "
                            },
                            [
                              _c("template-editor-placeholder-dropdown", {
                                ref: "dropdown",
                                staticClass: "mb-3",
                                attrs: {
                                  context: {
                                    report: true,
                                    partner: true
                                  },
                                  partnerId: _vm.partnerId,
                                  dense: true
                                }
                              }),
                              _c(
                                "v-btn",
                                {
                                  staticStyle: {
                                    position: "absolute",
                                    bottom: "-3px",
                                    right: "5px"
                                  },
                                  attrs: {
                                    color: "success"
                                  },
                                  on: {
                                    click: function click() {
                                      return _vm.confirmDropdown(_vm.token)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.$t("sign.Sign.select")) +
                                      " "
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ]
                      )
                    : _vm.token &&
                      (_vm.isTextField(_vm.token.token) ||
                        (_vm.editValues && !_vm.isSignature(_vm.token.token)))
                    ? _c(
                        "v-menu",
                        {
                          attrs: {
                            disabled: !_vm.editValues,
                            top: "",
                            left: "",
                            "close-on-content-click": false,
                            transition: "slide-y-reverse-transition",
                            elevation: "0"
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function fn(activator) {
                                return [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "ml-1 mr-1",
                                      attrs: {
                                        outlined: "",
                                        flat: ""
                                      }
                                    },
                                    [
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            icon: "",
                                            small: "",
                                            disabled: !_vm.editValues
                                          }
                                        },
                                        [
                                          _c(
                                            "v-icon",
                                            _vm._g(
                                              _vm._b(
                                                {},
                                                "v-icon",
                                                activator.attrs,
                                                false
                                              ),
                                              activator.on
                                            ),
                                            [_vm._v(" mdi-keyboard-space ")]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ]
                              }
                            }
                          ])
                        },
                        [
                          _c(
                            "div",
                            {
                              style:
                                "\n                background-color:white;\n                width: 500px;\n                overflow:hidden;\n                zoom: 75%;\n                padding: 5px;\n                border-width: 2px;\n              "
                            },
                            [
                              _c("v-text-field", {
                                staticClass: "mb-n7",
                                attrs: {
                                  flat: "",
                                  outlined: ""
                                },
                                model: {
                                  value: _vm.token.value,
                                  callback: function callback($$v) {
                                    _vm.$set(_vm.token, "value", $$v)
                                  },
                                  expression: "token.value"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              ),
              _c("v-spacer"),
              _c(
                "v-card",
                {
                  attrs: {
                    outlined: "",
                    flat: ""
                  }
                },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        disabled: !_vm.token,
                        icon: "",
                        small: ""
                      },
                      on: {
                        click: _vm.close
                      }
                    },
                    [_c("v-icon", [_vm._v(" mdi-close ")])],
                    1
                  )
                ],
                1
              )
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }