import { render, staticRenderFns } from "./BookingCancel.vue?vue&type=template&id=390e3028&scoped=true&"
import script from "./BookingCancel.vue?vue&type=script&lang=ts&"
export * from "./BookingCancel.vue?vue&type=script&lang=ts&"
import style0 from "./BookingCancel.vue?vue&type=style&index=0&id=390e3028&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "390e3028",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('390e3028')) {
      api.createRecord('390e3028', component.options)
    } else {
      api.reload('390e3028', component.options)
    }
    module.hot.accept("./BookingCancel.vue?vue&type=template&id=390e3028&scoped=true&", function () {
      api.rerender('390e3028', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/customer/BookingCancel.vue"
export default component.exports