var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      _vm.isReportPortalContext
        ? _c(
            "div",
            [
              _c(
                "v-row",
                {
                  staticClass: "mt-4 mb-n4"
                },
                [
                  _c(
                    "v-col",
                    [
                      _c("v-select", {
                        staticClass: "mx-4",
                        attrs: {
                          type: "string",
                          disabled: _vm.disabled,
                          items: _vm.availableLanguages,
                          label: _vm.$t("language"),
                          outlined: ""
                        },
                        on: {
                          change: function change($event) {
                            return _vm.onChangeLanguage($event)
                          }
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "item",
                              fn: function fn(_ref) {
                                var item = _ref.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "enums.LanguageCodeEnum.".concat(
                                              item
                                            )
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm._f("getFlagEmojiByLanguage")(item)
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            },
                            {
                              key: "selection",
                              fn: function fn(_ref2) {
                                var item = _ref2.item
                                return [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "enums.LanguageCodeEnum.".concat(
                                              item
                                            )
                                          )
                                        ) +
                                        " " +
                                        _vm._s(
                                          _vm._f("getFlagEmojiByLanguage")(item)
                                        ) +
                                        " "
                                    )
                                  ])
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          3376209916
                        ),
                        model: {
                          value: _vm.userLanguage,
                          callback: function callback($$v) {
                            _vm.userLanguage = $$v
                          },
                          expression: "userLanguage"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-list-item",
                {
                  staticClass: "pa-0 ma-0",
                  attrs: {
                    "three-line": ""
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _c("v-card-title", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.profile.notifications.assignee"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c("v-card-subtitle", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t(
                                "components.profile.notifications.assigneeExplanation"
                              )
                            ) +
                            " "
                        )
                      ]),
                      _c(
                        "v-list-subtitle",
                        [
                          _c("v-checkbox", {
                            staticClass: "mx-4 my-n2",
                            attrs: {
                              disabled: _vm.disabled,
                              label: _vm.$t(
                                "components.profile.notifications.email"
                              )
                            },
                            model: {
                              value: _vm.assigneeEmail,
                              callback: function callback($$v) {
                                _vm.assigneeEmail = $$v
                              },
                              expression: "assigneeEmail"
                            }
                          }),
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                text: _vm.$t("comingSoon")
                              }
                            },
                            [
                              _c("v-checkbox", {
                                staticClass: "mx-4 my-n2",
                                attrs: {
                                  disabled: "",
                                  label: _vm.$t(
                                    "components.profile.notifications.push"
                                  )
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "div",
            {
              staticClass: "mt-8"
            },
            [
              _c(
                "v-row",
                [
                  _c("v-select", {
                    staticClass: "mx-4",
                    attrs: {
                      type: "string",
                      disabled: _vm.disabled,
                      items: _vm.availableLanguages,
                      label: _vm.$t("language"),
                      outlined: ""
                    },
                    on: {
                      change: _vm.onChangeLanguage
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function fn(_ref3) {
                          var item = _ref3.item
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "enums.LanguageCodeEnum.".concat(item)
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("getFlagEmojiByLanguage")(item)
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      },
                      {
                        key: "selection",
                        fn: function fn(_ref4) {
                          var item = _ref4.item
                          return [
                            _c("span", [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "enums.LanguageCodeEnum.".concat(item)
                                    )
                                  ) +
                                  " " +
                                  _vm._s(
                                    _vm._f("getFlagEmojiByLanguage")(item)
                                  ) +
                                  " "
                              )
                            ])
                          ]
                        }
                      }
                    ]),
                    model: {
                      value: _vm.userLanguage,
                      callback: function callback($$v) {
                        _vm.userLanguage = $$v
                      },
                      expression: "userLanguage"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-checkbox", {
                    staticClass: "mx-4 my-n2",
                    attrs: {
                      disabled: "",
                      label: _vm.$t(
                        "components.profile.ProfileCard.notificationTypes.system"
                      )
                    },
                    model: {
                      value: _vm.isSystemEmail,
                      callback: function callback($$v) {
                        _vm.isSystemEmail = $$v
                      },
                      expression: "isSystemEmail"
                    }
                  })
                ],
                1
              ),
              _c(
                "v-row",
                [
                  _c("v-checkbox", {
                    staticClass: "mx-4 my-n2",
                    attrs: {
                      disabled: _vm.disabled,
                      label: _vm.$t(
                        "components.profile.ProfileCard.notificationTypes.news"
                      )
                    },
                    model: {
                      value: _vm.marketingOptIn,
                      callback: function callback($$v) {
                        _vm.marketingOptIn = $$v
                      },
                      expression: "marketingOptIn"
                    }
                  })
                ],
                1
              ),
              _vm.partnerNotificationsEnabled
                ? _c(
                    "v-row",
                    [
                      _c("v-card-title", [
                        _vm._v(_vm._s(_vm.partnerCompanyName))
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _vm.partnerNotificationsEnabled
                ? _c(
                    "v-row",
                    [
                      _c("v-checkbox", {
                        staticClass: "mx-4 my-n2",
                        attrs: {
                          disabled: _vm.disabled,
                          label: _vm.$t(
                            "components.profile.ProfileCard.notificationTypes.news"
                          )
                        },
                        model: {
                          value: _vm.partnerNotificationsLocal,
                          callback: function callback($$v) {
                            _vm.partnerNotificationsLocal = $$v
                          },
                          expression: "partnerNotificationsLocal"
                        }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          ),
      !_vm.isDisabled
        ? _c("profile-confirmation-row", {
            attrs: {
              isLoading: _vm.isLoading,
              valid: _vm.valid
            },
            on: {
              save: _vm.save,
              abort: _vm.abort
            }
          })
        : _vm._e(),
      _vm.isDisabled
        ? _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  attrs: {
                    loading: _vm.isLoading,
                    color: "primary",
                    "data-test-profile-edit": ""
                  },
                  on: {
                    click: function click($event) {
                      _vm.isDisabled = !_vm.isDisabled
                    }
                  }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t("components.profile.EditProfileDialog.edit")
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }