var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: _vm.$t("views.Error.title")
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "mx-auto"
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-auto",
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-auto",
                  attrs: {
                    flat: "",
                    align: "center"
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.$t("views.NoRoles.title"))),
                    _c("br"),
                    _vm._v(" " + _vm._s(_vm.$t("views.NoRoles.subtitle")) + " ")
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-container",
        {
          staticClass: "mx-auto"
        },
        [
          _c(
            "v-row",
            {
              staticClass: "ma-auto",
              attrs: {
                align: "center"
              }
            },
            [
              _c(
                "v-card",
                {
                  staticClass: "ma-auto",
                  attrs: {
                    flat: "",
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-card-actions",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            outlined: "",
                            text: ""
                          },
                          on: {
                            click: _vm.sendMail
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("views.NoRoles.sendMail")) + " "
                          )
                        ]
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            flat: "",
                            outlined: "",
                            text: ""
                          },
                          on: {
                            click: _vm.backToLogin
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("views.NoRoles.backToLogin")) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }