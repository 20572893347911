var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "confirm-action-dialog",
    {
      attrs: {
        title: _vm.$t(
          "components.fleet.FleetVehicleDetailDocumentCard.addDocumentDialogTitle"
        ),
        isDialogActive: _vm.dialogActive,
        leftDisabled: _vm.isLoading,
        rightDisabled: _vm.rightDisabled,
        rightLoading: _vm.isLoading
      },
      on: {
        "update:isDialogActive": function updateIsDialogActive($event) {
          _vm.dialogActive = $event
        },
        "update:is-dialog-active": function updateIsDialogActive($event) {
          _vm.dialogActive = $event
        },
        leftClick: function leftClick($event) {
          _vm.dialogActive = false
        },
        rightClick: _vm.save
      }
    },
    [
      _c("sign-document-form", {
        ref: "form",
        staticClass: "ml-4 mr-4 mb-2",
        attrs: {
          hideButton: true,
          isLoading: _vm.isLoading,
          hideTemplateOption: true,
          folder: _vm.folder
        },
        on: {
          updateValid: _vm.updateValid
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }