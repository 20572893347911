var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    [
      _c(
        "v-form",
        {
          ref: "form",
          model: {
            value: _vm.valid,
            callback: function callback($$v) {
              _vm.valid = $$v
            },
            expression: "valid"
          }
        },
        [
          _c("profile-form-fields", {
            staticClass: "mr-n3 ml-n3",
            attrs: {
              user: _vm.user,
              disabled: _vm.isDisabled
            }
          }),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c("v-spacer"),
                  _vm.isDisabled
                    ? _c(
                        "v-btn",
                        {
                          staticClass: "mt-5 mr-10",
                          attrs: {
                            loading: _vm.isLoading,
                            color: "primary",
                            "data-test-profile-edit": ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.isDisabled = !_vm.isDisabled
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "components.profile.EditProfileDialog.edit"
                                )
                              ) +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          !_vm.isDisabled
            ? _c("profile-confirmation-row", {
                attrs: {
                  isLoading: _vm.isLoading,
                  valid: _vm.valid
                },
                on: {
                  save: _vm.save,
                  abort: _vm.abort
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }