var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      attrs: {
        dense: "",
        "v-if": _vm.isNotification
      }
    },
    [
      _c(
        "v-col",
        _vm._l(_vm.notifications, function(notification, index) {
          return _c(
            "v-alert",
            {
              key: index,
              staticClass: "customPointer",
              attrs: {
                prominent: !_vm.smallDisplay,
                dense: "",
                text: "",
                type: notification.type,
                width: "100%",
                elevation: "1"
              }
            },
            [
              _c(
                "v-row",
                {
                  attrs: {
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "grow"
                    },
                    [_vm._v(" " + _vm._s(_vm.$t(notification.message)) + " ")]
                  ),
                  notification.hasTextField
                    ? _c(
                        "v-col",
                        {
                          staticClass: "shrink",
                          attrs: {
                            cols: _vm.isSmallDisplay,
                            "align-self": "center"
                          }
                        },
                        [
                          _c("v-text-field", {
                            ref: "alertField",
                            refInFor: true,
                            attrs: {
                              "single-line": "",
                              outlined: "",
                              "hide-details": "",
                              dense: "",
                              light: true,
                              "background-color": notification.textFieldBgColor,
                              rules: _vm.getRules(notification),
                              type: notification.textFieldType,
                              placeholder: _vm.$t(
                                notification.textFieldPlaceholder
                              )
                            },
                            model: {
                              value: _vm.fieldValue,
                              callback: function callback($$v) {
                                _vm.fieldValue = $$v
                              },
                              expression: "fieldValue"
                            }
                          })
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "v-col",
                    {
                      staticClass: "shrink",
                      attrs: {
                        cols: _vm.isSmallDisplay
                      }
                    },
                    [
                      notification.dismissText
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                outlined: "",
                                small: "",
                                disabled: _vm.isLoading,
                                color: notification.type
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.popNotification(index)
                                }
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.$t(notification.dismissText)) +
                                  " "
                              )
                            ]
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          staticClass: "ml-2",
                          attrs: {
                            loading: _vm.isLoading,
                            small: "",
                            color: notification.type
                          },
                          on: {
                            click: function click($event) {
                              return _vm.processNotification(
                                notification,
                                index
                              )
                            }
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t(notification.actionText)) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }