var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
    ? _c(
        "div",
        [
          _c("the-navigation-layout", {
            attrs: {
              items: _vm.items,
              title: _vm.title,
              isEnabled: true
            }
          })
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }