var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      !_vm.isBtn
        ? _c(
            "v-btn",
            {
              attrs: {
                icon: ""
              },
              on: {
                click: function click($event) {
                  _vm.dialog = true
                }
              }
            },
            [_c("v-icon", [_vm._v("mdi-clipboard-multiple-outline")])],
            1
          )
        : _c(
            "v-btn",
            {
              staticClass: "mr-2",
              attrs: {
                "x-small": "",
                dense: "",
                outlined: "",
                color: "info"
              },
              on: {
                click: function click($event) {
                  _vm.dialog = true
                }
              }
            },
            [_vm._v(" Link ")]
          ),
      _c(
        "confirm-action-dialog",
        {
          attrs: {
            isDialogActive: _vm.dialog,
            title: _vm.$t("sign.SignInitializeCard.title"),
            hideRight: true,
            hideLeft: true
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.dialog = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.dialog = $event
            }
          }
        },
        [
          _vm.request
            ? _c("sign-initialize-card", {
                attrs: {
                  id: _vm.request.id,
                  recipientFirstName: _vm.request.recipient.firstName,
                  recipientLastName: _vm.request.recipient.lastName
                },
                on: {
                  close: function close($event) {
                    _vm.dialog = false
                  }
                }
              })
            : _vm._e()
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }