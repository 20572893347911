var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "login-component-layout",
    {
      attrs: {
        component: "LoginCardConfirmMailRequestCode"
      }
    },
    [
      _c(
        "div",
        {
          staticClass: "mb-6"
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t(
                  "components.login.LoginCardConfirmMailRequestCode.instructions"
                )
              ) +
              " "
          )
        ]
      ),
      _c(
        "v-form",
        {
          on: {
            submit: function submit($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function callback($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c("login-component-input-mail"),
          _c("login-component-message", {
            attrs: {
              failText: _vm.failText,
              successText: _vm.successText
            }
          }),
          _c("login-component-submit-button", {
            attrs: {
              isLoading: _vm.isLoading,
              isFormValid: _vm.isFormValid,
              component: "LoginCardConfirmMailRequestCode"
            }
          }),
          _c("login-component-navigation-button", {
            attrs: {
              to: "/login/confirmMail"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }