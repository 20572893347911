var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "login-component-layout",
    {
      attrs: {
        component: "LoginCardEnterPassword"
      }
    },
    [
      _c(
        "v-form",
        {
          on: {
            submit: function submit($event) {
              $event.preventDefault()
              return _vm.submit.apply(null, arguments)
            }
          },
          model: {
            value: _vm.isFormValid,
            callback: function callback($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid"
          }
        },
        [
          _c("login-component-input-mail"),
          _c("login-component-input-password", {
            attrs: {
              onlyRequired: true
            }
          }),
          _c("login-component-message", {
            attrs: {
              failText: _vm.failText,
              successText: _vm.successText
            }
          }),
          _c("login-component-submit-button", {
            attrs: {
              isFormValid: !_vm.isButtonDisabled,
              isLoading: _vm.isLoading,
              component: "LoginCardEnterPassword"
            }
          }),
          _vm.isAppWithCustomerRegistration
            ? _c("login-component-navigation-button", {
                attrs: {
                  to: "/sign-up"
                }
              })
            : _vm._e(),
          _vm.isAppWithCustomerRegistration
            ? _c("v-divider", {
                staticClass: "ma-4"
              })
            : _vm._e(),
          _c("login-component-navigation-button", {
            attrs: {
              to: "/login/forgot"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }