var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          return _vm.handleDragOver.apply(null, arguments)
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          return _vm.handleDragLeave.apply(null, arguments)
        },
        drop: function drop($event) {
          $event.preventDefault()
          return _vm.handleFileDrop($event)
        }
      }
    },
    [
      _c(
        "v-card",
        {
          attrs: {
            width: "640px",
            "max-height": "125",
            height: "125",
            align: "center",
            color: _vm.$vuetify.theme.dark ? "grey darken-1" : "grey lighten-4",
            elevation: "0"
          },
          on: {
            click: function click($event) {
              return _vm.$emit("click")
            }
          }
        },
        [
          _vm.isDrag
            ? _c(
                "div",
                {
                  staticClass: "drag fadeIn"
                },
                [
                  !_vm.isDrop
                    ? _c(
                        "v-icon",
                        {
                          staticStyle: {
                            zoom: "3"
                          },
                          attrs: {
                            "x-large": ""
                          }
                        },
                        [_vm._v("mdi-file")]
                      )
                    : _vm.isFailed
                    ? _c(
                        "v-icon",
                        {
                          staticClass: "fade",
                          staticStyle: {
                            zoom: "3"
                          },
                          attrs: {
                            "x-large": "",
                            color: "error"
                          }
                        },
                        [_vm._v("mdi-close")]
                      )
                    : _c(
                        "v-icon",
                        {
                          staticClass: "fade",
                          staticStyle: {
                            zoom: "3"
                          },
                          attrs: {
                            "x-large": "",
                            color: "success"
                          }
                        },
                        [_vm._v("mdi-check-bold")]
                      )
                ],
                1
              )
            : _vm._e(),
          _c(
            "v-card-text",
            {
              staticClass: "prevent-select",
              attrs: {
                align: "center"
              }
            },
            [
              !_vm.isLoading && !_vm.isDrop
                ? _c(
                    "div",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            large: "",
                            color: _vm.iconColor
                          }
                        },
                        [_vm._v(_vm._s(_vm.icon))]
                      ),
                      _c("br"),
                      _vm._v(" " + _vm._s(_vm.$t(_vm.displayText)) + " "),
                      _c("br")
                    ],
                    1
                  )
                : _c("v-progress-circular", {
                    attrs: {
                      indeterminate: "",
                      size: "70"
                    }
                  })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }