import { render, staticRenderFns } from "./QuickLinkList.vue?vue&type=template&id=d284faee&"
import script from "./QuickLinkList.vue?vue&type=script&lang=ts&"
export * from "./QuickLinkList.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemGroup } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCard,VCardText,VDivider,VIcon,VList,VListItem,VListItemAvatar,VListItemContent,VListItemGroup,VListItemSubtitle,VListItemTitle,VSubheader,VTextField})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d284faee')) {
      api.createRecord('d284faee', component.options)
    } else {
      api.reload('d284faee', component.options)
    }
    module.hot.accept("./QuickLinkList.vue?vue&type=template&id=d284faee&", function () {
      api.rerender('d284faee', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/navigation/QuickLinkList.vue"
export default component.exports