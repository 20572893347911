var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      staticClass: "pb-0",
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        {
          staticClass: "pb-0"
        },
        [
          _c(
            "p",
            {
              staticClass: "display-1 text--primary autoHypen"
            },
            [_vm._v(_vm._s(_vm.$t(_vm.title)))]
          ),
          _c("p", {
            staticClass: "mb-0 pb-4 autoHypen",
            domProps: {
              innerHTML: _vm._s(_vm.$t(_vm.description))
            }
          }),
          _c("Debug", {
            attrs: {
              debug: _vm.debug
            }
          }),
          _vm.image
            ? _c("v-img", {
                staticClass: "hidden-sm-and-down",
                attrs: {
                  contain: "",
                  src: _vm.image,
                  height: _vm.imageHeight,
                  justify: "center"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "pt-0"
        },
        [
          _c(
            "v-bottom-sheet",
            {
              attrs: {
                inset: "",
                "max-width": "500px"
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on,
                      attrs = _ref.attrs
                    return [
                      _vm.hasInstructions
                        ? _c(
                            "v-btn",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    icon: "",
                                    fab: "",
                                    small: "",
                                    "data-test-instruction-toggle": ""
                                  }
                                },
                                "v-btn",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [_c("v-icon", [_vm._v(" mdi-help-box ")])],
                            1
                          )
                        : _vm._e()
                    ]
                  }
                }
              ]),
              model: {
                value: _vm.dialog,
                callback: function callback($$v) {
                  _vm.dialog = $$v
                },
                expression: "dialog"
              }
            },
            [
              _c("InstructionCard", {
                attrs: {
                  instructionTitle: _vm.instructionTitle,
                  instructionText: _vm.instructionText,
                  instructionPictures: _vm.instructionPictures
                },
                on: {
                  clicked: _vm.disableDialog
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _vm._t("default")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }