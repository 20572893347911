var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-navigation-drawer",
    {
      attrs: {
        app: "",
        height: "100%",
        width: "300px"
      },
      scopedSlots: _vm._u([
        {
          key: "append",
          fn: function fn() {
            return [_c("the-settings-list")]
          },
          proxy: true
        }
      ]),
      model: {
        value: _vm.drawer,
        callback: function callback($$v) {
          _vm.drawer = $$v
        },
        expression: "drawer"
      }
    },
    [
      _vm.isAuthenticated && _vm.navigationSettings.showMmmmintLogo
        ? _c("the-navigation-drawer-header")
        : _vm._e(),
      _c(
        "v-list",
        {
          staticClass: "pr-3",
          attrs: {
            expand: "",
            shaped: ""
          }
        },
        [
          _c(
            "v-list-item-group",
            {
              attrs: {
                color: "primary",
                "active-class": "navigation-base-box-shadow"
              },
              model: {
                value: _vm.selectedItem,
                callback: function callback($$v) {
                  _vm.selectedItem = $$v
                },
                expression: "selectedItem"
              }
            },
            [
              _vm.navigationSettings.showTheReportList
                ? _c("the-report-list")
                : _vm._e(),
              _vm.navigationSettings.showTheBookingList
                ? _c("the-booking-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgList
                ? _c("the-thg-list")
                : _vm._e(),
              _vm.navigationSettings.showThePartnerList
                ? _c("the-partner-list")
                : _vm._e(),
              _vm.navigationSettings.showTheOrganizationList
                ? _c("the-organization-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgPartnerList
                ? _c("the-thg-partner-list")
                : _vm._e(),
              _vm.navigationSettings.showTheOnlineBookingList
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.onlineBookingItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showTheDigitalSignatureList
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.signatureItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showTheProjectList
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.projectItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showTheCostList
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.costItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showTheCustomerList
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.customerItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showTheAnonymizer
                ? _c("the-navigation-layout", {
                    attrs: {
                      items: _vm.anonymizationItems,
                      isEnabled: true
                    }
                  })
                : _vm._e(),
              _vm.navigationSettings.showThePartnerList
                ? _c("the-partner-setting-list")
                : _vm._e(),
              _vm.navigationSettings.showTheAdminList
                ? _c("the-admin-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgProcessingList
                ? _c("the-thg-processing-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgAdminList
                ? _c("the-thg-analytics-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgAdminList
                ? _c("the-thg-billing-list")
                : _vm._e(),
              _vm.navigationSettings.showTheThgAdminList
                ? _c("the-thg-admin-list")
                : _vm._e(),
              _vm.navigationSettings.showThgAboutList
                ? _c("the-thg-unauthenticated-list")
                : _vm._e(),
              _vm.navigationSettings.showThgAboutList
                ? _c("the-thg-about-list")
                : _vm._e(),
              _vm.navigationSettings.showDocphant
                ? _c("the-docphant-list")
                : _vm._e(),
              _vm.navigationSettings.showTheAboutList
                ? _c("the-about-list")
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }