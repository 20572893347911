var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-row",
        {
          attrs: {
            align: "center"
          }
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12",
                md: "12"
              }
            },
            [
              _c("v-skeleton-loader", {
                staticClass: "mx-auto",
                attrs: {
                  type:
                    "image, card-heading, list-item-three-line, divider, card-heading, list-item-three-line, list-item-three-line, actions"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }