var render = function() {
  var _vm$partner$address,
    _vm$partner$address2,
    _vm$partner$address3,
    _vm$partner$contact,
    _vm$partner$address4,
    _vm$partner$address5,
    _vm$partner$address6,
    _vm$partner$contact2

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: "Allgemeine Geschäftsbedingungen"
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "padded"
        },
        [
          _c(
            "v-card",
            {
              attrs: {
                flat: ""
              }
            },
            [
              _c("v-card-title", [_vm._v(" Allgemeine Geschäftsbedingungen ")]),
              _c("v-card-subtitle", [_vm._v(" Stand: 01.03.2024 ")]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "1"
                  }
                },
                [_vm._v(" Einführung ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  ' Die MMM Intelligence UG (haftungsbeschränkt), Marie-Curie-Str. 3, 49076 Osnabrück, Telefon: +49 176 72884125, E-Mail: info@mmmint.ai (nachfolgend auch "wir" oder "uns" genannt) hat eine Web-App mit Namen Schadensmeldung digital entwickelt, durch welche Nutzer Schäden an ihrem Fahrzeug digital erfassen, dokumentieren und direkt an z.B. Reparaturwerkstätten, Autohäuser, Versicherungen oder Fuhrparkleiter übermitteln können ("App"). '
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  ' (1) Diese Allgemeinen Nutzungsbedingungen ("Bedingungen") beschreiben deine Rechte und Pflichten als Nutzer unserer App. Diese Bedingungen stellen eine rechtsverbindliche Vereinbarung zwischen Dir als Nutzer und uns dar. '
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  ' (2) "Du" oder "Nutzer" bezeichnet Dich persönlich. Die App richtet sich ausschließlich an volljährige Personen. Für die Nutzung unserer App versicherst du, dass Du mindestens 18 Jahre alt bist. '
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " (3) Wenn Du Fragen zur App hast, kannst Du uns unter support@mmmint.ai erreichen. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " (4) Wir unterhalten weitere Bedingungen und Richtlinien, die diese Bedingungen ergänzen, wie z. B. unsere Datenschutzrichtlinie, die unsere Sammlung und Nutzung von persönlichen Daten beschreibt. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " (5) Wir speichern diesen Vertragstext nach Vertragsschluss unter Beachtung des Datenschutzes. Diese Bedingungen sind jederzeit auf der Website https://www.schadensmeldung.digital/datenschutz/ in druckbarer Version abrufbar. "
                )
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "2"
                  }
                },
                [_vm._v(" 1. Vertragsgegenstand ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  ' 1.1 Die App ermöglicht es Dir als Fahrzeughalter Schäden an Deinem Fahrzeug digital zu erfassen, zu dokumentieren und direkt an z.B. Reparaturwerkstätten, Autohäuser, Versicherungen oder Fuhrparkleiter ("Empfänger") zu übermitteln. Außerdem kannst Du in der App konkrete Leistungen und Preise bei einer von Dir gewählten Empfänger abfragen, Termine vereinbaren und verwalten. '
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 1.2 Die App ermöglicht es Dir ein persönliches Nutzerkonto anzulegen (Ziffer 2). Gewisse Funktionen der App können nur als registrierter Nutzer in Anspruch genommen werden, wie beispielsweise das Verwalten der Fahrzeugdaten, Schadensmeldungen und Termine. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 1.3 Gegenstand dieses Vertrages ist die Nutzung der App auf einer von uns betriebenen Website. Teilweise gelangst Du über die Website eines Empfängers auf unsere App. Dann kann es sein, dass wir die optische Gestaltung der App an das Corporate Design des Empfängers angelehnt haben; es kann insbesondere das Logo oder den Namenszug des Empfängers hinterlegt sein. Dennoch besteht der Vertrag über die Nutzung der App zwischen Dir und uns. Ausdrücklich nicht Gegenstand dieses Vertrages sind sämtliche Leistungen, die Du mit einem Empfänger über die App vereinbarst, also z.B. Reparaturleistungen. Für diese Leistungen gelten dann möglicherweise die (Allgemeinen) Vertragsbedingungen des Empfängers. Wir stellen lediglich die App als Plattform für Dich als Nutzer und für die Empfänger bereit. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 1.4 Wir sind berechtigt, die Funktionen und Ausgestaltung der App im Einzelnen jederzeit zu ändern, anzupassen und zu updaten, insbesondere aufgrund einer geänderten Rechtslage, technischer Entwicklungen oder zur Verbesserung der IT-Sicherheit. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(" 1.5 Die Nutzung der App ist für Dich kostenfrei. ")
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "3"
                  }
                },
                [_vm._v(" 2. Anlegen eines Nutzerkontos ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " 2.1 Falls Du bei uns ein Nutzerkonto anlegst, bleiben die von Dir mitgeteilten Daten im Nutzerkonto so lange gespeichert, bis Du uns mitteilst, dass die Löschung einzelner Informationen oder des Nutzerkontos als Ganzes gewünscht ist. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 2.2 Soweit sich persönliche Informationen ändern, bist Du selbst für deren Aktualisierung verantwortlich. Alle Änderungen können online nach Anmeldung in Deinem Nutzerkonto vorgenommen werden. "
                )
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "4"
                  }
                },
                [_vm._v(" 3. Verfügbarkeit ")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " Wir bemühen uns um eine hohe durchschnittliche Verfügbarkeit der App. Eine ununterbrochene Verfügbarkeit ist jedoch technisch nicht realisierbar. Es kann zu vorübergehenden Unterbrechungen oder Störungen der App kommen. "
                )
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "5"
                  }
                },
                [_vm._v(" 4. Haftung")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " 4.1 Wir haften Dir gegenüber in allen Fällen vertraglicher und außervertraglicher Haftung bei Vorsatz und grober Fahrlässigkeit nach Maßgabe der gesetzlichen Bestimmungen auf Schadensersatz oder Ersatz vergeblicher Aufwendungen. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 4.2 In sonstigen Fällen wir - soweit in Ziffer 4.3 nicht abweichend geregelt - nur bei Verletzung einer Vertragspflicht, deren Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf deren Einhaltung der Kunde regelmäßig vertrauen darf (sog. Kardinalpflicht), und zwar beschränkt auf den Ersatz des vorhersehbaren und typischen Schadens. In allen übrigen Fällen ist unsere Haftung - vorbehaltlich der Regelung in Ziffer 4.3 - ausgeschlossen. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 4.3 Unsere Haftung für Schäden aus der Verletzung des Lebens, des Körpers oder der Gesundheit und nach dem Produkthaftungsgesetz bleibt von den vorstehenden Haftungsbeschränkungen und -ausschlüssen unberührt. "
                )
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "6"
                  }
                },
                [_vm._v("5. Widerrufsrecht des Verbrauchers")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " 5.1 Diese Vereinbarung räumt dem Verbraucher ein Widerrufsrecht nach Maßgabe der nachfolgenden Widerrufsbelehrung ein. Verbraucher ist jede natürliche Person, die ein Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen noch ihrer selbständigen beruflichen Tätigkeit zugerechnet werden kann (§ 13 BGB). "
                )
              ]),
              _c("v-card-text", [_vm._v("5.2 Widerrufsbelehrung")]),
              _c("v-card-text", [
                _vm._v(
                  " Widerrufsrecht Sie haben das Recht, binnen vierzehn Tagen ohne Angabe von Gründen diesen Vertrag zu. Die Widerrufsfrist beträgt vierzehn Tage ab dem Tag des Vertragsabschlusses. Um Ihr Widerrufsrecht auszuüben, müssen Sie uns (" +
                    _vm._s(
                      ""
                        .concat(_vm.partner.companyName, ", ")
                        .concat(
                          (_vm$partner$address = _vm.partner.address) ===
                            null || _vm$partner$address === void 0
                            ? void 0
                            : _vm$partner$address.street,
                          ", "
                        )
                        .concat(
                          (_vm$partner$address2 = _vm.partner.address) ===
                            null || _vm$partner$address2 === void 0
                            ? void 0
                            : _vm$partner$address2.zip,
                          " "
                        )
                        .concat(
                          (_vm$partner$address3 = _vm.partner.address) ===
                            null || _vm$partner$address3 === void 0
                            ? void 0
                            : _vm$partner$address3.city
                        )
                    ) +
                    ", E-Mail: " +
                    _vm._s(
                      (_vm$partner$contact = _vm.partner.contact) === null ||
                        _vm$partner$contact === void 0
                        ? void 0
                        : _vm$partner$contact.email
                    ) +
                    " mittels einer eindeutigen Erklärung (z. B. ein mit der Post versandter Brief￼ oder E-Mail) über Ihren Entschluss, diesen Vertrag zu widerrufen, informieren. Zur Wahrung der Widerrufsfrist reicht es aus, dass Sie die Mitteilung über die Ausübung des Widerrufsrechts vor Ablauf der Widerrufsfrist absenden. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Wenn Sie diesen Vertrag widerrufen, haben wir Ihnen alle Zahlungen, die wir von Ihnen erhalten haben, einschließlich der Lieferkosten (mit Ausnahme der zusätzlichen Kosten, die sich daraus ergeben, dass Sie eine andere Art der Lieferung als die von uns angebotene, günstigste Standardlieferung gewählt haben), unverzüglich und spätestens binnen vierzehn Tagen ab dem Tag zurückzuzahlen, an dem die Mitteilung über Ihren Widerruf dieses Vertrags bei uns eingegangen ist. Für diese Rückzahlung verwenden wir dasselbe Zahlungsmittel, das Sie bei der ursprünglichen Transaktion eingesetzt haben, es sei denn mit Ihnen wurde ausdrücklich etwas anderes vereinbart; in keinem Fall werden Ihnen wegen dieser Rückzahlung Entgelte berechnet. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " Haben Sie verlangt, dass die Dienstleistungen während der Widerrufsfrist beginnen sollen, so haben Sie uns einen angemessenen Betrag zu zahlen, der dem Anteil der bis zu dem Zeitpunkt, zu dem Sie uns von der Ausübung des Widerrufsrechts hinsichtlich dieses Vertrages unterrichten, bereits erbrachten Dienstleistungen im Vergleich zum Gesamtumfang der im Vertrag vorgesehenen Dienstleistungen entspricht. "
                )
              ]),
              _c("v-card-text", [_vm._v("Muster-Widerrufsformular")]),
              _c("v-card-text", [
                _vm._v(
                  " Wenn Sie den Vertrag widerrufen wollen, dann füllen Sie bitte dieses Formular aus und senden Sie es zurück. "
                )
              ]),
              _c(
                "v-sheet",
                {
                  staticClass: "ma-4",
                  attrs: {
                    elevation: 5,
                    rounded: ""
                  }
                },
                [
                  _c("v-card-text", [
                    _vm._v(" " + _vm._s(_vm.partner.companyName)),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm$partner$address4 = _vm.partner.address) ===
                            null || _vm$partner$address4 === void 0
                            ? void 0
                            : _vm$partner$address4.street
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      " " +
                        _vm._s(
                          (_vm$partner$address5 = _vm.partner.address) ===
                            null || _vm$partner$address5 === void 0
                            ? void 0
                            : _vm$partner$address5.zip
                        ) +
                        " " +
                        _vm._s(
                          (_vm$partner$address6 = _vm.partner.address) ===
                            null || _vm$partner$address6 === void 0
                            ? void 0
                            : _vm$partner$address6.city
                        )
                    ),
                    _c("br"),
                    _vm._v(
                      " E-Mail: " +
                        _vm._s(
                          (_vm$partner$contact2 = _vm.partner.contact) ===
                            null || _vm$partner$contact2 === void 0
                            ? void 0
                            : _vm$partner$contact2.email
                        )
                    ),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      " Hiermit widerrufe(n) ich/wir den von mir/uns abgeschlossenen Vertrag über die Erbringung der folgenden Dienstleistung: ____________________________________"
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(" – Bestellt am: ______________"),
                    _c("br"),
                    _vm._v(" – Name des/der Verbraucher(s): ______________"),
                    _c("br"),
                    _vm._v(
                      " – Anschrift des/der Verbraucher(s): ______________"
                    ),
                    _c("br"),
                    _vm._v(
                      " – Unterschrift des/der Verbraucher(s): ______________"
                    ),
                    _c("br"),
                    _vm._v(" – Datum: ______________ ")
                  ])
                ],
                1
              ),
              _c("v-card-text", [
                _vm._v(
                  " 5.3 Wir sind verpflichtet, dich als Verbraucher auf die Plattform zur Online-Streitbeilegung (OS-Plattform) der Europäischen Kommission hinzuweisen. Diese OS-Plattform ist über folgenden Link erreichbar: https://webgate.ec.europa.eu/odr. Wir nehmen an einem Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle allerdings nicht teil. "
                )
              ]),
              _c(
                "v-card-title",
                {
                  attrs: {
                    id: "7"
                  }
                },
                [_vm._v(" 6. Schlussbestimmungen")]
              ),
              _c("v-card-text", [
                _vm._v(
                  " 5.1 Diese Bedingungen stellen die gesamte Vereinbarung zwischen uns und Dir in Bezug auf Deinen Zugang zur App oder deren Nutzung dar und ersetzen alle vorherigen mündlichen oder schriftlichen Vereinbarungen zwischen uns und dir. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 5.2 Sofern es sich bei Dir um einen Kaufmann, eine juristische Person des öffentlichen Rechts oder um ein öffentlich-rechtliches Sondervermögen handelt, ist ausschließlicher Gerichtsstand für alle Streitigkeiten aus diesen Bedingungen und aus Vertragsverhältnissen, die unter Einbeziehung dieser Bedingungen geschlossen wurden, der Sitz von der MMM Intelligence UG (haftungsbeschränkt). "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 5.3 Es findet ausschließlich das Recht der Bundesrepublik Deutschland Anwendung, unter Ausschluss internationalen Einheitsrechts, insbesondere des UN-Kaufrechts. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 5.4 Die Parteien sind nicht berechtigt Ansprüche oder sonstige Rechte aus diesen Bedingungen ohne Zustimmung der jeweils anderen Parteien zu übertragen, zu verpfänden oder in sonstiger Weise zu belasten. "
                )
              ]),
              _c("v-card-text", [
                _vm._v(
                  " 5.5 Sollte eine Bestimmung dieses Vertrages ganz oder teilweise nichtig, unwirksam oder undurchsetzbar sein oder werden, wird die Wirksamkeit und Durchsetzbarkeit aller übrigen verbleibenden Bestimmungen davon nicht berührt. Anstelle einer nichtigen, unwirksamen oder undurchsetzbaren Bestimmung treten, soweit vorhanden, die gesetzlichen Vorschriften. Soweit dies für eine Vertragspartei eine unzumutbare Härte darstellen würde, wird der Vertrag jedoch im Ganzen unwirksam. "
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }