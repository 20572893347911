var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      staticClass: "pa-2"
    },
    [
      _c(
        "v-col",
        {
          attrs: {
            cols: "12"
          }
        },
        [
          _c(
            "v-form",
            {
              model: {
                value: _vm.isValid,
                callback: function callback($$v) {
                  _vm.isValid = $$v
                },
                expression: "isValid"
              }
            },
            [
              _c("file-input", {
                staticClass: "pt-4 pb-4",
                attrs: {
                  maxFileSizeInMb: _vm.MAX_FILE_SIZE_IN_MB,
                  value: _vm.file,
                  showSize: "true",
                  accept: "application/pdf, .pdf",
                  label: _vm.$t("sign.SignDocumentForm.file") + " *",
                  placeholder: _vm.$t("sign.SignDocumentForm.placeholder"),
                  persistentHint: true,
                  rules: _vm.maxSizeRule,
                  readonly: _vm.disabled,
                  outlined: true,
                  autofocus: ""
                },
                on: {
                  "update:value": function updateValue($event) {
                    _vm.file = $event
                  }
                }
              }),
              _c("v-text-field", {
                attrs: {
                  label: _vm.$t("sign.SignDocumentForm.title") + " *",
                  hint: _vm.$t("sign.SignDocumentForm.titleHint"),
                  "persistent-hint": "",
                  outlined: "",
                  "prepend-icon": "mdi-format-title",
                  rules: _vm.requiredRule
                },
                model: {
                  value: _vm.title,
                  callback: function callback($$v) {
                    _vm.title = $$v
                  },
                  expression: "title"
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }