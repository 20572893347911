var render = function() {
  var _vm$selectedService

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _vm.showImage
        ? _c("v-img", {
            staticClass: "mt-8",
            attrs: {
              contain: "",
              src: _vm.image,
              height: 150,
              justify: "center"
            }
          })
        : _vm._e(),
      _c(
        "v-row",
        {
          attrs: {
            align: "center",
            justify: "center"
          }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "justify-center align-center text-center",
              attrs: {
                cols: "12"
              }
            },
            [
              _c("v-card-text", {
                staticClass: "mt-4 text-h6 font-weight-bold",
                domProps: {
                  innerHTML: _vm._s(_vm.$t(_vm.heading))
                }
              }),
              _c(
                "v-card-text",
                {
                  staticClass: "mt-n6 mb-2 headline font-weight-bold"
                },
                [
                  _vm._v(" " + _vm._s(_vm.selectedSlotDate) + " "),
                  _c("br"),
                  _vm._v(" " + _vm._s(_vm.selectedSlotStartTime) + " ")
                ]
              ),
              _c("v-card-text", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t(_vm.text, {
                      serviceName:
                        (_vm$selectedService = _vm.selectedService) === null ||
                        _vm$selectedService === void 0
                          ? void 0
                          : _vm$selectedService.name,
                      email: _vm.email
                    })
                  )
                }
              }),
              _vm.showDownloadBtn
                ? _c(
                    "v-card-text",
                    {
                      staticClass: "mt-n6 mb-8"
                    },
                    [
                      _c(
                        "div",
                        {
                          class: !_vm.isMobile ? "action-container" : ""
                        },
                        [
                          _c("login-register-button", {
                            attrs: {
                              large: false,
                              small: _vm.isMobile
                            }
                          }),
                          _c(
                            "v-btn",
                            {
                              staticClass: "mt-4",
                              attrs: {
                                small: _vm.isMobile,
                                elevation: "0",
                                block: "",
                                text: !_vm.isMobile,
                                outlined: _vm.isMobile
                              },
                              on: {
                                click: _vm.createIcs
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm.$t(
                                      "bookingForm.BookingSuccess.download"
                                    )
                                  ) +
                                  " "
                              )
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }