var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    {
      on: {
        click: function click($event) {
          return _vm.goTo()
        }
      }
    },
    [
      _c(
        "v-list-item-avatar",
        [_c("v-icon", [_vm._v(" " + _vm._s(_vm.icon) + " ")])],
        1
      ),
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v(_vm._s(_vm.navigationItem.title))]),
          _c("v-list-item-subtitle", [
            _vm._v(_vm._s(_vm.navigationItem.description))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }