var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-banner",
    {
      attrs: {
        tile: "",
        color: _vm.color,
        "single-line": ""
      }
    },
    [
      _c(
        "v-row",
        {
          staticClass: "justify-center align-center"
        },
        [
          _vm._l(_vm.items, function(item, index) {
            return [
              _c(
                "v-col",
                {
                  key: "item-" + index,
                  attrs: {
                    cols: "auto"
                  }
                },
                [
                  _c(
                    "p",
                    {
                      class: [
                        "text-body",
                        "ma-0",
                        "d-inline-block",
                        "white--text"
                      ]
                    },
                    [_vm._v(" " + _vm._s(item.title) + " ")]
                  )
                ]
              ),
              index < _vm.items.length - 1
                ? _c(
                    "v-col",
                    {
                      key: "divider-" + index,
                      staticClass: "white--text",
                      attrs: {
                        cols: "auto"
                      }
                    },
                    [_vm._v(" • ")]
                  )
                : _vm._e()
            ]
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }