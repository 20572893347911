var render = function() {
  var _vm$selectedService, _vm$selectedService2, _vm$selectedService3

  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-row",
        {
          attrs: {
            align: "stretch"
          }
        },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex justify-center align-center",
              attrs: {
                cols: "12"
              }
            },
            [
              _c(
                "v-avatar",
                {
                  staticClass: "mt-4",
                  attrs: {
                    color: _vm.color,
                    size: "80"
                  }
                },
                [
                  (_vm$selectedService = _vm.selectedService) !== null &&
                  _vm$selectedService !== void 0 &&
                  _vm$selectedService.imageUrl
                    ? _c("v-img", {
                        attrs: {
                          src: _vm.selectedService.imageUrl,
                          contain: ""
                        }
                      })
                    : (_vm$selectedService2 = _vm.selectedService) !== null &&
                      _vm$selectedService2 !== void 0 &&
                      _vm$selectedService2.icon
                    ? _c(
                        "v-icon",
                        {
                          attrs: {
                            dark: "",
                            "x-large": ""
                          }
                        },
                        [
                          _vm._v(
                            " mdi-" + _vm._s(_vm.selectedService.icon) + " "
                          )
                        ]
                      )
                    : _c(
                        "v-icon",
                        {
                          attrs: {
                            dark: "",
                            "x-large": ""
                          }
                        },
                        [_vm._v(" mdi-account-wrench-outline ")]
                      )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      (_vm$selectedService3 = _vm.selectedService) !== null &&
      _vm$selectedService3 !== void 0 &&
      _vm$selectedService3.name
        ? _c(
            "v-row",
            [
              _c("v-col", [
                _c(
                  "h1",
                  {
                    class: [
                      "headline text-h4 font-weight-bold text-center",
                      _vm.dark ? "white--text" : ""
                    ]
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("bookingForm.BookingCalendar.selectDate")
                        ) +
                        " "
                    )
                  ]
                )
              ])
            ],
            1
          )
        : _vm._e(),
      _vm.displayDatePicker
        ? _c(
            "v-row",
            {
              staticClass: "px-4  d-flex flex-column ",
              staticStyle: {
                "min-height": "290px"
              },
              attrs: {
                align: "center",
                justify: "center"
              }
            },
            [
              _c("v-date-picker", {
                ref: "bookingPicker",
                attrs: {
                  "picker-date": _vm.pickerDate,
                  "allowed-dates": _vm.allowedDates,
                  min: _vm.today,
                  "full-width": "",
                  flat: "",
                  locale: _vm.$t("locale"),
                  color: _vm.color,
                  "first-day-of-week": "1",
                  "no-title": "",
                  dark: _vm.dark,
                  disabled: _vm.isLoadingSlots
                },
                on: {
                  "update:pickerDate": function updatePickerDate($event) {
                    _vm.pickerDate = $event
                  },
                  "update:picker-date": function updatePickerDate($event) {
                    _vm.pickerDate = $event
                  },
                  change: _vm.gotoSlots
                },
                model: {
                  value: _vm.date,
                  callback: function callback($$v) {
                    _vm.date = $$v
                  },
                  expression: "date"
                }
              })
            ],
            1
          )
        : _vm._e(),
      _vm.isLoadingSlots && _vm.isMobile
        ? _c("v-skeleton-loader", {
            staticClass: "mt-4 mb-4",
            attrs: {
              type: "date-picker-options, date-picker-days"
            }
          })
        : _vm._e(),
      _c(
        "v-row",
        {
          staticStyle: {
            "min-height": "60px"
          }
        },
        [
          _c("v-col", [
            _c(
              "p",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: !_vm.isLoadingSlots && !_vm.slots.length,
                    expression: "!isLoadingSlots && !slots.length"
                  }
                ],
                class: [
                  "text-center text-caption pt-0 px-4",
                  _vm.dark ? "white--text" : ""
                ]
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t("bookingForm.BookingCalendar.noAvailableSlots", {
                        monthName: new Date(_vm.date).toLocaleDateString(
                          "de-de",
                          {
                            month: "long"
                          }
                        )
                      })
                    ) +
                    " "
                )
              ]
            )
          ])
        ],
        1
      ),
      _vm.showNavigation
        ? _c("booking-navigation", {
            attrs: {
              cardClass: "pa-4 mx-auto no-top-round-corners",
              isMobile: _vm.isMobile
            },
            on: {
              back: _vm.back,
              forward: _vm.forward
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }