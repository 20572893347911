var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticStyle: {
        "background-color": "red",
        width: "100%"
      }
    },
    [
      _c(
        "v-speed-dial",
        {
          staticStyle: {
            "z-index": "5"
          },
          attrs: {
            bottom: "",
            right: "",
            direction: "top",
            transition: "slide-y-reverse"
          },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function fn() {
                return [
                  _c(
                    "v-btn",
                    {
                      style: "position: absolute; right: -13px; bottom: -16px",
                      attrs: {
                        color: "info",
                        fab: "",
                        elevation: 0
                      },
                      model: {
                        value: _vm.started,
                        callback: function callback($$v) {
                          _vm.started = $$v
                        },
                        expression: "started"
                      }
                    },
                    [
                      _vm.started
                        ? _c("v-icon", [_vm._v(" mdi-close ")])
                        : _c("v-icon", [_vm._v(" mdi-plus ")])
                    ],
                    1
                  )
                ]
              },
              proxy: true
            }
          ]),
          model: {
            value: _vm.started,
            callback: function callback($$v) {
              _vm.started = $$v
            },
            expression: "started"
          }
        },
        [
          _vm.editStep === _vm.EditSteps.INITIAL
            ? _c(
                "div",
                _vm._l(_vm.options, function(option, index) {
                  return _c(
                    "div",
                    {
                      key: option.icon
                    },
                    [
                      _vm.editStep === _vm.EditSteps.INITIAL
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                "z-index": "5"
                              }
                            },
                            _vm._l(_vm.options, function(option, index) {
                              return _c(
                                "v-slide-x-reverse-transition",
                                {
                                  key: option.icon
                                },
                                [
                                  _c(
                                    "v-chip",
                                    {
                                      style: "\n                  background-color:white !important;\n                  position: absolute;\n                  right: 40px;\n                  bottom: ".concat(
                                        11 + 46 * (index + 1),
                                        "px;\n                "
                                      ),
                                      attrs: {
                                        rounded: "",
                                        color: "info",
                                        outlined: ""
                                      }
                                    },
                                    [
                                      _c("span", [
                                        _vm._v(" " + _vm._s(option.short) + " ")
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            }),
                            1
                          )
                        : _vm._e(),
                      _c(
                        "v-btn",
                        {
                          style: "position: absolute; right: -12px; bottom: ".concat(
                            46 * (index + 1),
                            "px;"
                          ),
                          attrs: {
                            small: "",
                            fab: "",
                            elevation: 0,
                            color: "info"
                          },
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return option.action.apply(null, arguments)
                            }
                          }
                        },
                        [
                          _c("v-icon", [
                            _vm._v(" " + _vm._s(option.icon) + " ")
                          ])
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                0
              )
            : _c(
                "div",
                [
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "46px",
                        right: "-12px"
                      },
                      attrs: {
                        small: "",
                        fab: "",
                        elevation: 0,
                        color: "warning"
                      },
                      on: {
                        click: _vm.discard
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-undo-variant")])],
                    1
                  ),
                  _c(
                    "v-btn",
                    {
                      staticStyle: {
                        position: "absolute",
                        bottom: "92px",
                        right: "-12px"
                      },
                      attrs: {
                        small: "",
                        fab: "",
                        elevation: 0,
                        color: "success"
                      },
                      on: {
                        click: _vm.addField
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }