var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "the-layout-base",
    {
      attrs: {
        progress: _vm.progress,
        showFooter: _vm.showFooter,
        isEndUser: true,
        showLoginButton: _vm.showLoginButton
      },
      on: {
        logoClick: _vm.logoClick
      }
    },
    [
      _c(
        "v-container",
        {
          staticClass: "pb-14"
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto card-outter pb-5 rounded-0",
              staticStyle: {
                "min-height": "600px"
              },
              attrs: {
                flat: "",
                "max-width": "1200",
                color: "transparent"
              }
            },
            [
              _vm._t("default"),
              _vm.renderBackAndForthFooter
                ? _c("BackAndForthFooter", {
                    attrs: {
                      hasNext: _vm.hasNext,
                      hasPrev: _vm.hasPrev,
                      forceNextBtn: _vm.forceNextBtn,
                      disableNext: _vm.disableNext
                    },
                    on: {
                      next: _vm.next,
                      prev: _vm.previous
                    }
                  })
                : _vm._e()
            ],
            2
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }