var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    [
      _c(
        "v-list-item-content",
        [
          _c("v-list-item-title", [_vm._v(" " + _vm._s(_vm.item.title) + " ")]),
          _c("v-list-item-subtitle", [
            _vm._v(
              " " + _vm._s(_vm.dottedSubString(_vm.item.description)) + " "
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }