import { render, staticRenderFns } from "./BookingStartHowToBook.vue?vue&type=template&id=62c825e0&scoped=true&"
import script from "./BookingStartHowToBook.vue?vue&type=script&lang=ts&"
export * from "./BookingStartHowToBook.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "62c825e0",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBanner } from 'vuetify/lib/components/VBanner';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBanner,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('62c825e0')) {
      api.createRecord('62c825e0', component.options)
    } else {
      api.reload('62c825e0', component.options)
    }
    module.hot.accept("./BookingStartHowToBook.vue?vue&type=template&id=62c825e0&scoped=true&", function () {
      api.rerender('62c825e0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/customer/BookingStartHowToBook.vue"
export default component.exports