var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        on: {
          dragover: function dragover($event) {
            $event.preventDefault()
            return _vm.startDrag.apply(null, arguments)
          }
        }
      },
      [
        _c(
          "div",
          {
            staticStyle: {
              display: "flex"
            }
          },
          [
            _c("v-spacer"),
            _c(
              "v-btn",
              {
                staticClass: "mt-1",
                attrs: {
                  "x-small": "",
                  text: "",
                  elevation: 0,
                  loading: _vm.loading || _vm.isLoadingCreation
                },
                on: {
                  click: function click($event) {
                    _vm.isDocumentDialogActive = true
                  }
                }
              },
              [
                _vm._v(
                  " " +
                    _vm._s(
                      _vm.$t(
                        "components.fleet.FleetVehicleDetailDocumentCard.addDocumentDialogTitle"
                      )
                    ) +
                    " "
                )
              ]
            )
          ],
          1
        ),
        _c(
          "div",
          {
            attrs: {
              id: "docsContainer"
            }
          },
          [
            _vm.isDrag
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "z-index": "1",
                      position: "absolute",
                      "background-color": "rgba(200,200,200,0.5)"
                    },
                    style: "height: "
                      .concat(_vm.dropFieldDimensions.h, "px; width: ")
                      .concat(_vm.dropFieldDimensions.w, "px; ")
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          height: "100%",
                          display: "flex",
                          "align-items": "center",
                          "justify-content": "center"
                        },
                        on: {
                          dragleave: function dragleave($event) {
                            $event.preventDefault()
                            return _vm.endDrag.apply(null, arguments)
                          },
                          drop: function drop($event) {
                            $event.preventDefault()
                            return _vm.onFileDrop($event)
                          }
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticStyle: {
                              transform: "scale(300%)"
                            },
                            attrs: {
                              "x-large": ""
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e(),
            _c(
              "draggable",
              {
                attrs: {
                  list: _vm.documents,
                  handle: ".handle"
                },
                on: {
                  change: _vm.handleOrderChange
                }
              },
              _vm._l(_vm.documents, function(document, index) {
                return _c("v-hover", {
                  key: "document-card-" + index,
                  staticClass: "my-2",
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "default",
                        fn: function fn(_ref) {
                          var hover = _ref.hover
                          return [
                            _c(
                              "v-card",
                              {
                                attrs: {
                                  outlined: "",
                                  elevation: 0
                                }
                              },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-action",
                                      {
                                        staticClass: "pa-0 ma-0 ml-n2 mr-1"
                                      },
                                      [
                                        _vm.documents.length > 1 &&
                                        (hover || _vm.isMobile)
                                          ? _c(
                                              "v-icon",
                                              {
                                                staticClass: "handle"
                                              },
                                              [_vm._v(" mdi-drag-vertical ")]
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-content",
                                      {
                                        staticClass: "pa-0"
                                      },
                                      [
                                        document.isLoading
                                          ? _c("v-skeleton-loader", {
                                              attrs: {
                                                type: "list-item-three-line"
                                              }
                                            })
                                          : document.document
                                          ? _c("refs-document", {
                                              attrs: {
                                                item: document.document
                                              },
                                              on: {
                                                openDetail:
                                                  _vm.goToDocumentDetail
                                              }
                                            })
                                          : _c("latest-entries-card-empty")
                                      ],
                                      1
                                    ),
                                    _c(
                                      "v-list-item-action",
                                      {
                                        staticClass: "mx-1 px-0"
                                      },
                                      [
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              text: _vm.$t("remove")
                                            }
                                          },
                                          [
                                            _c(
                                              "v-flex",
                                              [
                                                hover
                                                  ? _c(
                                                      "v-btn",
                                                      {
                                                        attrs: {
                                                          text: "",
                                                          small: "",
                                                          color: "grey",
                                                          type: ""
                                                        },
                                                        on: {
                                                          click: function click(
                                                            $event
                                                          ) {
                                                            return _vm.startRemove(
                                                              index
                                                            )
                                                          }
                                                        }
                                                      },
                                                      [
                                                        _c(
                                                          "v-icon",
                                                          {
                                                            attrs: {
                                                              small: ""
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              " mdi-close "
                                                            )
                                                          ]
                                                        )
                                                      ],
                                                      1
                                                    )
                                                  : _vm._e()
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        }
                      }
                    ],
                    null,
                    true
                  )
                })
              }),
              1
            ),
            !_vm.documents.length
              ? _c(
                  "v-card",
                  {
                    staticClass: "mb-3 mt-n1 pa-1",
                    attrs: {
                      outlined: ""
                    },
                    on: {
                      click: function click($event) {
                        _vm.isDocumentDialogActive = true
                      }
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        class: "".concat(_vm.isDrag ? "drag" : ""),
                        staticStyle: {
                          width: "100%",
                          height: "100px",
                          display: "flex",
                          "flex-direction": "column",
                          justify: "center"
                        }
                      },
                      [
                        _c(
                          "v-icon",
                          {
                            staticClass: "mx-auto mt-4 mb-3",
                            staticStyle: {
                              transform: "scale(150%)"
                            },
                            attrs: {
                              "x-large": ""
                            }
                          },
                          [_vm._v(" " + _vm._s(_vm.prependIcon) + " ")]
                        ),
                        _c(
                          "div",
                          {
                            staticClass: "mx-auto"
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("cost.dragAndDropDocuments")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                )
              : _vm._e()
          ],
          1
        ),
        _vm.isDocumentDialogActive
          ? _c("document-selection-dialog", {
              attrs: {
                folder: "/cost/".concat(_vm.costId),
                isDialogActive: _vm.isDocumentDialogActive,
                reference: _vm.documentReference,
                partnerId: _vm.partnerId,
                createActivityOnSelect: false
              },
              on: {
                "update:isDialogActive": function updateIsDialogActive($event) {
                  _vm.isDocumentDialogActive = $event
                },
                "update:is-dialog-active": function updateIsDialogActive(
                  $event
                ) {
                  _vm.isDocumentDialogActive = $event
                },
                created: _vm.onCreated
              }
            })
          : _vm._e(),
        _c("confirm-action-dialog", {
          attrs: {
            isDialogActive: _vm.isRemoveDocumentDialogActive,
            subtitle: "Dokument aus Kostenobjekt entfernen"
          },
          on: {
            "update:isDialogActive": function updateIsDialogActive($event) {
              _vm.isRemoveDocumentDialogActive = $event
            },
            "update:is-dialog-active": function updateIsDialogActive($event) {
              _vm.isRemoveDocumentDialogActive = $event
            },
            rightClick: _vm.removeDocument,
            leftClick: function leftClick() {
              return (_vm.isRemoveDocumentDialogActive = false)
            },
            close: function close() {
              return (_vm.isRemoveDocumentDialogActive = false)
            }
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }