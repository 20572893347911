var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-bottom-navigation",
    {
      staticStyle: {
        position: "fixed",
        bottom: "0"
      },
      attrs: {
        value: true
      }
    },
    [
      _c(
        "v-row",
        [
          _c("v-spacer"),
          _vm.$vuetify.breakpoint.smAndUp
            ? _c(
                "v-card-title",
                {
                  staticClass: "mr-4"
                },
                [
                  _c("small", [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "components.profile.EditProfileDialog.saveQuestion"
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              )
            : _vm._e(),
          _c(
            "v-btn",
            {
              staticClass:
                "navigationbottombutton navigationbottomReject mt-5 mr-2",
              attrs: {
                loading: _vm.isLoading,
                outlined: "",
                large: "",
                color: "warning",
                "data-test-profile-abort": ""
              },
              on: {
                click: _vm.abort
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.profile.EditProfileDialog.abort")) +
                  " "
              )
            ]
          ),
          _c(
            "v-btn",
            {
              staticClass:
                "navigationbottombutton navigationbottomConfirm mt-5 mr-10",
              attrs: {
                disabled: !_vm.valid,
                loading: _vm.isLoading,
                color: "primary",
                "data-test-profile-submit": "",
                "x-large": ""
              },
              on: {
                click: _vm.save
              }
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("components.profile.EditProfileDialog.save")) +
                  " "
              )
            ]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }