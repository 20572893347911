import { render, staticRenderFns } from "./BookingForm.vue?vue&type=template&id=2c5be6f2&scoped=true&"
import script from "./BookingForm.vue?vue&type=script&lang=ts&"
export * from "./BookingForm.vue?vue&type=script&lang=ts&"
import style0 from "./BookingForm.vue?vue&type=style&index=0&id=2c5be6f2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c5be6f2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';
installComponents(component, {VBtn,VCard,VCardTitle,VCheckbox,VCol,VForm,VList,VListItem,VListItemContent,VListItemSubtitle,VListItemTitle,VRow,VTextField,VTextarea})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('2c5be6f2')) {
      api.createRecord('2c5be6f2', component.options)
    } else {
      api.reload('2c5be6f2', component.options)
    }
    module.hot.accept("./BookingForm.vue?vue&type=template&id=2c5be6f2&scoped=true&", function () {
      api.rerender('2c5be6f2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/customer/BookingForm.vue"
export default component.exports