var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        height: "100%",
        width: "640px",
        "max-height": "125",
        align: "center",
        color: _vm.$vuetify.theme.dark ? "grey darken-1" : "grey lighten-4",
        elevation: "0"
      }
    },
    [
      _c("v-img", {
        attrs: {
          src: _vm.imageFromAssetRepository,
          height: "125",
          contain: ""
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }