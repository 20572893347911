var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-row",
    {
      attrs: {
        align: "stretch"
      }
    },
    [
      _c(
        "v-col",
        {
          staticClass: "d-flex justify-center align-center",
          attrs: {
            cols: "12"
          }
        },
        [
          _c(
            "v-card",
            {
              staticClass: "text-center ",
              staticStyle: {
                height: "100%",
                "overflow-y": "auto",
                "overflow-x": "hidden"
              },
              attrs: {
                flat: "",
                "max-width": "600"
              }
            },
            [
              _c(
                "v-card-text",
                [
                  _c("v-img", {
                    staticClass: "mt-8",
                    attrs: {
                      align: "center",
                      justify: "center",
                      contain: "",
                      "max-height": "250px",
                      src: require("@/assets/partner_contact_success.svg")
                    }
                  })
                ],
                1
              ),
              _c(
                "v-card-title",
                {
                  staticClass: "justify-center"
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(
                        _vm.$t(
                          "sign.ReportDocumentSignRequestStepperMailSuccess.title"
                        )
                      ) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }