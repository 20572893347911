import { render, staticRenderFns } from "./BookingSlots.vue?vue&type=template&id=1fe1ef77&scoped=true&"
import script from "./BookingSlots.vue?vue&type=script&lang=ts&"
export * from "./BookingSlots.vue?vue&type=script&lang=ts&"
import style0 from "./BookingSlots.vue?vue&type=style&index=0&id=1fe1ef77&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1fe1ef77",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VVirtualScroll } from 'vuetify/lib/components/VVirtualScroll';
installComponents(component, {VBtn,VCardText,VList,VListItem,VRow,VSkeletonLoader,VVirtualScroll})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1fe1ef77')) {
      api.createRecord('1fe1ef77', component.options)
    } else {
      api.reload('1fe1ef77', component.options)
    }
    module.hot.accept("./BookingSlots.vue?vue&type=template&id=1fe1ef77&scoped=true&", function () {
      api.rerender('1fe1ef77', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/customer/BookingSlots.vue"
export default component.exports