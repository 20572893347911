import { render, staticRenderFns } from "./ProfileConfirmationRow.vue?vue&type=template&id=7d9b065c&scoped=true&"
import script from "./ProfileConfirmationRow.vue?vue&type=script&lang=ts&"
export * from "./ProfileConfirmationRow.vue?vue&type=script&lang=ts&"
import style0 from "./ProfileConfirmationRow.vue?vue&type=style&index=0&id=7d9b065c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7d9b065c",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBottomNavigation } from 'vuetify/lib/components/VBottomNavigation';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBottomNavigation,VBtn,VCardTitle,VRow,VSpacer})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7d9b065c')) {
      api.createRecord('7d9b065c', component.options)
    } else {
      api.reload('7d9b065c', component.options)
    }
    module.hot.accept("./ProfileConfirmationRow.vue?vue&type=template&id=7d9b065c&scoped=true&", function () {
      api.rerender('7d9b065c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/profile/ProfileConfirmationRow.vue"
export default component.exports