var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      attrs: {
        flat: ""
      }
    },
    [
      _c(
        "v-card-text",
        [
          _c("v-text-field", {
            ref: "search",
            attrs: {
              dense: "",
              outlined: "",
              "prepend-inner-icon": "mdi-magnify",
              label: _vm.$t("components.QuickLinkList.search"),
              autofocus: ""
            },
            model: {
              value: _vm.searchPattern,
              callback: function callback($$v) {
                _vm.searchPattern = $$v
              },
              expression: "searchPattern"
            }
          })
        ],
        1
      ),
      _vm.searchPattern
        ? _c(
            "v-list",
            {
              staticClass: "mt-n8"
            },
            [
              _c(
                "v-list-item-group",
                [
                  _vm.limitDisplay
                    ? _c(
                        "v-list-item",
                        {
                          attrs: {
                            to: "/partners/"
                              .concat(_vm.partnerId, "/quick-links?search=")
                              .concat(_vm.searchPattern)
                          }
                        },
                        [
                          _c(
                            "v-list-item-avatar",
                            [_c("v-icon", [_vm._v(" mdi-magnify ")])],
                            1
                          ),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", [
                                _vm._v(_vm._s(_vm.searchPattern))
                              ]),
                              _c("v-list-item-subtitle", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "components.QuickLinkList.searchHint"
                                    )
                                  )
                                )
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.filteredList.length
                    ? _c("v-subheader", [
                        _vm._v(
                          _vm._s(_vm.$t("components.QuickLinkList.bestHits"))
                        )
                      ])
                    : _vm._e(),
                  _vm._l(_vm.filteredList, function(searchResult, idx) {
                    return _c(
                      "span",
                      {
                        key: idx
                      },
                      [
                        _c("navigation-list-item", {
                          attrs: {
                            navigationItem: searchResult.item
                          }
                        })
                      ],
                      1
                    )
                  })
                ],
                2
              )
            ],
            1
          )
        : _vm.limitDisplay
        ? _c(
            "v-list",
            {
              staticClass: "mt-n8"
            },
            [
              _c("v-subheader", [
                _vm._v(_vm._s(_vm.$t("components.QuickLinkList.favs")))
              ]),
              _c(
                "v-list-item-group",
                _vm._l(_vm.favLinks, function(quicklink, idx) {
                  return _c(
                    "span",
                    {
                      key: idx
                    },
                    [
                      _c("navigation-list-item", {
                        attrs: {
                          navigationItem: quicklink
                        }
                      })
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _c(
            "div",
            _vm._l(
              _vm.forUserContextAvailableQuickLinksWithSubheaders,
              function(quicklinkC, idx1) {
                return _c(
                  "div",
                  {
                    key: idx1
                  },
                  [
                    _c("v-subheader", [
                      _vm._v(
                        _vm._s(_vm.$t("navCategory." + quicklinkC.category))
                      )
                    ]),
                    _c(
                      "v-list",
                      [
                        _c(
                          "v-list-item-group",
                          _vm._l(quicklinkC.items, function(quicklink, idx) {
                            return _c(
                              "span",
                              {
                                key: idx
                              },
                              [
                                _c("navigation-list-item", {
                                  attrs: {
                                    navigationItem: quicklink
                                  }
                                })
                              ],
                              1
                            )
                          }),
                          0
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              }
            ),
            0
          ),
      _vm.limitDisplay ? _c("v-divider") : _vm._e(),
      _vm.limitDisplay
        ? _c(
            "v-card-text",
            [
              _c(
                "v-list-item",
                {
                  on: {
                    click: function click($event) {
                      return _vm.goToQuickLinks()
                    }
                  }
                },
                [
                  _c(
                    "v-list-item-content",
                    [
                      _vm.limitDisplay
                        ? _c("v-list-item-subtitle", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("components.QuickLinkList.allFunctions")
                              )
                            )
                          ])
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }