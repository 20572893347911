import { render, staticRenderFns } from "./BookingNavigation.vue?vue&type=template&id=4ff6b3c2&scoped=true&"
import script from "./BookingNavigation.vue?vue&type=script&lang=ts&"
export * from "./BookingNavigation.vue?vue&type=script&lang=ts&"
import style0 from "./BookingNavigation.vue?vue&type=style&index=0&id=4ff6b3c2&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ff6b3c2",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCol,VRow})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ff6b3c2')) {
      api.createRecord('4ff6b3c2', component.options)
    } else {
      api.reload('4ff6b3c2', component.options)
    }
    module.hot.accept("./BookingNavigation.vue?vue&type=template&id=4ff6b3c2&scoped=true&", function () {
      api.rerender('4ff6b3c2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/booking/customer/BookingNavigation.vue"
export default component.exports