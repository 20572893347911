var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "span",
    {
      style: _vm.text ? "width: 100%" : ""
    },
    [
      _c(
        "v-btn",
        {
          attrs: {
            icon: !_vm.text,
            block: Boolean(_vm.text),
            text: Boolean(_vm.text),
            disabled: _vm.disabled
          },
          on: {
            click: _vm.startDelete
          }
        },
        [
          _vm._v(" " + _vm._s(_vm.text) + " "),
          _vm.text ? _c("v-spacer") : _vm._e(),
          _c(
            "v-icon",
            {
              attrs: {
                color: _vm.isDialogActive ? "info" : ""
              }
            },
            [_vm._v("mdi-delete-outline")]
          )
        ],
        1
      ),
      _c("confirm-action-dialog", {
        attrs: {
          rightLoading: _vm.loading,
          leftDisabled: _vm.loading,
          rightDisabled: _vm.loading,
          isDialogActive: _vm.isDialogActive,
          title: _vm.$t("sign.DocumentTemplateDetailDelete.sure"),
          rightText: _vm.$t("sign.DocumentTemplateDetailDelete.delete")
        },
        on: {
          "update:isDialogActive": function updateIsDialogActive($event) {
            _vm.isDialogActive = $event
          },
          "update:is-dialog-active": function updateIsDialogActive($event) {
            _vm.isDialogActive = $event
          },
          leftClick: function leftClick($event) {
            _vm.isDialogActive = false
          },
          rightClick: function rightClick($event) {
            return _vm.$emit("delete")
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }