var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-app",
    [
      _c(
        "v-main",
        {
          staticClass: "mt-1"
        },
        [
          _c(
            "v-card",
            {
              staticClass: "mx-auto card-outter rounded-0",
              staticStyle: {
                "min-height": "550px"
              },
              attrs: {
                flat: "",
                "max-width": "850"
              }
            },
            [
              _c("booking-process", {
                attrs: {
                  bookingIframe: true,
                  bgColor: _vm.bgColor,
                  dark: true
                }
              })
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }