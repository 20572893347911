var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.showLoginButton
    ? _c(
        "div",
        {
          staticClass: "d-flex justify-space-between"
        },
        [
          _c(
            "v-btn",
            {
              class: [_vm.htmlClass, _vm.showRegisterButton ? "mr-2" : ""],
              attrs: {
                color: _vm.color,
                elevation: _vm.elevation,
                dark: _vm.dark,
                outlined: _vm.outlined,
                large: _vm.large,
                small: _vm.small,
                rounded: _vm.rounded,
                block: _vm.block
              },
              on: {
                click: _vm.goToLogin
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t(_vm.loginButtonText)) + " ")]
          ),
          _vm.showRegisterButton
            ? _c(
                "v-btn",
                {
                  class: _vm.htmlClass,
                  attrs: {
                    color: _vm.color,
                    elevation: _vm.elevation,
                    dark: _vm.dark,
                    large: _vm.large,
                    small: _vm.small,
                    rounded: _vm.rounded,
                    block: _vm.block
                  },
                  on: {
                    click: _vm.goToRegister
                  }
                },
                [
                  _vm._v(
                    " " + _vm._s(_vm.$t(_vm.createAccountButtonText)) + " "
                  )
                ]
              )
            : _vm._e()
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }