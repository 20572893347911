var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-card",
    {
      directives: [
        {
          name: "touch",
          rawName: "v-touch",
          value: _vm.handleGesture,
          expression: "handleGesture"
        }
      ],
      staticClass: "pdfContainer noOverflow",
      style: _vm.backgroundColor
    },
    [
      false
        ? _c(
            "div",
            {
              staticClass: "pageCount"
            },
            [
              _vm._v(
                _vm._s(_vm.currentPageNumber) + "/" + _vm._s(_vm.pageCount)
              )
            ]
          )
        : _vm._e(),
      _c(
        "v-snackbar",
        {
          attrs: {
            top: ""
          },
          scopedSlots: _vm._u([
            {
              key: "action",
              fn: function fn(_ref) {
                var attrs = _ref.attrs
                return [
                  _c(
                    "v-btn",
                    _vm._b(
                      {
                        attrs: {
                          text: ""
                        },
                        on: {
                          click: function click($event) {
                            _vm.isSnackbar = false
                          }
                        }
                      },
                      "v-btn",
                      attrs,
                      false
                    ),
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.files.PdfViewer.snackBarButton")
                          ) +
                          " "
                      )
                    ]
                  )
                ]
              }
            }
          ]),
          model: {
            value: _vm.isSnackbar,
            callback: function callback($$v) {
              _vm.isSnackbar = $$v
            },
            expression: "isSnackbar"
          }
        },
        [
          _vm._v(
            " " +
              _vm._s(_vm.$t("components.files.PdfViewer.snackBarTitle")) +
              " "
          )
        ]
      ),
      _vm.currentLoading
        ? _c("v-progress-circular", {
            staticClass: "loadingIcon",
            attrs: {
              indeterminate: "",
              color: "grey",
              "data-test-pdf-loading": ""
            }
          })
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "pdfFlexColumnContainer"
        },
        [
          _c(
            "div",
            {
              staticClass: "pdfFlexColumnUpper"
            },
            [
              _c(
                "div",
                {
                  staticClass: "pdfFlexRowContainer"
                },
                [
                  _vm.pageCount !== 1 && _vm.mdAndUp
                    ? _c(
                        "div",
                        {
                          staticClass: "pdfFlexRowSmallerUp"
                        },
                        [
                          _vm.previousPage && !_vm.previousLoading
                            ? _c("v-img", {
                                staticClass: "image",
                                attrs: {
                                  src: _vm.previousPage,
                                  alt: "Previous Page",
                                  contain: ""
                                },
                                on: {
                                  click: _vm.openPreviousPage
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "div",
                    {
                      class:
                        _vm.pageCount !== 1
                          ? _vm.mdAndUp
                            ? "pdfFlexRowLargerUp"
                            : "pdfFlexRowLargerDown"
                          : "pdfFlexRowOnly"
                    },
                    [
                      _vm.currentPage && !_vm.currentLoading
                        ? _c("v-img", {
                            staticClass: "image",
                            attrs: {
                              img: "",
                              src: _vm.currentPage,
                              alt: "Current Page",
                              contain: ""
                            },
                            on: {
                              click: _vm.exportPDF
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  ),
                  _vm.pageCount !== 1 && _vm.mdAndUp
                    ? _c(
                        "div",
                        {
                          staticClass: "pdfFlexRowSmallerUp"
                        },
                        [
                          _vm.nextPage && !_vm.nextLoading
                            ? _c("v-img", {
                                staticClass: "image",
                                attrs: {
                                  src: _vm.nextPage,
                                  alt: "Next Page",
                                  contain: ""
                                },
                                on: {
                                  click: _vm.openNextPage
                                }
                              })
                            : _vm._e()
                        ],
                        1
                      )
                    : _vm._e()
                ]
              )
            ]
          ),
          !_vm.mdAndUp
            ? _c(
                "div",
                {
                  staticClass: "pdfFlexColumnLower"
                },
                [
                  _c(
                    "div",
                    {
                      staticClass: "pdfFlexRowContainer"
                    },
                    [
                      _vm.pageCount !== 1
                        ? _c(
                            "div",
                            {
                              staticClass: "pdfFlexRowSmallerDown"
                            },
                            [
                              _vm.previousPage && !_vm.previousLoading
                                ? _c("v-img", {
                                    staticClass: "image",
                                    attrs: {
                                      src: _vm.previousPage,
                                      alt: "Previous Page",
                                      contain: ""
                                    },
                                    on: {
                                      click: _vm.openPreviousPage
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm.pageCount !== 1
                        ? _c(
                            "div",
                            {
                              staticClass: "pdfFlexRowSmallerDown"
                            },
                            [
                              _vm.nextPage && !_vm.nextLoading
                                ? _c("v-img", {
                                    staticClass: "image",
                                    attrs: {
                                      src: _vm.nextPageNumber,
                                      alt: "Next Page",
                                      contain: ""
                                    },
                                    on: {
                                      click: _vm.openNextPage
                                    }
                                  })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ]
                  )
                ]
              )
            : _vm._e()
        ]
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "buttonContainer"
        },
        [
          _c(
            "v-row",
            {
              staticClass: "mt-6"
            },
            [
              _c("v-spacer"),
              _vm.mdAndUp && _vm.pageCount !== 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "button wide",
                      attrs: {
                        disabled: _vm.pageCount === 1,
                        elevation: "0",
                        "x-large": ""
                      },
                      on: {
                        click: _vm.openPreviousPage
                      }
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-arrow-left")]),
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.files.PdfViewer.lastPage")
                          ) +
                          " "
                      )
                    ],
                    1
                  )
                : _vm.pageCount !== 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled: _vm.pageCount === 1,
                        rounded: "",
                        outlined: "",
                        fab: "",
                        small: ""
                      },
                      on: {
                        click: _vm.openPreviousPage
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer"),
              _vm.mdAndUp
                ? _c(
                    "v-btn",
                    {
                      staticClass: "button wide",
                      staticStyle: {
                        width: "200px"
                      },
                      attrs: {
                        color: "success",
                        elevation: "0",
                        "x-large": "",
                        "data-test-confirm-pdf-upload": ""
                      },
                      on: {
                        click: _vm.exportPDF
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("components.files.PdfViewer.select")) +
                          " "
                      ),
                      _c("v-icon", [_vm._v("mdi-check")])
                    ],
                    1
                  )
                : _c(
                    "v-btn",
                    {
                      staticClass: "button",
                      attrs: {
                        color: "success",
                        rounded: "",
                        outlined: "",
                        fab: "",
                        small: "",
                        "data-test-confirm-pdf-upload": ""
                      },
                      on: {
                        click: _vm.exportPDF
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-check")])],
                    1
                  ),
              _c("v-spacer"),
              _vm.mdAndUp && _vm.pageCount !== 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "button wide",
                      attrs: {
                        disabled: _vm.pageCount === 1,
                        elevation: "0",
                        "x-large": ""
                      },
                      on: {
                        click: _vm.openNextPage
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            _vm.$t("components.files.PdfViewer.nextPage")
                          ) +
                          " "
                      ),
                      _c("v-icon", [_vm._v("mdi-arrow-right")])
                    ],
                    1
                  )
                : _vm.pageCount !== 1
                ? _c(
                    "v-btn",
                    {
                      staticClass: "button",
                      attrs: {
                        disabled: _vm.pageCount === 1,
                        rounded: "",
                        outlined: "",
                        fab: "",
                        small: ""
                      },
                      on: {
                        click: _vm.openNextPage
                      }
                    },
                    [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                    1
                  )
                : _vm._e(),
              _c("v-spacer")
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }