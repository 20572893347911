var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "tooltip",
    {
      attrs: {
        text: _vm.text
      }
    },
    [
      _c(
        "v-icon",
        {
          staticClass: "mr-1 mt-n1"
        },
        [_vm._v(" " + _vm._s(_vm.icon) + " ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }