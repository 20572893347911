var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.ADMIN)
    ? _c(
        "div",
        [
          _c("v-subheader", [_vm._v("Admin")]),
          _vm._l(_vm.items, function(item, i) {
            return _c(
              "v-list-item",
              {
                key: i,
                attrs: {
                  to: item.link
                }
              },
              [
                _c(
                  "v-list-item-icon",
                  [
                    _c("v-icon", {
                      domProps: {
                        textContent: _vm._s(item.icon)
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-list-item-content",
                  [
                    _c("v-list-item-title", {
                      domProps: {
                        textContent: _vm._s(item.text)
                      }
                    })
                  ],
                  1
                )
              ],
              1
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }