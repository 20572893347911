var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "layout-simple",
    {
      attrs: {
        title: _vm.$t("views.Error.title")
      }
    },
    [
      _c(
        "v-container",
        {
          attrs: {
            fluid: ""
          }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center"
              }
            },
            [_c("error-list")],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }