var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("layout-report-with-banner", {
    attrs: {
      showProgress: false,
      showFooter: false,
      showLoginButton: _vm.showLoginButton
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function fn() {
          var _vm$service

          return [
            _c("booking-cancel", {
              attrs: {
                isBookingCancelled: _vm.isBookingCancelled,
                serviceName:
                  (_vm$service = _vm.service) === null || _vm$service === void 0
                    ? void 0
                    : _vm$service.name,
                booking: _vm.createdBooking,
                isMobile: _vm.isMobile,
                bookingId: _vm.bookingId
              },
              on: {
                click: _vm.cancel
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }