var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-menu",
    {
      attrs: {
        "offset-y": "",
        left: "",
        "nudge-bottom": "14",
        "max-width": "450",
        "close-on-content-click": false,
        "content-class": "user-profile-menu-content"
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function fn(_ref) {
            var on = _ref.on,
              attrs = _ref.attrs
            return [
              _c(
                "span",
                [
                  _vm.user.firstName && _vm.user.lastName
                    ? _c(
                        "v-avatar",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "white--text ml-4",
                              attrs: {
                                "data-test-user-menu": "",
                                size: "35px",
                                color: _vm.color,
                                right: true
                              }
                            },
                            "v-avatar",
                            attrs,
                            false
                          ),
                          on
                        ),
                        [
                          _vm._v(
                            " " +
                              _vm._s(_vm.user.firstName[0] || "") +
                              _vm._s(_vm.user.lastName[0] || "") +
                              " "
                          )
                        ]
                      )
                    : _vm._e()
                ],
                1
              )
            ]
          }
        }
      ])
    },
    [
      _c(
        "v-list",
        [
          _c(
            "div",
            {
              staticClass: "pt-2 ms-4"
            },
            [
              _vm.user.firstName && _vm.user.lastName
                ? _c(
                    "v-avatar",
                    {
                      staticClass: "white--text",
                      attrs: {
                        color: _vm.color,
                        size: "35px"
                      }
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.firstName[0] || "") +
                          _vm._s(_vm.user.lastName[0] || "") +
                          " "
                      )
                    ]
                  )
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass: "d-inline-flex flex-column justify-center ms-3",
                  staticStyle: {
                    "vertical-align": "middle"
                  }
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "text--primary text-truncate font-weight-semibold mb-n1"
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.user.firstName) +
                          " " +
                          _vm._s(_vm.user.lastName) +
                          " "
                      )
                    ]
                  ),
                  _c(
                    "small",
                    {
                      staticClass: "text--disabled"
                    },
                    [_vm._v(_vm._s(_vm.user.userName))]
                  )
                ]
              )
            ],
            1
          ),
          _vm.partners.length > 0 &&
          _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
            ? _c("v-divider", {
                staticClass: "my-2 pb-1"
              })
            : _vm._e(),
          _vm.partners.length > 0
            ? _c(
                "v-list-item",
                [
                  _vm.$can(_vm.ActionEnum.READ, _vm.ResourceEnum.PARTNER)
                    ? _c("the-partner-selector-list")
                    : _vm._e()
                ],
                1
              )
            : _vm._e(),
          _c("v-divider", {
            staticClass: "my-2"
          }),
          _c(
            "v-list-item",
            {
              attrs: {
                link: "",
                to: _vm.userItem.link,
                "data-test-profile-link": ""
              }
            },
            [
              _c(
                "v-list-item-icon",
                {
                  staticClass: "me-2"
                },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: {
                        size: "22"
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.userItem.icon) + " ")]
                  )
                ],
                1
              ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.userItem.text))])],
                1
              )
            ],
            1
          ),
          _c("v-divider", {
            staticClass: "my-2"
          }),
          _c(
            "v-list-item",
            {
              on: {
                click: _vm.performLogout
              }
            },
            [
              !_vm.isLogoutLoading
                ? _c(
                    "v-list-item-icon",
                    {
                      staticClass: "me-2"
                    },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            size: "22"
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.logOut.icon) + " ")]
                      )
                    ],
                    1
                  )
                : _c(
                    "v-list-item-icon",
                    {
                      staticClass: "me-2"
                    },
                    [
                      _c("v-progress-circular", {
                        attrs: {
                          size: 22,
                          width: 3,
                          color: "grey",
                          indeterminate: ""
                        }
                      })
                    ],
                    1
                  ),
              _c(
                "v-list-item-content",
                [_c("v-list-item-title", [_vm._v(_vm._s(_vm.logOut.text))])],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }