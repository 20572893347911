var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "tooltip",
    {
      attrs: {
        text: _vm.value.description,
        disabled: !_vm.value.description
      }
    },
    [
      _c(
        "v-chip",
        {
          class: _vm.customFieldClass,
          style: _vm.chipStyle,
          attrs: {
            color: _vm.color,
            small: ""
          }
        },
        [
          _c(
            "div",
            {
              style: _vm.textColor
            },
            [_vm._v(" " + _vm._s(_vm.value.value) + " ")]
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }