var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("layout-report-with-banner", {
    attrs: {
      showProgress: false,
      showFooter: false,
      showLoginButton: _vm.showLoginButton
    },
    scopedSlots: _vm._u([
      {
        key: "content",
        fn: function fn() {
          return [
            _c("booking-detail", {
              attrs: {
                bookingId: _vm.bookingId
              }
            })
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }