var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-row",
        {
          staticClass: "justify-center align-center"
        },
        [
          _c(
            "v-tooltip",
            {
              attrs: {
                top: "",
                "nudge-right": ""
              },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function fn(_ref) {
                    var on = _ref.on
                    return [
                      _c(
                        "v-img",
                        _vm._g(
                          {
                            attrs: {
                              src: _vm.src,
                              height: "100",
                              width: "200",
                              contain: ""
                            }
                          },
                          on
                        )
                      )
                    ]
                  }
                }
              ])
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("noData")))])]
          )
        ],
        1
      ),
      !_vm.small
        ? _c(
            "v-row",
            {
              staticClass: "justify-center align-center mt-4"
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("noData")))])]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }