var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "side-card",
    {
      attrs: {
        fullscreen: _vm.isMobile,
        height: _vm.sideCardHeight
      },
      on: {
        close: function close($event) {
          return _vm.$emit("close")
        }
      }
    },
    [
      _c("file-download", {
        ref: "downloader",
        attrs: {
          listItem: _vm.listItem,
          hidden: "",
          downloadFiles: _vm.downloadFiles
        }
      }),
      _vm.loading
        ? _c("v-progress-circular", {
            staticStyle: {
              position: "absolute",
              bottom: "calc((100vh - 165px) / 2)",
              right: "calc(200px - 20px)"
            },
            attrs: {
              color: "grey",
              indeterminate: ""
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }