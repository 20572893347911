var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      style: "height: ".concat(_vm.height, "; overflow: hidden")
    },
    [
      !_vm.isMobile
        ? _c(
            "div",
            {
              style: "height: ".concat(
                _vm.height,
                "; position: absolute; width: 400px; right: 0;"
              )
            },
            [
              !_vm.isLoading
                ? _c("document-template-detail-token-list", {
                    attrs: {
                      height: "calc(".concat(_vm.height, " - 82px)"),
                      isEditValue: true,
                      currentPageNumber: _vm.currentPageNumber,
                      page: _vm.pages[_vm.currentPageNumber]
                    },
                    on: {
                      setCurrentPage: _vm.setCurrentPage
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "addToken",
                          fn: function fn() {
                            return [
                              _c(
                                "document-template-detail-token-list-options",
                                {
                                  attrs: {
                                    document: _vm.signDocument,
                                    showSave: false,
                                    showPlaceholderOption: false,
                                    getDetailCardEditor: function getDetailCardEditor() {
                                      return _vm.detailCardEditor
                                    }
                                  },
                                  on: {
                                    save: _vm.resetHighlighted
                                  }
                                }
                              )
                            ]
                          },
                          proxy: true
                        }
                      ],
                      null,
                      false,
                      3230744810
                    )
                  })
                : _c("v-skeleton-loader", {
                    attrs: {
                      type: "list-item@3"
                    }
                  })
            ],
            1
          )
        : _vm._e(),
      !_vm.isLoading
        ? _c("document-detail-card-editor", {
            ref: "detailCardEditor",
            staticStyle: {
              width: "calc(100%)"
            },
            attrs: {
              outlined: false,
              editValues: true,
              isLoading: _vm.isLoading,
              sideBarElement: _vm.sideBarElement,
              currentPageNumber: _vm.currentPageNumber,
              pages: _vm.pages
            },
            on: {
              setCurrentPage: _vm.setCurrentPage
            }
          })
        : _c("v-skeleton-loader", {
            staticStyle: {
              width: "calc(100%)"
            },
            attrs: {
              type: "image@3"
            }
          }),
      _vm.isMobile
        ? _c("document-template-detail-token-list-options-mobile", {
            staticStyle: {
              "z-index": "5",
              position: "relative",
              bottom: "80px"
            },
            attrs: {
              document: _vm.signDocument,
              showPlaceholderOption: false,
              getDetailCardEditor: function getDetailCardEditor() {
                return _vm.detailCardEditor
              }
            },
            on: {
              save: _vm.resetHighlighted
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }