var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-bottom-navigation",
        {
          staticClass: "hidden-sm-and-up",
          attrs: {
            app: "",
            fixed: "",
            grow: ""
          }
        },
        [
          _vm.hasPrev
            ? _c(
                "v-btn",
                {
                  attrs: {
                    "x-large": "",
                    icon: ""
                  },
                  on: {
                    click: _vm.emitPrev
                  }
                },
                [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                1
              )
            : _vm._e(),
          _c("v-spacer"),
          _vm.hasNext
            ? _c(
                "v-btn",
                {
                  attrs: {
                    disabled: _vm.disableNext,
                    "x-large": "",
                    color: "green",
                    icon: ""
                  },
                  on: {
                    click: _vm.emitNext
                  }
                },
                [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                1
              )
            : _vm._e()
        ],
        1
      ),
      _c(
        "v-card-actions",
        {
          staticClass: "card-actions hidden-sm-and-down d-sm-inline"
        },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "start"
                      }
                    },
                    [
                      _vm.hasPrev
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "prev",
                                large: "",
                                rounded: "",
                                elevation: "0"
                              },
                              on: {
                                click: _vm.emitPrev
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-left")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "v-col",
                [
                  _c(
                    "v-row",
                    {
                      attrs: {
                        justify: "end"
                      }
                    },
                    [
                      _vm.hasNext
                        ? _c(
                            "v-btn",
                            {
                              attrs: {
                                id: "next",
                                disabled: _vm.disableNext,
                                large: "",
                                rounded: "",
                                color: "green",
                                elevation: "0"
                              },
                              on: {
                                click: _vm.emitNext
                              }
                            },
                            [_c("v-icon", [_vm._v("mdi-arrow-right")])],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }