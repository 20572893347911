var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.customField.type === _vm.CustomFieldEnum.TEXT
    ? _c("custom-field-text", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.NUMBER
    ? _c("custom-field-number", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.BOOLEAN
    ? _c("custom-field-boolean", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.DATE
    ? _c("custom-field-date", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.DATE_TIME
    ? _c("custom-field-date-time", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.SINGLE_SELECT
    ? _c("custom-field-single-select", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm.customField.type === _vm.CustomFieldEnum.MULTI_SELECT
    ? _c("custom-field-multi-select", {
        attrs: {
          required: _vm.required,
          customField: _vm.customField,
          disabled: _vm.disabled,
          loading: _vm.loading
        },
        model: {
          value: _vm.input,
          callback: function callback($$v) {
            _vm.input = $$v
          },
          expression: "input"
        }
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }