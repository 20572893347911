import { render, staticRenderFns } from "./TheNavigationLayout.vue?vue&type=template&id=1347ecb3&scoped=true&"
import script from "./TheNavigationLayout.vue?vue&type=script&lang=ts&"
export * from "./TheNavigationLayout.vue?vue&type=script&lang=ts&"
import style0 from "./TheNavigationLayout.vue?vue&type=style&index=0&id=1347ecb3&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1347ecb3",
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';
installComponents(component, {VBadge,VIcon,VListItem,VListItemContent,VListItemIcon,VListItemTitle,VSubheader})


/* hot reload */
if (module.hot) {
  var api = require("/home/runner/work/app-mrfiktiv/app-mrfiktiv/mrfiktiv/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('1347ecb3')) {
      api.createRecord('1347ecb3', component.options)
    } else {
      api.reload('1347ecb3', component.options)
    }
    module.hot.accept("./TheNavigationLayout.vue?vue&type=template&id=1347ecb3&scoped=true&", function () {
      api.rerender('1347ecb3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/layouts/navigation/TheNavigationLayout.vue"
export default component.exports