var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _vm.booking && !_vm.isBookingCancelled
    ? _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-row",
            {
              attrs: {
                align: "center",
                justify: "center"
              }
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "justify-center align-center text-center",
                  attrs: {
                    cols: "12"
                  }
                },
                [
                  _c(
                    "v-card-text",
                    {
                      staticClass: "mt-4 text-h5 font-weight-bold"
                    },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t("bookingForm.BookingCancel.bookingCancel")
                        ) + " "
                      )
                    ]
                  ),
                  _c("booking-success", {
                    attrs: {
                      heading: "bookingForm.BookingDetail.yourCreatedBooking",
                      text: _vm.$t(
                        "bookingForm.BookingCancel.bookingCancelText",
                        {
                          serviceName: _vm.serviceName
                        }
                      ),
                      showImage: false,
                      showDownloadBtn: false
                    }
                  }),
                  _c(
                    "v-card-text",
                    {
                      staticClass: "mt-n6 mb-8  "
                    },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            color: "error",
                            elevation: "0",
                            block: _vm.isMobile
                          },
                          on: {
                            click: _vm.cancel
                          }
                        },
                        [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t("bookingForm.BookingCancel.cancel")
                              ) +
                              " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    : _vm.isBookingCancelled
    ? _c(
        "v-card",
        {
          attrs: {
            flat: ""
          }
        },
        [
          _c("booking-success", {
            attrs: {
              showDownloadBtn: false,
              showImage: false,
              heading: "bookingForm.BookingCancel.bookingCanceledHeading",
              text: "bookingForm.BookingCancel.bookingCanceledSuccess"
            }
          })
        ],
        1
      )
    : _c("error-card")
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }