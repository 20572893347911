var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-form",
    {
      ref: "signDocumentForm",
      model: {
        value: _vm.valid,
        callback: function callback($$v) {
          _vm.valid = $$v
        },
        expression: "valid"
      }
    },
    [
      !_vm.document
        ? _c("file-input", {
            attrs: {
              maxFileSizeInMb: _vm.maxFileSizeInMb,
              value: _vm.createDocumentDto.file,
              showSize: "true",
              accept: "application/pdf, .pdf",
              label: _vm.$t("sign.SignDocumentForm.file") + " *",
              placeholder: _vm.$t("sign.SignDocumentForm.placeholder"),
              rules: _vm.maxSizeRule,
              readonly: _vm.disabled,
              outlined: ""
            },
            on: {
              "update:value": function updateValue($event) {
                return _vm.$set(_vm.createDocumentDto, "file", $event)
              },
              change: _vm.onChange
            }
          })
        : _vm._e(),
      _c("v-text-field", {
        attrs: {
          "prepend-icon": "mdi-file",
          rules: _vm.requiredRule,
          label: _vm.$t("sign.SignDocumentForm.name"),
          readonly: true,
          outlined: ""
        },
        model: {
          value: _vm.createDocumentDto.name,
          callback: function callback($$v) {
            _vm.$set(_vm.createDocumentDto, "name", $$v)
          },
          expression: "createDocumentDto.name"
        }
      }),
      _c("v-text-field", {
        attrs: {
          "prepend-icon": "mdi-folder",
          label: _vm.$t("sign.SignDocumentForm.folder"),
          readonly: _vm.disabled,
          outlined: ""
        },
        on: {
          blur: _vm.removeWhiteSpace
        },
        model: {
          value: _vm.createDocumentDto.folder,
          callback: function callback($$v) {
            _vm.$set(_vm.createDocumentDto, "folder", $$v)
          },
          expression: "createDocumentDto.folder"
        }
      }),
      _c("v-combobox", {
        attrs: {
          "prepend-icon": "mdi-tag-multiple",
          label: _vm.$t("sign.SignDocumentForm.tags"),
          multiple: "",
          "small-chips": "",
          clearable: "",
          readonly: _vm.disabled,
          outlined: ""
        },
        model: {
          value: _vm.createDocumentDto.tags,
          callback: function callback($$v) {
            _vm.$set(_vm.createDocumentDto, "tags", $$v)
          },
          expression: "createDocumentDto.tags"
        }
      }),
      _c(
        "v-row",
        {
          staticClass: "px-3 pb-8"
        },
        [
          _c("v-icon", [_vm._v("mdi-file-multiple-outline")]),
          !_vm.hideTemplateOption
            ? _c("v-checkbox", {
                staticClass: "pl-2",
                attrs: {
                  label: _vm.$t("sign.SignDocumentForm.template"),
                  readonly: _vm.disabled,
                  outlined: ""
                },
                model: {
                  value: _vm.createDocumentDto.isTemplate,
                  callback: function callback($$v) {
                    _vm.$set(_vm.createDocumentDto, "isTemplate", $$v)
                  },
                  expression: "createDocumentDto.isTemplate"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-divider", {
        staticClass: "pb-12"
      }),
      _c("v-text-field", {
        attrs: {
          "prepend-icon": "mdi-text",
          rules: _vm.requiredRule,
          label: _vm.$t("sign.SignDocumentForm.title") + " *",
          readonly: _vm.disabled,
          hint: _vm.$t("sign.SignDocumentForm.titleHint"),
          outlined: ""
        },
        model: {
          value: _vm.createDocumentDto.title,
          callback: function callback($$v) {
            _vm.$set(_vm.createDocumentDto, "title", $$v)
          },
          expression: "createDocumentDto.title"
        }
      }),
      _c("v-textarea", {
        attrs: {
          "prepend-icon": " ",
          label: _vm.$t("sign.SignDocumentForm.description"),
          readonly: _vm.disabled,
          hint: _vm.$t("sign.SignDocumentForm.descriptionHint"),
          "persistent-hint": "",
          outlined: ""
        },
        model: {
          value: _vm.createDocumentDto.description,
          callback: function callback($$v) {
            _vm.$set(_vm.createDocumentDto, "description", $$v)
          },
          expression: "createDocumentDto.description"
        }
      }),
      !_vm.document && !_vm.hideButton
        ? _c(
            "v-btn",
            {
              staticClass: "mt-4 mr-4",
              attrs: {
                hidden: _vm.hideButton,
                loading: _vm.isLoading,
                color: "primary",
                large: "",
                disabled: !_vm.valid,
                "data-test-form-submit": ""
              },
              on: {
                click: _vm.save
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("sign.SignDocumentForm.submit")) + " ")]
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }