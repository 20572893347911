var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      on: {
        dragover: function dragover($event) {
          $event.preventDefault()
          _vm.isDrag = true
        },
        dragleave: function dragleave($event) {
          $event.preventDefault()
          _vm.isDrag = false
        },
        drop: function drop($event) {
          $event.preventDefault()
          return _vm.onFileDrop($event)
        }
      }
    },
    [
      _c("v-file-input", {
        attrs: {
          "show-size": "1000",
          "persistent-hint": _vm.persistentHint,
          accept: _vm.accept,
          label: _vm.label,
          placeholder: _vm.placeholder,
          "persistent-placeholder": _vm.persistentPlaceholder,
          rules: _vm.rules,
          readonly: _vm.readonly,
          filled: _vm.isDrag,
          outlined: _vm.outlined,
          loading: _vm.loading,
          "small-chips": ""
        },
        scopedSlots: _vm._u([
          {
            key: "prepend-icon",
            fn: function fn() {
              return [_vm._v(_vm._s(_vm.prependIcon))]
            },
            proxy: true
          }
        ]),
        model: {
          value: _vm.file,
          callback: function callback($$v) {
            _vm.file = $$v
          },
          expression: "file"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }