var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-container",
    [
      _c(
        "v-card",
        {
          staticClass: "ma-auto card",
          attrs: {
            flat: ""
          }
        },
        [
          _c(
            "v-card-title",
            [
              _c(
                "v-banner",
                {
                  staticClass: "mr-auto ml-auto",
                  attrs: {
                    align: "center"
                  }
                },
                [
                  _c(
                    "v-avatar",
                    {
                      attrs: {
                        size: "250"
                      }
                    },
                    [
                      _c("v-img", {
                        attrs: {
                          contain: "",
                          src: require("@/assets/undraw/undraw_authentication_fsn5.svg")
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "v-card-text",
            [
              _c(
                "p",
                {
                  staticClass: "text",
                  staticStyle: {
                    "word-break": "break-word"
                  }
                },
                [
                  _vm._v(" Fehlende Berechtigung zu dieser Seite! "),
                  _c("br"),
                  _vm._v(" Bitte mit geeigneten Zugriffsrechten anmelden. ")
                ]
              ),
              _vm.requiredPermission.action
                ? _c("p", [
                    _vm._v(
                      " Benötigte Berechtigung: " +
                        _vm._s(_vm.requiredPermission.action) +
                        "." +
                        _vm._s(_vm.requiredPermission.subject) +
                        " "
                    )
                  ])
                : _vm._e(),
              _c(
                "v-btn",
                {
                  staticClass: "button mt-2",
                  attrs: {
                    block: "",
                    "x-large": "",
                    outlined: "",
                    "data-test-login-again": "",
                    color: "primary"
                  },
                  on: {
                    click: _vm.backToLogin
                  }
                },
                [_vm._v(" Erneut Anmelden ")]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }