var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("v-text-field", {
    attrs: {
      rules: _vm.requiredRule,
      label: _vm.customField.label + (_vm.required ? " *" : ""),
      hint: _vm.customField.hint,
      disabled: _vm.disabled,
      loading: _vm.loading,
      type: "datetime-local",
      outlined: "",
      "persistent-hint": "",
      clearable: !_vm.required
    },
    on: {
      "click:clear": _vm.clear
    },
    model: {
      value: _vm.input,
      callback: function callback($$v) {
        _vm.input = $$v
      },
      expression: "input"
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }