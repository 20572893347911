var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-list-item",
    {
      attrs: {
        "three-line": ""
      }
    },
    [
      _vm._t("icon"),
      _vm.isLoading
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "list-item-two-line"
            }
          })
        : _vm.cost
        ? _c(
            "v-list-item-content",
            [
              _c(
                "v-list-item-title",
                {
                  staticClass: "text-truncate "
                },
                [
                  _vm.hasOpenDetailListener()
                    ? _c(
                        "a",
                        {
                          on: {
                            click: function click($event) {
                              $event.stopPropagation()
                              return _vm.emitOpenDetail.apply(null, arguments)
                            }
                          }
                        },
                        [_vm._v(" " + _vm._s(_vm.cost.title) + " ")]
                      )
                    : _c("span", [_vm._v(" " + _vm._s(_vm.cost.title) + " ")])
                ]
              ),
              _vm.cost.description
                ? _c(
                    "v-list-item-subtitle",
                    {
                      staticClass: "text-xs"
                    },
                    [_vm._v(" " + _vm._s(_vm.cost.description) + " ")]
                  )
                : _vm._e()
            ],
            1
          )
        : _c("latest-entries-card-empty")
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }