var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c("div", [
    _c(
      "div",
      {
        style: "height: ".concat(_vm.height, " !important; overflow-y: auto;")
      },
      _vm._l(_vm.documentTokens, function(pageTokens, pageIndex) {
        return _c(
          "v-sheet",
          {
            key: "pageTokens" + pageIndex,
            attrs: {
              rounded: "",
              outlined: pageIndex === _vm.currentPageNumber,
              color: pageIndex === _vm.currentPageNumber ? "info" : ""
            }
          },
          [
            _c(
              "v-card",
              {
                attrs: {
                  flat: "",
                  outlined: pageIndex === _vm.currentPageNumber
                }
              },
              [
                pageIndex === _vm.currentPageNumber && _vm.page
                  ? _c(
                      "v-expansion-panels",
                      {
                        attrs: {
                          id: "page" + pageIndex,
                          flat: "",
                          accordion: ""
                        },
                        model: {
                          value: _vm.highlightedToken,
                          callback: function callback($$v) {
                            _vm.highlightedToken = $$v
                          },
                          expression: "highlightedToken"
                        }
                      },
                      [
                        _vm.documentTokens.length > 1
                          ? _c(
                              "div",
                              {
                                staticStyle: {
                                  width: "100%"
                                },
                                on: {
                                  click: function click($event) {
                                    return _vm.setCurrentPage(pageIndex)
                                  }
                                }
                              },
                              [
                                _c("v-card-title", [
                                  _c("span", [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm.$t(
                                            "sign.DocumentTemplateDetailTokenList.page"
                                          )
                                        ) +
                                        " " +
                                        _vm._s(pageIndex + 1) +
                                        " "
                                    )
                                  ]),
                                  pageTokens.length === 0
                                    ? _c("span", [
                                        _vm._v(
                                          "  (" +
                                            _vm._s(
                                              _vm.$t(
                                                "sign.DocumentTemplateDetailTokenList.empty"
                                              )
                                            ) +
                                            ") "
                                        )
                                      ])
                                    : _vm._e()
                                ])
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm._l(pageTokens, function(documentToken, index) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: "tokens" + index,
                              on: {
                                click: function click($event) {
                                  return _vm.setCurrentPage(pageIndex)
                                }
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  attrs: {
                                    id: "token" + pageIndex + index
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function fn() {
                                          return undefined
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "document-template-detail-token-list-expansion-header",
                                    {
                                      key:
                                        "token-list-expansion-header" +
                                        documentToken.value,
                                      attrs: {
                                        documentToken: documentToken,
                                        isEditValue: _vm.isEditValue
                                      }
                                    }
                                  )
                                ],
                                1
                              ),
                              _c(
                                "v-expansion-panel-content",
                                [
                                  _c(
                                    "v-row",
                                    {
                                      attrs: {
                                        "no-gutters": ""
                                      }
                                    },
                                    [
                                      _c("debug", [
                                        _vm._v(
                                          " " +
                                            _vm._s(documentToken) +
                                            " " +
                                            _vm._s(
                                              (_vm.isEditValue &&
                                                !_vm.getIsSignature(
                                                  documentToken.token
                                                )) ||
                                                _vm.getIsTextField(
                                                  documentToken.token
                                                )
                                            ) +
                                            " " +
                                            _vm._s(
                                              _vm.isEditValue &&
                                                !_vm.getIsSignature(
                                                  documentToken.token
                                                )
                                            ) +
                                            " " +
                                            _vm._s(_vm.isEditValue) +
                                            " " +
                                            _vm._s(
                                              _vm.getIsTextField(
                                                documentToken.token
                                              )
                                            ) +
                                            " "
                                        )
                                      ]),
                                      (_vm.isEditValue &&
                                        !_vm.getIsSignature(
                                          documentToken.token
                                        )) ||
                                      _vm.getIsTextField(documentToken.token)
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("v-textarea", {
                                                attrs: {
                                                  "append-icon": !documentToken.value
                                                    ? "mdi-alert"
                                                    : "",
                                                  autofocus: "",
                                                  outlined: "",
                                                  rows: 1,
                                                  "auto-grow": "",
                                                  label:
                                                    documentToken.title ||
                                                    _vm.getTokenText(
                                                      documentToken.token
                                                    ),
                                                  hint:
                                                    documentToken.description ||
                                                    _vm.$t(
                                                      "sign.DocumentTemplateDetailTokenList.inputHint"
                                                    ),
                                                  "persistent-hint": "",
                                                  readonly: _vm.disabled
                                                },
                                                model: {
                                                  value: documentToken.value,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.$set(
                                                      documentToken,
                                                      "value",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentToken.value"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "9"
                                              }
                                            },
                                            [
                                              _c(
                                                "template-editor-placeholder-dropdown",
                                                {
                                                  key:
                                                    "placeholderdropdown" +
                                                    documentToken.token,
                                                  attrs: {
                                                    disabled:
                                                      _vm.getIsSignature(
                                                        documentToken.token
                                                      ) || _vm.disabled,
                                                    label: documentToken.title,
                                                    hint: _vm.getIsSignature(
                                                      documentToken.token
                                                    )
                                                      ? _vm.$t(
                                                          "sign.DocumentTemplateDetailTokenList.signatureHint"
                                                        )
                                                      : documentToken.description ||
                                                        _vm.$t(
                                                          "sign.DocumentTemplateDetailTokenList.tokenHint"
                                                        ),
                                                    "persistent-hint": "true",
                                                    context: {
                                                      report: true,
                                                      partner: true,
                                                      sign: true
                                                    },
                                                    initialOption: _vm.getTokenText(
                                                      documentToken.token
                                                    )
                                                  },
                                                  on: {
                                                    change: function change(v) {
                                                      return _vm.updateToken(
                                                        documentToken,
                                                        v
                                                      )
                                                    }
                                                  }
                                                }
                                              )
                                            ],
                                            1
                                          ),
                                      _vm.getIsSignature(documentToken.token)
                                        ? _c(
                                            "v-col",
                                            {
                                              attrs: {
                                                cols: "3"
                                              }
                                            },
                                            [
                                              _vm.getIsSignature(
                                                documentToken.token
                                              )
                                                ? _c("v-checkbox", {
                                                    attrs: {
                                                      "input-value": _vm.isSignatureWithQR(
                                                        documentToken
                                                      ),
                                                      label: "QR-Code",
                                                      "true-value": true,
                                                      "false-value": false
                                                    },
                                                    on: {
                                                      change: function change(
                                                        $event
                                                      ) {
                                                        return _vm.change(
                                                          $event,
                                                          documentToken
                                                        )
                                                      }
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "label",
                                                          fn: function fn() {
                                                            return [
                                                              _c("small", [
                                                                _vm._v(
                                                                  " " +
                                                                    _vm._s(
                                                                      _vm.$t(
                                                                        "QR-Code"
                                                                      )
                                                                    ) +
                                                                    " "
                                                                )
                                                              ])
                                                            ]
                                                          },
                                                          proxy: true
                                                        }
                                                      ],
                                                      null,
                                                      true
                                                    )
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.getIsSignature(
                                        documentToken.token
                                      ) && _vm.isSignatureFormActive
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "mb-n2 mt-n4",
                                              attrs: {
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("v-checkbox", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "sign.DocumentTemplateDetailTokenList.isEditable"
                                                  ),
                                                  disabled: _vm.disabled
                                                },
                                                model: {
                                                  value:
                                                    documentToken.isEditable,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.$set(
                                                      documentToken,
                                                      "isEditable",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentToken.isEditable"
                                                }
                                              }),
                                              documentToken.isEditable
                                                ? _c("v-checkbox", {
                                                    staticClass: "mt-n4",
                                                    attrs: {
                                                      label: _vm.$t(
                                                        "sign.DocumentTemplateDetailTokenList.isRequired"
                                                      ),
                                                      disabled: _vm.disabled
                                                    },
                                                    model: {
                                                      value:
                                                        documentToken.isRequired,
                                                      callback: function callback(
                                                        $$v
                                                      ) {
                                                        _vm.$set(
                                                          documentToken,
                                                          "isRequired",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "documentToken.isRequired"
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      !_vm.getIsSignature(documentToken.token)
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "mb-n4",
                                              attrs: {
                                                cols: "12"
                                              }
                                            },
                                            [
                                              _c("v-text-field", {
                                                staticClass: "mb-n4",
                                                attrs: {
                                                  label: _vm.$t(
                                                    "sign.DocumentTemplateDetailTokenList.descriptionTitle"
                                                  ),
                                                  placeholder: _vm.$t(
                                                    "sign.DocumentTemplateDetailTokenList.descriptionTitleExplanation"
                                                  ),
                                                  dense: "",
                                                  outlined: "",
                                                  disabled: _vm.disabled
                                                },
                                                model: {
                                                  value: documentToken.title,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.$set(
                                                      documentToken,
                                                      "title",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentToken.title"
                                                }
                                              }),
                                              _c("v-text-field", {
                                                attrs: {
                                                  label: _vm.$t(
                                                    "sign.DocumentTemplateDetailTokenList.description"
                                                  ),
                                                  placeholder: _vm.$t(
                                                    "sign.DocumentTemplateDetailTokenList.descriptionExplanation"
                                                  ),
                                                  dense: "",
                                                  outlined: "",
                                                  disabled: _vm.disabled
                                                },
                                                model: {
                                                  value:
                                                    documentToken.description,
                                                  callback: function callback(
                                                    $$v
                                                  ) {
                                                    _vm.$set(
                                                      documentToken,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "documentToken.description"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _vm.isSignatureFormActive
                                        ? _c(
                                            "v-col",
                                            {
                                              staticClass: "mb-n4",
                                              attrs: {
                                                cols: "12"
                                              }
                                            },
                                            [
                                              !_vm.getIsSignature(
                                                documentToken.token
                                              )
                                                ? _c("v-text-field", {
                                                    attrs: {
                                                      dense: "",
                                                      outlined: "",
                                                      "append-icon":
                                                        "mdi-format-font-size-increase",
                                                      label:
                                                        _vm.$t(
                                                          "sign.DocumentTemplateDetailTokenList.fontSize"
                                                        ) + " (pt)",
                                                      type: "number",
                                                      min: "1",
                                                      max: "40",
                                                      value: Math.round(
                                                        documentToken
                                                          .coordinates.fontSize
                                                      ),
                                                      disabled: _vm.disabled
                                                    },
                                                    on: {
                                                      change: function change(
                                                        v
                                                      ) {
                                                        return (documentToken.coordinates.fontSize = Number(
                                                          v
                                                        ))
                                                      }
                                                    }
                                                  })
                                                : _vm._e()
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-n4",
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "mr-1",
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "append-icon":
                                                "mdi-arrow-left-right",
                                              label:
                                                _vm.$t(
                                                  "sign.DocumentTemplateDetailTokenList.x"
                                                ) + " (mm)",
                                              type: "number",
                                              min: "0",
                                              max:
                                                _vm.page.pdfWidth -
                                                documentToken.coordinates.x,
                                              value: Math.round(
                                                documentToken.coordinates.x
                                              ),
                                              disabled: _vm.disabled
                                            },
                                            on: {
                                              change: function change(v) {
                                                return (documentToken.coordinates.x = Number(
                                                  v
                                                ))
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-n4",
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          _c("v-text-field", {
                                            staticClass: "ml-1",
                                            attrs: {
                                              dense: "",
                                              outlined: "",
                                              "append-icon":
                                                "mdi-arrow-up-down",
                                              label:
                                                _vm.$t(
                                                  "sign.DocumentTemplateDetailTokenList.y"
                                                ) + " (mm)",
                                              type: "number",
                                              min: "0",
                                              max:
                                                _vm.page.pdfHeight -
                                                documentToken.coordinates.h,
                                              value: Math.round(
                                                documentToken.coordinates.y
                                              ),
                                              disabled: _vm.disabled
                                            },
                                            on: {
                                              change: function change(v) {
                                                return (documentToken.coordinates.y = Number(
                                                  v
                                                ))
                                              }
                                            }
                                          })
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-n4",
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          !_vm.getIsSignature(
                                            documentToken.token
                                          )
                                            ? _c("v-text-field", {
                                                staticClass: "mr-1",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  "append-icon":
                                                    "mdi-unfold-less-vertical",
                                                  label:
                                                    _vm.$t(
                                                      "sign.DocumentTemplateDetailTokenList.w"
                                                    ) + " (mm)",
                                                  type: "number",
                                                  min: "1",
                                                  max:
                                                    _vm.page.pdfWidth -
                                                    documentToken.coordinates.x,
                                                  value: Math.round(
                                                    documentToken.coordinates.w
                                                  ),
                                                  disabled: _vm.disabled
                                                },
                                                on: {
                                                  change: function change(v) {
                                                    return (documentToken.coordinates.w = Number(
                                                      v
                                                    ))
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "mb-n4",
                                          attrs: {
                                            cols: "6"
                                          }
                                        },
                                        [
                                          !_vm.getIsSignature(
                                            documentToken.token
                                          )
                                            ? _c("v-text-field", {
                                                staticClass: "ml-1",
                                                attrs: {
                                                  dense: "",
                                                  outlined: "",
                                                  "append-icon":
                                                    "mdi-unfold-less-horizontal",
                                                  label:
                                                    _vm.$t(
                                                      "sign.DocumentTemplateDetailTokenList.h"
                                                    ) + " (mm)",
                                                  type: "number",
                                                  min: "1",
                                                  max:
                                                    _vm.page.pdfHeight -
                                                    documentToken.coordinates.y,
                                                  value: Math.round(
                                                    documentToken.coordinates.h
                                                  ),
                                                  disabled: _vm.disabled
                                                },
                                                on: {
                                                  change: function change(v) {
                                                    return (documentToken.coordinates.h = Number(
                                                      v
                                                    ))
                                                  }
                                                }
                                              })
                                            : _vm._e()
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pb-1",
                                          attrs: {
                                            cols: "12"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              attrs: {
                                                elevation: 0,
                                                height: "60",
                                                color: "success",
                                                block: "",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: _vm.unselect
                                              }
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "sign.DocumentTemplateDetailTokenList.confirm"
                                                    )
                                                  ) +
                                                  " "
                                              )
                                            ]
                                          )
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-col",
                                        {
                                          attrs: {
                                            cols: "12"
                                          }
                                        },
                                        [
                                          _c(
                                            "v-btn",
                                            {
                                              staticStyle: {
                                                height: "40px"
                                              },
                                              attrs: {
                                                elevation: 0,
                                                block: "",
                                                outlined: "",
                                                disabled: _vm.disabled
                                              },
                                              on: {
                                                click: function click($event) {
                                                  return _vm.removeDocumentToken(
                                                    documentToken
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              documentToken.token
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "sign.DocumentTemplateDetailTokenList.remove"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                                : _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "sign.DocumentTemplateDetailTokenList.discard"
                                                          )
                                                        ) +
                                                        " "
                                                    )
                                                  ])
                                            ]
                                          )
                                        ],
                                        1
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        }),
                        _c("v-divider")
                      ],
                      2
                    )
                  : _c(
                      "v-expansion-panels",
                      {
                        attrs: {
                          id: "page" + pageIndex,
                          flat: ""
                        }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticStyle: {
                              width: "100%"
                            },
                            on: {
                              click: function click($event) {
                                return _vm.setCurrentPage(pageIndex)
                              }
                            }
                          },
                          [
                            _c("v-card-title", [
                              _c("span", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "sign.DocumentTemplateDetailTokenList.page"
                                      )
                                    ) +
                                    " " +
                                    _vm._s(pageIndex + 1) +
                                    " "
                                )
                              ]),
                              pageTokens.length === 0
                                ? _c("small", [
                                    _vm._v(
                                      "  (" +
                                        _vm._s(
                                          _vm.$t(
                                            "sign.DocumentTemplateDetailTokenList.empty"
                                          )
                                        ) +
                                        ") "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          ],
                          1
                        ),
                        _vm._l(pageTokens, function(documentToken, index) {
                          return _c(
                            "v-expansion-panel",
                            {
                              key: "tokens" + index,
                              attrs: {
                                id: "token" + pageIndex + index
                              },
                              on: {
                                click: function click($event) {
                                  return _vm.setCurrentPage(pageIndex)
                                }
                              }
                            },
                            [
                              _c(
                                "v-expansion-panel-header",
                                {
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "actions",
                                        fn: function fn() {
                                          return undefined
                                        },
                                        proxy: true
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "document-template-detail-token-list-expansion-header",
                                    {
                                      attrs: {
                                        documentToken: documentToken,
                                        isEditValue: _vm.isEditValue
                                      }
                                    }
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        })
                      ],
                      2
                    )
              ],
              1
            )
          ],
          1
        )
      }),
      1
    ),
    _c(
      "div",
      {
        staticClass: "mr-4 ml-4"
      },
      [_vm._t("addToken")],
      2
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }