var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "v-bottom-sheet",
    {
      attrs: {
        inset: "",
        "max-width": "500px"
      },
      model: {
        value: _vm.sheet,
        callback: function callback($$v) {
          _vm.sheet = $$v
        },
        expression: "sheet"
      }
    },
    [
      _c(
        "v-list",
        [
          _c("v-subheader", [
            _vm._v(
              _vm._s(_vm.$t("components.camera.CameraBottomSheet.subheader")) +
                " "
            )
          ]),
          _c(
            "v-list-item",
            {
              on: {
                click: function click($event) {
                  _vm.sheet = false

                  _vm.emitCamera()
                }
              }
            },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-camera")])], 1),
              _c("v-list-item-title", [
                _vm._v(
                  _vm._s(_vm.$t("components.camera.CameraBottomSheet.camera"))
                )
              ])
            ],
            1
          ),
          _c(
            "v-list-item",
            {
              on: {
                click: function click($event) {
                  _vm.sheet = false

                  _vm.emitLibrary()
                }
              }
            },
            [
              _c("v-list-item-icon", [_c("v-icon", [_vm._v("mdi-file")])], 1),
              _c("v-list-item-title", [
                _vm._v(
                  _vm._s(_vm.$t("components.camera.CameraBottomSheet.file"))
                )
              ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }